import React, { Component } from 'react'
import { Link } from "react-router-dom";

class Footer extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
      }
    render() {
        return (
            <div>
                <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
                    <div className="container">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12">
                                <div className="py-[30px] px-0">
                                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                        <div className="lg:col-span-4 md:col-span-4">
                                            <h5 className="tracking-[1px] text-gray-100 font-semibold">Connect with Us</h5>
                                            <ul className="list-none footer-list mt-6">
                                                <li><a href="tel:+91 9876543210" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-phone-volume me-1 mr-1"></i>+91 9876543210</a></li>
                                                <li className="mt-[10px]"><a href="mailto:info@indzip.com" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-fast-mail me-1 mr-1"></i>info@indzip.com</a></li>
                                            </ul>
                                            {/* <ul className="list-none mt-6">
                                                <li className="inline"><a href="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-red-600 dark:hover:bg-red-600"><i className="uil uil-linkedin" title="Linkedin"></i></a></li>
                                                <li className="inline"><a href="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-red-600 dark:hover:bg-red-600"><i className="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                                                <li className="inline"><a href="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-red-600 dark:hover:bg-red-600"><i className="uil uil-instagram align-middle" title="instagram"></i></a></li>
                                                <li className="inline"><a href="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-red-600 dark:hover:bg-red-600"><i className="uil uil-twitter align-middle" title="twitter"></i></a></li>
                                            </ul> */}
                                        </div>
                                        <div className="lg:col-span-4 md:col-span-4">
                                            <h5 className="tracking-[1px] text-gray-100 font-semibold">Navigation</h5>
                                            <ul className="list-none footer-list mt-6">
                                                <li><Link to="/about-us" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>About Us</Link></li>
                                                <li className="mt-[10px]"><Link to="/how-it-works" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>How It Works</Link></li>
                                                <li className="mt-[10px]"><Link to="/customer" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Customer</Link></li>
                                                <li className="mt-[10px]"><Link to="/travellers" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Traveller</Link></li>
                                                <li className="mt-[10px]"><Link to="/contact" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Contact Us</Link></li>
                                            </ul>
                                        </div>
                                        <div className="lg:col-span-3 md:col-span-4">
                                            <h5 className="tracking-[1px] text-gray-100 font-semibold">Useful Links</h5>
                                            <ul className="list-none footer-list mt-6">
                                                <li><Link to="/terms-of-services" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Terms of Services</Link></li>
                                                <li className="mt-[10px]"><Link to="/privacy-policy" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Privacy Policy</Link></li>
                                                <li className="mt-[10px]"><Link to="/customers-faq" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Customer FAQ</Link></li>
                                                <li className="mt-[10px]"><Link to="/travellers-faq" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out" onClick={this.scrollToTop}><i className="uil uil-angle-right-b me-1 mr-1"></i>Traveller FAQ</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-[30px] px-0 border-t border-slate-800">
                        <div className="container text-center">
                            <div className="grid md:grid-cols-1 items-center">
                                <div className="md:text-left text-center">
                                    <p className="mb-0 text-center">Copyright © <script>document.write(new Date().getFullYear())</script>, INDZIP</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        )
    }
}
export default Footer