import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";

class CustomersFAQ extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
      }

        
    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Customer FAQ</span></h4>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic line-height-1">Check our FAQ for more information</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/20.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                    <section className="relative py-16">
                        <div className="container">
                                <div className="grid md:grid-cols-1 grid-cols-1 gap-[30px]">
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Is <span className="text-red-600">INDZIP</span> a Courier?</h5>
                                            <p className="text-slate-400">Not Really. INDZIP is a personalised service that provides dedicated hand carriage based on specific demand from customers. We have our own registered travellers who would hand carry your urgent critical documents or parcels from anywhere to anywhere.We also have access to several cargo aircraft fleet which we can deploy for you at short notice.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">When are you likely to need <span className="text-red-600">INDZIP?</span></h5>
                                            <p className="text-slate-400">You would need INDZIP whenever you have any urgent same-day delivery requirements from one city to another and courier service cannot deliver within your required timeline.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Can anyone use <span className="text-red-600">INDZIP's</span> services?</h5>
                                            <p className="text-slate-400">Yes, you only need to register with INDZIP as an individual or a company.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How can you register with <span className="text-red-600">INDZIP?</span></h5>
                                            <p className="text-slate-400">You can register with us in a few simple steps:</p>
                                            <ul className="list-none text-slate-400 mt-4">
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Visit www.indzip.com (or) download the INDZIP app from Google Playstore or Apple iStore</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Register using your name /company name, phone number and email id</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Upload the required basic KYC documents</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Await confirmation from us</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Commence with your online booking</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How quickly would your parcel be picked up and delivered?</h5>
                                            <p className="text-slate-400">As soon as you place a confirmed order, our team will contact you and assign a traveller to your order. Your order will be picked up within 1-2 hours and will be delivered anywhere in India within 6-8 hours, and anywhere in the world within 24 hours</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">What is the maximum weight you can send by <span className="text-red-600">INDZIP?</span></h5>
                                            <p className="text-slate-400">All orders are hand carried and mostly flown by air, so we accept orders from 1 to 22Kg:</p>
                                            <ul className="list-none text-slate-400 mt-4">
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>In cabin: up to 7 kg with dimensions not exceeding 55cm x 35cm x 25cm (Length x Width x Height)</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Check-in: up to 15 kg with dimensions not exceeding 152cm x 58cm x 101cm (Length x Width x Height)</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>In case you have any larger parcels, not to worry, we will offer you alternate solutions including arranging an entire charter aircraft for you.</li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Are there any restricted items that you cannot send using <span className="text-red-600">INDZIP?</span></h5>
                                            <p className="text-slate-400">We do not accept hazardous items for transport. Some of the categories we do not accept are flammable substances, acids, liquids, chemicals, weapons, magnetized material, sharp objects or any other items which are deemed security hazards by local law. Luxury items including Jewellery, Watches and Cash will be accepted with proper documents and declaration from the sender.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How do you track your order?</h5>
                                            <p className="text-slate-400">INDZIP provides periodic shipment notifications on the INDZIP app/website and on your mobile/email. You can also contact our 24/7 contact centre for live shipment updates anytime.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        )
    }
}

export default CustomersFAQ