import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import withRouter from '../../../api/withRouter';
import axios from 'axios';
import { toast } from 'react-toastify';
import AppUrl from '../../../api/AppUrl';

class UserSidebar extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            user_type: '',
            customer_display: 'none',
            traveller_display: 'none',
            menu_url: '',
            file_path: '',
            imagePreviewUrl: null,
            image_folder:''
        }
    }

    componentDidMount() {
        const name = JSON.parse(localStorage.getItem('user'));
        const user_type = JSON.parse(localStorage.getItem('user_type'));
        this.setState({ name: name.name });
        if (user_type === 'Traveller') {
            this.setState({ traveller_display: 'block' , file_path : name.profile_image , image_folder : '/traveller/profile/' });
        }
        if (user_type === 'Customer') {
            this.setState({ customer_display: 'block' , file_path : name.profile_image , image_folder : '/customer/profile/'});
        }
    }

    customerLogout = () => {
        axios.post(AppUrl.customerLogout,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("user_type");
                localStorage.removeItem("profile_image");
                this.setState({
                    isUerLogin: false
                })
                window.location.href = decodeURIComponent('/');
            }).catch(error => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            });
    }

    travellerLogout = () => {
        axios.post(AppUrl.travellerLogout,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("user_type");
                localStorage.removeItem("profile_image");
                this.setState({
                    isUerLogin: false
                })
                window.location.href = decodeURIComponent('/');
            }).catch(error => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            });
    }

    updateProfileImage = (e) => {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const user_type = JSON.parse(localStorage.getItem('user_type'));
        const dataFromState = this.state;
        const postPath = user_type === 'Traveller' ? 'https://indzip.com/admin/api/traveller-profile-image-update' : 'https://indzip.com/admin/api/customer-profile-image-update';
        const url = AppUrl+postPath;
        const formData = new FormData();
        formData.append('customer_id', userInfo.id);
        formData.append('file_path', e.target.files[0]);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };
        axios.post( postPath, formData, config).then((response) => {
            const status = response.data.status;
            const message = response.data.message;
            const profile_image = response.data.profile_image;
            if (status === 'success') {
                localStorage.setItem("profile_image", JSON.stringify(profile_image));
                if (user_type === 'Traveller') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block',file_path: profile_image , image_folder : '/traveller/profile'});
                }if (user_type === 'Customer') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block',file_path: profile_image, image_folder : '/customer/profile'});
                }
                const profile_image_get = JSON.parse(localStorage.getItem('profile_image'));
                this.setState({ file_path: profile_image_get });
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
                 window.location.href = decodeURIComponent('/profile');
            }
        }).catch(error => {

        });
    }
    render() {
        const profile_image_get = JSON.parse(localStorage.getItem('profile_image'));
        return (
            <Fragment>
                <div className="lg:w-1/4 md:w-1/3 md:px-3">
                    <div className="relative md:-mt-48 -mt-32">
                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <div className="profile-pic text-center mb-5">
                                <input id="pro-img" name="profile_image" type="file" className="hidden" accept="image/png, image/jpeg" onChange={this.updateProfileImage} />
                                <div>
                                    <div className="relative h-28 w-28 mx-auto">
                                        <img src={`https://indzip.com/admin/public${this.state.image_folder}${profile_image_get}`} className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" id="profile-image" alt={profile_image_get} />
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img" />
                                    </div>
                                    <div className="mt-4">
                                        <h5 className="text-lg font-semibold">{this.state.name}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t border-gray-100 dark:border-gray-700">
                                <ul className="list-none sidebar-nav mb-0 mt-3" id="navmenu-nav">
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/profile' ? 'active' : ''}`}>
                                        <Link to="/profile" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-dashboard" /></span>
                                            <h6 className="mb-0 font-semibold">Profile</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/banks' ? 'active' : ''}`} style={{ display: `${this.state.traveller_display}` }}>
                                        <Link to="/banks" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-diary-alt" /></span>
                                            <h6 className="mb-0 font-semibold">Bank Details</h6>
                                        </Link>
                                    </li>

                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/customer-address' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/customer-address" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-diary-alt" /></span>
                                            <h6 className="mb-0 font-semibold">Address</h6>
                                        </Link>
                                    </li>

                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/customer-documents' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/customer-documents" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-credit-card" /></span>
                                            <h6 className="mb-0 font-semibold">Documents</h6>
                                        </Link>
                                    </li>

                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/traveller-address' ? 'active' : ''}`} style={{ display: `${this.state.traveller_display}` }}>
                                        <Link to="/traveller-address" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-diary-alt" /></span>
                                            <h6 className="mb-0 font-semibold">Address</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/traveller-documents' ? 'active' : ''}`} style={{ display: `${this.state.traveller_display}` }}>
                                        <Link to="/traveller-documents" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-credit-card" /></span>
                                            <h6 className="mb-0 font-semibold">Documents</h6>
                                        </Link>
                                    </li>

                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/enquiries' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/enquiries" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-receipt" /></span>
                                            <h6 className="mb-0 font-semibold">Enquiries</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/traveller-orders' ? 'active' : ''}`} style={{ display: `${this.state.traveller_display}` }}>
                                        <Link to="/traveller-orders" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-process" /></span>
                                            <h6 className="mb-0 font-semibold">Orders</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/customer-orders' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/customer-orders" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-process" /></span>
                                            <h6 className="mb-0 font-semibold">Orders</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/customer-transactions' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/customer-transactions" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-bell" /></span>
                                            <h6 className="mb-0 font-semibold">Transactions</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/traveller-transactions' ? 'active' : ''}`} style={{ display: `${this.state.traveller_display}` }}>
                                        <Link to="/traveller-transactions" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-bell" /></span>
                                            <h6 className="mb-0 font-semibold">Transactions</h6>
                                        </Link>
                                    </li>
                                    <li className={`navbar-item account-menu ${this.props.location.pathname === '/customer-invoices' ? 'active' : ''}`} style={{ display: `${this.state.customer_display}` }}>
                                        <Link to="/customer-invoices" className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-setting" /></span>
                                            <h6 className="mb-0 font-semibold">Invoices</h6>
                                        </Link>
                                    </li>
                                    <li className="navbar-item account-menu" style={{ display: `${this.state.customer_display}` }}>
                                        <button onClick={this.customerLogout} className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-power" /></span>
                                            <h6 className="mb-0 font-semibold">Sign Out</h6>
                                        </button>
                                    </li>
                                    <li className="navbar-item account-menu" style={{ display: `${this.state.traveller_display}` }}>
                                        <button onClick={this.travellerLogout} className="navbar-link text-slate-400 flex items-center py-2 rounded">
                                            <span className="mr-2 text-[18px] mb-0"><i className="uil uil-power" /></span>
                                            <h6 className="mb-0 font-semibold">Sign Out</h6>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(UserSidebar)
