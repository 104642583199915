import React, { Component } from 'react'
import CustomerProfile from '../components/customer/signup/Profile'
import TravellerProfile from '../components/traveller/signup/Profile'
class ProfilePage extends Component {

  constructor() {
    super();
    this.state = {
      traveller_display_profile: '',
      customer_display_profile: ''
    }
  }

  componentDidMount() {
    let user_type = JSON.parse(localStorage.getItem('user_type'));
    if (user_type === 'Traveller') {
      this.setState({ traveller_display_profile: 'true' })
    }
    if (user_type === 'Customer') {
      this.setState({ customer_display_profile: 'true' })
    }
  }

  render() {
    const traveller_display_profile = this.state.traveller_display_profile;
    const customer_display_profile = this.state.customer_display_profile;
    if (traveller_display_profile === 'true') {
      return <TravellerProfile />
    } if (customer_display_profile === 'true') {
      return <CustomerProfile />
    }
  }
}

export default ProfilePage
