import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));
axios.defaults.headers.common['Authorization']= 'Bearer '+localStorage.getItem('token')
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


// Clear localStorage after 30 minutes
const cleanupLocalStorage = () => {
  const storedData = JSON.parse(localStorage.getItem('myData'));

  if (storedData) {
    const currentTime = new Date().getTime();
    const { data, expiration } = storedData;

    if (currentTime >= expiration) {
      // Data has expired, remove it
      localStorage.removeItem('user_type');
      localStorage.removeItem('user');
      localStorage.removeItem('profile_image');
      localStorage.removeItem('token');
       // Redirect to the login page
       window.location.href = '/login'; // Replace with your login page URL
    }
  }
};

setInterval(cleanupLocalStorage, 1800000); // 30 minutes

reportWebVitals();
