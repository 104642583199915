import React, { Component } from 'react'
import HowItWorks from '../components/website/common/HowItWorks'

class HowItWorksPage extends Component {
  render() {
    return (
      <HowItWorks />
    )
  }
}

export default HowItWorksPage