import React, { Component } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import AppUrl from '../../../api/AppUrl';
import { toast } from 'react-toastify';

class Contact extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            mobile_no: '',
            email: '',
            message: '',
            name_error: '',
            mobile_no_error: '',
            email_error: '',
            message_error: '',
            display_processing:'none',
            hide_submit:'block'

        }
    }

    userInputHandel = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }


    scrollToTop() {
        window.scrollTo(0, 0);
    }


    componentDidMount() {
        this.scrollToTop();
    }
    vaild = () => {
        let name_error = '';
        let mobile_no_error = '';
        let email_error = '';
        let message_error = '';

        if (!this.state.name) {
            name_error = "Please enter name"
        } else if (!this.state.email) {
            email_error = "Please enter email"
        } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
            email_error = "Please enter a valid email";
        } else if (!this.state.mobile_no) {
            mobile_no_error = "Please enter mobile number"
        } else if (!/^\d{10}$/.test(this.state.mobile_no)) {
            mobile_no_error = "Please enter a valid 10-digit mobile number";
        } else if (!this.state.message) {
            message_error = "Please enter messager"
        }
        if (name_error || email_error || mobile_no_error || message_error) {
            this.setState({
                name_error, email_error, mobile_no_error, message_error
            });
            return false;
        }
        return true;

    }

    conactUsSubmit = (e) => {
        e.preventDefault();
        const isValid = this.vaild();
        if (isValid) {
            this.setState({ 'display_processing': 'block', 'hide_submit': 'none' });
            const dataFromState = this.state;
            const postingData = {
                'name': dataFromState.name,
                'email': dataFromState.email,
                'mobile_no': dataFromState.mobile_no,
                'message' : dataFromState.message
            }
            axios.post(AppUrl.contactus, postingData).then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    window.location.href = decodeURIComponent('/contact');
                } else {
                    this.setState({ display_processing: 'none', hide_submit: 'block' })
                    toast.warning(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                }
            }).catch(error => {
            });
        }
    }

    render() {
        const imageUrl = 'assets/images/gray-abstract.jpg';
        const backgroundStyle = {
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Contact Us</span></h4>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic">Ready to discuss</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/17.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-14 mt-2">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-6 mt-5 md:mt-0">
                                <div className="">
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic">Mobile : <a href="tel: +91 9876543210">+91 9876543210</a></h1>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic">Email : <a href="mailto:info@indzip.com">info@indzip.com</a></h1>
                                </div>
                            </div>
                            <div className="lg:col-span-6 mt-5 md:mt-0">
                                <div className="bg-gray-50 dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-900">
                                    <div className="mt-1">
                                        <form>
                                            <div className="grid grid-cols-1 mt-4">
                                                <div className="mb-5">
                                                    <label className="form-label font-medium">Name<span className="text-red-600">*</span></label>
                                                    <input type="text" className="form-input" placeholder="Name" id="name" name="name" value={this.state.name} onChange={this.userInputHandel} />
                                                    <span className='required'>{this.state.name_error}</span>
                                                </div>
                                                <div className="mb-5">
                                                    <label className="form-label font-medium">Email<span className="text-red-600">*</span></label>
                                                    <input type="email" className="form-input" placeholder="Email" id="email" name="email" value={this.state.email} onChange={this.userInputHandel} />
                                                    <span className='required'>{this.state.email_error}</span>
                                                </div>
                                                <div className="mb-5">
                                                    <label className="form-label font-medium">Mobile No<span className="text-red-600">*</span></label>
                                                    <input type="text" className="form-input" placeholder="Mobile No" id="mobile_no" name="mobile_no" value={this.state.mobile_no} onChange={this.userInputHandel} maxLength={10} minLength={10}/>
                                                    <span className='required'>{this.state.mobile_no_error}</span>
                                                </div>
                                                <div className="mb-5">
                                                    <label className="form-label font-medium">Message<span className="text-red-600">*</span></label>
                                                    <textarea className="form-input textarea-sty" placeholder="Message" id="message" name="message" onChange={this.userInputHandel}>{this.state.message}</textarea>
                                                    <span className='required'>{this.state.message_error}</span>
                                                </div>
                                                <div className="">
                                                    <button type="button" onClick={(e) => this.conactUsSubmit(e)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full" style={{ display: `${this.state.hide_submit}`}}><i className="mdi mdi-login-variant mr-2"></i>Submit</button>
                                                    <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container md:mt-4 mt-10 md:mb-12">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="mt-1 text-center">
                                    <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Contact