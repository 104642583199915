import React, { Component } from 'react'
import Singup from '../components/website/login/Singup'

class SignUpPage extends Component {
    render() {
        return (
            <Singup />
        )
    }
}

export default SignUpPage
