import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import AppUrl from '../../../api/AppUrl'
import { toast } from 'react-toastify';
import withRouter from '../../../api/withRouter';
class Singup extends Component {

    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            mobile: '',
            password: '',
            name_error: '',
            email_error: '',
            mobile_error: '',
            password_error: '',
            display_processing: 'none',
            hide_submit: '',
            user_type: '',
            title: ''
        }
    }

    componentDidMount() {
        if (this.props.params.type) {
            if (this.props.params.type == 0) {
                this.setState({ user_type: 0, title: 'Customer' })
            } else if (this.props.params.type == 2) {
                this.setState({ user_type: 2, title: 'Traveller' })
            }
        }
    }


    handelUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    validate = () => {
        let name_error = '';
        let email_error = '';
        let mobile_error = '';
        let password_error = '';
        if (!this.state.name) {
            name_error = "Name required";
        } else if (!this.state.email) {
            email_error = "Email required";
        } else if (!this.state.mobile) {
            mobile_error = "Mobile required";
        } else if (!this.state.password) {
            password_error = "Password required";
        }
        if (name_error || email_error || mobile_error || password_error) {
            this.setState({ name_error, email_error, mobile_error, password_error });
            return false;
        }
        return true;
    }

    submitForm = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState({ display_processing: 'block', hide_submit: 'none' })
            const dataFromState = this.state;
            const postingData = {
                'name': dataFromState.name,
                'email': dataFromState.email,
                'mobile': dataFromState.mobile,
                'password': dataFromState.password,
                'user_type': dataFromState.user_type
            }
            axios.post(AppUrl.singup, postingData).then(response => {
                const status = response.data.status;
                if (status === 'success') {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    localStorage.setItem("token", response.data.token);
                    window.location.href = decodeURIComponent('/profile');

                } else if (status === 'exist') {
                    this.setState({ display_processing: 'none', hide_submit: 'block' })
                    toast.warning(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                } else if (status === 'error') {
                    this.setState({ display_processing: 'none', hide_submit: 'block' })
                    toast.warning(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                }
            }).catch(error => {

            });
        }
    }

    render() {
        return (
            <Fragment>
                <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/auth.html')] bg-no-repeat bg-center">
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black" />
                    <div className="container">
                        <div className="flex justify-center">
                            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                                <a href="index.html"><img src="assets/images/logo-icon-64.png" className="mx-auto" alt="" /></a>
                                <h5 className="my-6 text-xl font-semibold">{this.state.title} Singup</h5>
                                <form className="text-left">
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="name">Name: <span className="text-danger">*</span></label>
                                            <input id="name" name="name" type="text" className="form-input mt-3" placeholder="Enter Name" value={this.state.name} onChange={this.handelUserInput} />
                                            <span className='required'>{this.state.name_error}</span>
                                        </div>
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="mobile">Mobile: <span className="text-danger">*</span></label>
                                            <input id="mobile" name="mobile" type="text" className="form-input mt-3" placeholder="Enter Mobile no." value={this.state.mobile} maxLength={10} onChange={this.handelUserInput} />
                                            <span className='required'>{this.state.mobile_error}</span>
                                        </div>
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="email">Email Address: <span className="text-danger">*</span></label>
                                            <input id="email" name="email" type="email" className="form-input mt-3" placeholder="name@example.com" value={this.state.email} onChange={this.handelUserInput} />
                                            <span className='required'>{this.state.email_error}</span>
                                        </div>
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="password">Password: <span className="text-danger">*</span></label>
                                            <input id="password" name="password" type="password" className="form-input mt-3" placeholder="Password:" value={this.state.password} onChange={this.handelUserInput} />
                                            <span className='required'>{this.state.password_error}</span>
                                        </div>
                                        <div className="mb-4">
                                            <input type="submit" onClick={(e) => this.submitForm(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" value="Sign Up" />
                                            <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                        </div>
                                        <div className="text-center">
                                            <span className="text-slate-400 me-2"> have an account ?</span> <Link to="/login" className="text-black dark:text-white font-bold">Sign In</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default withRouter(Singup)
