import React, { Component } from 'react'
import AboutUs from '../components/website/common/AboutUs'

class AboutUsPage extends Component {
  render() {
    return (
      <AboutUs />
    )
  }
}

export default AboutUsPage