import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import AppUrl from '../../../api/AppUrl'
import { toast } from 'react-toastify';
import withRouter from '../../../api/withRouter';
import moment from 'moment';

class TravellerSignup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      middle_name: '',
      last_name: '',
      gender: '',
      dob: '',
      age: '',
      occupation_id: '',
      email: '',
      secondary_email: '',
      mobile: '',
      secondary_mobile: '',
      login_id: '',
      password: '',
      adhaar_number: '',
      adhaar_path: '',
      permanent_address: '',
      permanent_state: '',
      permanent_city: '',
      permanent_locality: '',
      permanent_pincode: '',
      current_address: '',
      current_state: '',
      current_city: '',
      current_locality: '',
      current_pincode: '',
      otp: '',
      //dropdown bind
      states: [],
      cities: [],
      localities: [],

      current_states: [],
      current_cities: [],
      current_localities: [],
      //Error display name
      first_name_error: '',
      middle_name_error: '',
      last_name_error: '',
      gender_error: '',
      dob_error: '',
      occupation_error: '',
      email_error: '',
      mobile_error: '',
      login_id_error: '',
      password_error: '',
      adhaar_number_error: '',
      adhar_path_error: '',
      permanent_address_error: '',
      permanent_city_error: '',
      permanent_locality_error: '',
      permanent_pincode_error: '',
      current_address_error: '',
      current_state_error: '',
      current_city_error: '',
      current_locality_error: '',
      current_pincode_error: '',
      display_processing: 'none',
      hide_submit: '',
      user_type: '',
      title: '',
      verify_div: 'none',
      otp_error: '',
      isSameAddress: false,
    }
  }

  handelUserInput = (e) => {
    let first_name_error = '';
    let middle_name_error = '';
    let last_name_error = '';
    let gender_error = '';
    let dob_error = '';
    let occupation_error = '';
    let email_error = '';
    let mobile_error = '';
    let login_id_error = '';
    let password_error = '';
    let adhaar_number_error = '';
    let adhar_path_error = '';
    let permanent_address_error = '';
    let permanent_state_error = '';
    let permanent_city_error = '';
    let permanent_locality_error = '';
    let permanent_pincode_error = '';
    let current_address_error = '';
    let current_state_error = '';
    let current_city_error = '';
    let current_locality_error = '';
    let current_pincode_error = '';

    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });

    if (name == 'email' && value != '') {
      this.setState({ login_id: value });
    }

    if (name == 'first_name') {
      this.setState({ first_name_error }, () => setTimeout(() => this.setState({ first_name_error: '' }), 5000));
    } else if (name == 'gender') {
      this.setState({ gender_error }, () => setTimeout(() => this.setState({ gender_error: '' }), 5000));
    } else if (name == 'dob') {
      //dob_error = "Please select date of birth";
      var today = new Date();
      var birthDate = new Date(value);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      this.setState({ age: age_now })
      this.setState({ dob_error }, () => setTimeout(() => this.setState({ dob_error: '' }), 5000));
    } else if (name == 'mobile') {
      this.setState({ mobile_error }, () => setTimeout(() => this.setState({ mobile_error: '' }), 5000));
    } else if (name == 'email') {
      this.setState({ email_error }, () => setTimeout(() => this.setState({ email_error: '' }), 5000));
    } else if (name == 'adhaar_number') {
      this.setState({ adhaar_number_error }, () => setTimeout(() => this.setState({ adhaar_number_error: '' }), 5000));
    } else if (name == 'adhaar_path') {
      this.setState({ adhar_path_error }, () => setTimeout(() => this.setState({ adhar_path_error: '' }), 5000));
    } else if (name == 'occupation_id') {
      this.setState({ occupation_error }, () => setTimeout(() => this.setState({ occupation_error: '' }), 5000));
    } else if (name == 'permanent_address') {
      this.setState({ permanent_address_error }, () => setTimeout(() => this.setState({ permanent_address_error: '' }), 5000));
    } else if (name == 'permanent_locality') {
      this.setState({ permanent_locality_error }, () => setTimeout(() => this.setState({ permanent_locality_error: '' }), 5000));
    } else if (name == 'permanent_pincode') {
      this.setState({ permanent_pincode_error }, () => setTimeout(() => this.setState({ permanent_pincode_error: '' }), 5000));
    } else if (name == 'current_address') {
      this.setState({ current_address_error }, () => setTimeout(() => this.setState({ current_address_error: '' }), 5000));
    } else if (name == 'current_locality') {
      this.setState({ current_locality_error }, () => setTimeout(() => this.setState({ current_locality_error: '' }), 5000));
    } else if (name == 'current_pincode') {
      this.setState({ current_pincode_error }, () => setTimeout(() => this.setState({ current_pincode_error: '' }), 5000));
    } else if (name == 'login_id') {
      this.setState({ login_id_error }, () => setTimeout(() => this.setState({ login_id_error: '' }), 5000));
    } else if (name == 'password') {
      this.setState({ password_error }, () => setTimeout(() => this.setState({ password_error: '' }), 5000));
    }
  }

  handleImageChange = (e) => {
    console.log(URL.createObjectURL(e.target.files[0]));
    this.setState({ adhaar_path: e.target.files[0] });
  }

  validate = () => {
    let first_name_error = '';
    let middle_name_error = '';
    let last_name_error = '';
    let gender_error = '';
    let dob_error = '';
    let occupation_error = '';
    let email_error = '';
    let mobile_error = '';
    let login_id_error = '';
    let password_error = '';
    let adhaar_number_error = '';
    let adhar_path_error = '';
    let permanent_address_error = '';
    let permanent_state_error = '';
    let permanent_city_error = '';
    let permanent_locality_error = '';
    let permanent_pincode_error = '';
    let current_address_error = '';
    let current_state_error = '';
    let current_city_error = '';
    let current_locality_error = '';
    let current_pincode_error = '';

    if (!this.state.first_name) {
      first_name_error = "Please enter first name";
    } else if (!this.state.gender) {
      gender_error = "Please select gender";
    } else if (!this.state.dob) {
      dob_error = "Please select date of birth";
    } else if (!this.state.mobile) {
      mobile_error = "Please enter primary mobile number";
    } else if (!this.state.email) {
      email_error = "Please enter primary email id";
    } else if (!this.state.adhaar_number) {
      adhaar_number_error = "Please enter adhaar number";
    } else if (!this.state.adhaar_path) {
      adhar_path_error = "Please upload adhaar card";
    } else if (!this.state.occupation_id) {
      occupation_error = "Please select occupation";
    } else if (!this.state.permanent_address) {
      permanent_address_error = "Please enter permanent address";
    } else if (!this.state.permanent_state) {
      permanent_state_error = "Please select state";
    } else if (!this.state.permanent_city) {
      permanent_city_error = "Please select city";
    } else if (!this.state.permanent_locality) {
      permanent_locality_error = "Please select locality";
    } else if (!this.state.permanent_pincode) {
      permanent_pincode_error = "Please enter pincode";
    } else if (!this.state.current_address) {
      current_address_error = "Please enter current address";
    } else if (!this.state.current_state) {
      current_state_error = "Please select state";
    } else if (!this.state.current_city) {
      current_city_error = "Please select city";
    } else if (!this.state.current_locality) {
      current_locality_error = "Please select locality";
    } else if (!this.state.current_pincode) {
      current_pincode_error = "Please enter pincode";
    } else if (!this.state.login_id) {
      login_id_error = "Please enter login id";
    } else if (!this.state.password) {
      password_error = "Please enter password required";
    }
    if (first_name_error || last_name_error || gender_error || dob_error || occupation_error ||
      email_error || mobile_error || login_id_error || password_error || adhaar_number_error || adhar_path_error ||
      permanent_address_error || permanent_state_error || permanent_city_error || permanent_locality_error || permanent_pincode_error || current_address_error || current_state_error || current_city_error || current_locality_error ||
      current_pincode_error) {
      this.setState({
        first_name_error, last_name_error, gender_error, dob_error, occupation_error,
        email_error, mobile_error, login_id_error, password_error, adhaar_number_error, adhar_path_error,
        permanent_address_error, permanent_state_error, permanent_city_error, permanent_locality_error, permanent_pincode_error, current_address_error, current_state_error, current_city_error, current_locality_error,
        current_pincode_error,
      });

      setTimeout(() => {
        this.setState({
          first_name_error: '',
          last_name_error: '',
          gender_error: '',
          dob_error: '',
          occupation_error: '',
          email_error: '',
          mobile_error: '',
          login_id_error: '',
          password_error: '',
          adhaar_number_error: '',
          adhar_path_error: '',
          permanent_address_error: '',
          permanent_state_error: '',
          permanent_city_error: '',
          permanent_locality_error: '',
          permanent_pincode_error: '',
          current_address_error: '',
          current_state_error: '',
          current_city_error: '',
          current_locality_error: '',
          current_pincode_error: '',
        });
      }, 3000);
      return false;
    }
    return true;
  }

  handleSameAddressChange = (e) => {
    this.setState({ isSameAddress: e.target.checked }, () => {
      if (e.target.checked) {
        this.setState(
          {
            current_address: this.state.permanent_address,
            current_state: this.state.permanent_state,
            current_city: this.state.permanent_city,
            current_locality: this.state.permanent_locality,
            current_pincode: this.state.permanent_pincode,
          },
          () => {
            this.changeCurrentState({ target: { value: this.state.permanent_state } });
            this.changeCurrentCity({ target: { value: this.state.permanent_city } });
          }
        );

        this.setState(
          {
            current_city: this.state.permanent_city,
            current_locality: this.state.permanent_locality,
          });
      } else {
        this.setState(
          {
            current_address: '',
            current_state: '',
            current_city: '',
            current_locality: '',
            current_pincode: '',
            current_cities: [],
            current_localities: []
          });
      }
    });
  };


  travellerOtpSend = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      const dataFromState = this.state;
      const postingData = {
        'email': dataFromState.email,
        'type': 2
      }
      axios.post(AppUrl.otpSend, postingData).then(response => {
        const status = response.data.status;
        const message = response.data.message;
        if (status === 'success') {
          this.setState({ verify_div: 'block', hide_submit: 'none' });
          toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      }).catch(error => {

      });
    }
  }

  verifyOtp = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState({ display_processing: 'block', hide_submit: 'none' })
      const dataFromState = this.state;
      const formData = new FormData();
      formData.append('first_name', dataFromState.first_name);
      formData.append('middle_name', dataFromState.middle_name);
      formData.append('last_name', dataFromState.last_name);
      formData.append('gender', dataFromState.gender);
      formData.append('dob', dataFromState.dob);
      formData.append('age', dataFromState.age);
      formData.append('mobile', dataFromState.mobile);
      formData.append('secondary_mobile', dataFromState.secondary_mobile);
      formData.append('email', dataFromState.email);
      formData.append('secondary_email', dataFromState.secondary_email);
      formData.append('adhaar_number', dataFromState.adhaar_number);
      formData.append('adhaar_path', dataFromState.adhaar_path);
      formData.append('occupation_id', dataFromState.occupation_id);
      formData.append('permanent_address', dataFromState.permanent_address);
      formData.append('permanent_state', dataFromState.permanent_state);
      formData.append('permanent_city', dataFromState.permanent_city);
      formData.append('permanent_pincode', dataFromState.permanent_pincode);
      formData.append('permanent_locality', dataFromState.permanent_locality);
      formData.append('current_address', dataFromState.current_address);
      formData.append('current_state', dataFromState.current_state);
      formData.append('current_city', dataFromState.current_city);
      formData.append('current_locality', dataFromState.current_locality);
      formData.append('current_pincode', dataFromState.current_pincode);
      formData.append('login_id', dataFromState.login_id);
      formData.append('password', dataFromState.password);
      formData.append('otp', dataFromState.otp);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      };
      axios.post(AppUrl.travellerSignup, formData, config).then(response => {
        const status = response.data.status;
        if (status === 'success') {
          window.location.href = '/thankyou';

        } else if (status === 'exist') {
          this.setState({ display_processing: 'none', hide_submit: 'block' })
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else if (status === 'error') {
          this.setState({ display_processing: 'none', hide_submit: 'block' })
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      }).catch(error => {

      });
    }
  }

  componentDidMount() {
    this.setState({
      states: [], cities: [], localities: []
    });
    axios.get(AppUrl.getStates(1)).then(response => {
      this.setState({
        states: response.data.states,
        current_states: response.data.states
      });
    });
  }

  // onChangePermentState
  changePermanentState = (e) => {
    let permanent_state_error = '';
    this.setState({
      permanent_state: e.target.value, cities: [], localities: []
    });
    if (e.target.value) {
      this.setState({ permanent_state_error }, () => setTimeout(() => this.setState({ permanent_state_error: '' }), 5000));
    }
    axios.get(AppUrl.getCity(e.target.value)).then(response => {
      console.log(response.data);
      this.setState({
        cities: response.data.cities
      });
    });
  }

  // onChangePermentCity
  changePermanentCity = (e) => {
    let permanent_city_error = '';
    this.setState({
      permanent_city: e.target.value, localities: []
    });
    if (e.target.value) {
      this.setState({ permanent_city_error }, () => setTimeout(() => this.setState({ permanent_city_error: '' }), 5000));
    }
    axios.get(AppUrl.getLocalities(e.target.value)).then(response => {
      this.setState({
        localities: response.data.localities
      })
    })
  }

  // onChangeCurrentState
  changeCurrentState = (e) => {
    let current_state_error = '';
    this.setState({
      current_state: e.target.value, current_cities: [], current_localities: []
    });
    if (e.target.value) {
      this.setState({ current_state_error }, () => setTimeout(() => this.setState({ current_state_error: '' }), 5000));
    }
    axios.get(AppUrl.getCity(e.target.value)).then(response => {
      this.setState({
        current_cities: response.data.cities
      });
    });
  }

  // onChangeCurrentCity
  changeCurrentCity = (e) => {
    let current_city_error = '';
    this.setState({
      current_city: e.target.value, current_localities: []
    });
    if (e.target.value) {
      this.setState({ current_city_error }, () => setTimeout(() => this.setState({ current_city_error: '' }), 5000));
    }
    axios.get(AppUrl.getLocalities(e.target.value)).then(response => {
      this.setState({
        current_localities: response.data.localities
      })
    })
  }

  handleKeyPress(e) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  }
  render() {

    const maxDate = new Date(); // Get the current date
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const maxDateFormatted = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1).toString().padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;

    return (
      <Fragment>
        <div>
          <section class="relative table w-full py-16 text-center lg:py-36 bg-gray-100">
            <div class="container">
              <div class="md:grid-cols-12 grid-cols-1 items-center mt-0 pb-5">
                <h4 class="font-extrabold text-7xl lg:text-7xl mb-5 text-center italic"><span class="text-red-600">Traveller Signup</span></h4>
              </div>
            </div>
          </section>
          {/* Start */}
          <section className=" md:py-24 bg-gray-50 dark:bg-slate-800" style={{ marginTop: '-17rem' }}>
            <div className="container ">
              <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                <div className="lg:col-span-8 md:order-2 order-1">
                  <div className="lg:ml-10">
                    <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-800">
                      <div className="mt-8">
                        <p className="mb-6 md:text-2xl text-1xl md:leading-normal leading-normal font-semibold"><b>Please complete the below registration form to continue further.</b></p>
                        <form>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">First Name<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                <input type="text" className="form-input" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handelUserInput} required maxlength={100} />
                                <span className='required'>{this.state.first_name_error}</span>
                              </div>
                            </div>
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Middle Name</label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                <input type="text" className="form-input" id="middle_name" name="middle_name" value={this.state.middle_name} onChange={this.handelUserInput} maxlength={100} />
                                <span className='required'>{this.state.middle_name_error}</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Last Name</label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                <input type="text" className="form-input" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handelUserInput} maxlength={100} />
                                <span className='required'>{this.state.last_name_error}</span>
                              </div>
                            </div>
                            <div>
                              <div>
                                <label htmlFor="typejob" className="font-semibold">Gender<span className="text-red-600 ml-1">*</span></label>
                                <select id="gender" name="gender" value={this.state.gender} onChange={this.handelUserInput} className="form-input mt-2">
                                  <option value="">-- Select --</option>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                  <option value="3">Other</option>
                                </select>
                                <span className='required'>{this.state.gender_error}</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Date of Birth<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                <input type="date" className="form-input" id="dob" name="dob" value={this.state.dob} onChange={this.handelUserInput} required placeholder="dd/mm/yy" max={maxDateFormatted} />
                                <span className='required'>{this.state.dob_error}</span>
                              </div>
                            </div>
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Age<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                <p><b> {this.state.age}</b></p>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Primary Mobile<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> */}
                                <input type="text" className="form-input" id="mobile" name="mobile" value={this.state.mobile} onChange={this.handelUserInput} maxlength={10} required minLength={10} onKeyPress={(e) => this.handleKeyPress(e)}/>
                                <span className='required'>{this.state.mobile_error}</span>
                              </div>
                            </div>
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Secondary Mobile</label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> */}
                                <input type="text" className="form-input" id="secondary_mobile" name="secondary_mobile" value={this.state.secondary_mobile} onChange={this.handelUserInput} maxLength={10} />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Primary Email Id<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                <input type="email" className="form-input" id="email" name="email" value={this.state.email} onChange={this.handelUserInput} required maxLength={100} />
                                <span className='required'>{this.state.email_error}</span>
                              </div>
                            </div>
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Secondary Email Id</label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                <input type="email" className="form-input" id="secondary_email" name="secondary_email" value={this.state.secondary_email} onChange={this.handelUserInput} required maxLength={100} />
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Adhaar Number<span className="text-red-600 ml-1">*</span></label><br />
                              <input type="text" className="form-input mt-2" id="adhaar_number" name="adhaar_number" value={this.state.adhaar_number} onChange={this.handelUserInput} maxlength={12} minLength={12} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                              <span className='required'>{this.state.adhaar_number_error}</span>
                            </div>
                            <div>
                              <label className="font-semibold" htmlFor="adhaar_path">Upload Adhaar<span className="text-red-600 ml-1">*</span></label>
                              <input className="form-input file-sty mt-2" id="adhaar_path" name="adhaar_path" type="file" accept="image/png, image/jpeg" onChange={this.handleImageChange} />
                              <span className='required'>{this.state.adhar_path_error}</span>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Occupation<span className="text-red-600 ml-1">*</span></label>
                              <select className="form-input mt-2" id="occupation_id" name="occupation_id" value={this.state.occupation_id} onChange={this.handelUserInput}>
                                <option value="">-- Select --</option>
                                <option value="1">Student</option>
                                <option value="2">Empolyed</option>
                              </select>
                              <span className='required'>{this.state.occupation_error}</span>
                            </div>
                          </div>
                          <h2 className="mb-3 mt-6 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold">Permanent Address</h2>
                          <label htmlFor="typejob" className="font-semibold">Address<span className="text-red-600 ml-1">*</span></label><br />
                          <input type="text" className="form-input mt-2" id="permanent_address" name="permanent_address" value={this.state.permanent_address} onChange={this.handelUserInput} required />
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">State<span className="text-red-600 ml-1">*</span></label>
                              <select id="permanent_state" name="permanent_state" value={this.state.permanent_state} onChange={this.changePermanentState} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.states.map((state, key) => {
                                    return <option key={key} value={state.id}>{state.state_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.permanent_address_error}</span>
                            </div>
                            <div>
                              <label htmlFor="permanent_city" className="font-semibold">City<span className="text-red-600 ml-1">*</span></label>
                              <select id="permanent_city" name="permanent_city" value={this.state.permanent_city} onChange={this.changePermanentCity} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.cities.map((city, key) => {
                                    return <option key={key} value={city.id}>{city.city_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.permanent_city_error}</span>
                            </div>
                            <div>
                              <label htmlFor="permanent_locality" className="font-semibold">Locality<span className="text-red-600 ml-1">*</span></label>
                              <select id="permanent_locality" name="permanent_locality" value={this.state.permanent_locality} onChange={this.handelUserInput} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.localities.map((locality, key) => {
                                    return <option key={key} value={locality.id}>{locality.locality_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.permanent_locality_error}</span>
                            </div>
                            <div>
                              <label className="form-label font-medium">Pin Code<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                <input type="text" className="form-input" id="permanent_pincode" name="permanent_pincode" value={this.state.permanent_pincode} onChange={this.handelUserInput} maxlength={6} minLength={6} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                                <span className='required'>{this.state.permanent_pincode_error}</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="typejob" className="font-semibold"><span className="text-red-600" /></label>
                            <div className="form-icon relative mt-2">
                            </div>
                          </div>
                          {/* <input type="submit" id="submit" name="send" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Save"> */}
                          <h2 className="mb-3 mt-6 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold">Current Address</h2>
                          <div>
                            <label htmlFor="typejob" className="font-semibold"><span className="text-red-600" /></label>
                            <div className="form-icon relative mt-4">
                              <input type="checkbox" id="check_permanent" className="cursorpointer mr-2" style={{ marginTop: 0, height: 15, width: 15 }} checked={this.state.isSameAddress}
                                onChange={this.handleSameAddressChange} /><span className="cursorpointer" for="check_permanent">Click if same as Permanent Address</span>
                            </div>
                          </div>
                          <label htmlFor="current_address" className="font-semibold">Address<span className="text-red-600 ml-1">*</span></label>
                          <div className="form-icon relative mt-2">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                            <input type="text" className="form-input" id="current_address" name="current_address" value={this.state.current_address} onChange={this.handelUserInput} required />
                            <span className='required'>{this.state.current_address_error}</span>
                          </div>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">State<span className="text-red-600 ml-1">*</span></label>
                              <select id="current_state" name="current_state" value={this.state.current_state} onChange={this.changeCurrentState} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.current_states.map((state, key) => {
                                    return <option key={key} value={state.id}>{state.state_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.current_state_error}</span>
                            </div>
                            <div>
                              <label htmlFor="current_city" className="font-semibold">City<span className="text-red-600 ml-1">*</span></label>
                              <select id="current_city" name="current_city" value={this.state.current_city} onChange={this.changeCurrentCity} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.current_cities.map((city, key) => {
                                    return <option key={key} value={city.id}>{city.city_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.current_city_error}</span>
                            </div>
                            <div>
                              <label htmlFor="current_locality" className="font-semibold">Locality<span className="text-red-600 ml-1">*</span></label>
                              <select id="current_locality" name="current_locality" value={this.state.current_locality} onChange={this.handelUserInput} className="form-input mt-2">
                                <option value="">-- Select --</option>
                                {
                                  this.state.current_localities.map((locality, key) => {
                                    return <option key={key} value={locality.id}>{locality.locality_name}</option>
                                  })
                                }
                              </select>
                              <span className='required'>{this.state.current_locality_error}</span>
                            </div>
                            <div>
                              <label className="form-label font-medium">Pin Code<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                <input type="text" className="form-input" id="current_pincode" name="current_pincode" value={this.state.current_pincode} onChange={this.handelUserInput} maxLength={6} minLength={6} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                                <span className='required'>{this.state.current_pincode_error}</span>
                              </div>
                            </div>
                          </div>
                          <h2 className="mb-3 mt-6 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold"> Login</h2>
                          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                            <div>
                              <label htmlFor="typejob" className="font-semibold">Email Id<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                <input type="text" className="form-input" id="login_id" name="login_id" value={this.state.login_id} onChange={this.handelUserInput} maxlength={100} required />
                                <span className='required'>{this.state.login_id_error}</span>
                              </div>
                            </div>
                            <div>
                              <label className="form-label font-medium">Passward<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-key w-4 h-4 absolute top-3 left-4"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg> */}
                                <input type="password" className="form-input" id="password" name="password" value={this.state.password} onChange={this.handelUserInput} maxlength={8} required />
                                <span className='required'>{this.state.password_error}</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-1 grid-cols-1 gap-5 mt-3">
                            <div style={{ display: `${this.state.verify_div}` }}>
                              <label htmlFor="otp" className="font-semibold">OTP<span className="text-red-600 ml-1">*</span></label>
                              <div className="form-icon relative mt-2">
                                <input type="password" className="form-input" name="otp" value={this.state.otp} onChange={this.handelUserInput} maxLength={4} minLength={4} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                                <span className='required'>{this.state.otp_error}</span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3" style={{ textAlign: 'center' }}>
                            <input type="submit" id="submit" name="send" onClick={(e) => this.verifyOtp(e)} style={{ display: `${this.state.verify_div}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Verify OTP" />
                            <input type="submit" id="resend_otp" name="resend_otp" onClick={(e) => this.travellerOtpSend(e)} style={{ display: `${this.state.verify_div}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Resend OTP" />
                            <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            {/* <input type="submit" id="submit" name="send" onClick={(e) => this.travellerOtpSend(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="SignUp" /> */}
                            <button type="button" id="submit" name="send" onClick={(e) => this.travellerOtpSend(e)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full"><i className="mdi mdi-login-variant mr-2"></i>Sign Up</button>
                          </div>
                        </form>
                        <div className="sin mt-6">
                          <a href="/traveller-login"><i className="mdi mdi-lock-reset mr-2"></i>Back to login<b class="text-indigo-600 ml-2">Traveller</b></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4" style={{ marginTop: '-40rem' }}>
                  <h3 className="mb-6 text-3xl md:leading-normal leading-normal font-semibold">Welcome to INDZIP – India’s premium personal hand carriage service</h3>
                  {/* <img src="assets/images/login.png" /> */}
                  <p className="text-xl text-left text-dark font-semibold">Become a traveller with INDZIP and earn over ₹ 5000 per trip, per day.
                    You can choose when and where you wish to travel with our parcels as per available orders.
                    Unlimited earning potential for yourself and a great opportunity to visit new cities for free!</p>
                </div>
              </div>{/*end grid*/}
            </div>{/*end container*/}
          </section>{/*end section*/}
          {/* End */}
        </div>

      </Fragment>
    )
  }
}
export default TravellerSignup