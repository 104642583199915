import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import axios from 'axios';
import withRouter from '../../../api/withRouter';

class OrderList extends Component {

    constructor() {
        super();
        this.state = {
            orderData: []
        }
    }
    componentDidMount() {
        const customer_id = JSON.parse(localStorage.getItem('user'));
        axios.get(AppUrl.customerOrderList(customer_id.id)).then(response => {
            this.setState({ orderData: response.data.orderData })
        })
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-process"></i></span>Orders</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div>
                                        {
                                            this.state.orderData.map((list, i) => {
                                                return <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900">
                                                    <div className="flex-1 content ml-4">
                                                        <h4 className="text-lg text-medium font-bold">Order No. :{list.order_id}</h4>
                                                        <hr className="mt-3 mb-3" />
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Enquiry No. :</span>{list.enquiry_id}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Date :</span>{list.order_date}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">From :</span>{list.pickup_city}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">To :</span>{list.drop_city}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Status :</span>{list.status_name}</p>
                                                    </div>
                                                    <div>
                                                        <button onClick={(e) => this.props.orderDetails(list.id, list.e_id)} className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></button>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default withRouter(OrderList)
