import React, { Component, Fragment } from 'react'
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import withRouter from '../../../api/withRouter';
import axios from 'axios'

class TransactionList extends Component {

    constructor() {
        super();
        this.state = {
            transactions: []
        }
    }

    componentDidMount() {
        const user_id = JSON.parse(localStorage.getItem('user'));
        axios.get(AppUrl.CustomerTransactionList(user_id.id)).then(response => {
            const status = response.data.status
            if (status === 'success') {
                this.setState({ transactions: response.data.transactionList.data })
            }
        })
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-bell"></i></span>Transactions</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div>
                                        {
                                            this.state.transactions.map((list, key) => {
                                                return <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900" key={key}>
                                                    <div class="flex-1 content ml-4">
                                                        <h4 className="text-lg text-medium font-bold">Transaction No. :{list.trn_no}</h4>
                                                        <hr className="mt-3 mb-3" />
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Date :</span>{list.trn_date}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Amount :</span>{list.amount}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Order No. :</span>{list.order_id}</p>
                                                        <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Reference No. :</span>{list.ref_no}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default TransactionList