import React, { Component, Fragment } from 'react'

class UserSidebar extends Component {
    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="profile-banner relative text-transparent">
                        <input id="pro-banner" name="profile-banner" type="file" className="hidden" onChange="loadFile(event)" />
                        <div className="relative shrink-0">
                            <img src="assets/images/header-bg.jpg" className="h-80 w-full object-cover" id="profile-banner" alt />
                            <div className="absolute inset-0 bg-black/70" />
                        </div>
                    </div>
                </div>{/*end container*/}
            </Fragment>
        )
    }
}

export default UserSidebar
