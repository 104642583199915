import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import axios from 'axios';

class Enquiry extends Component {

    constructor() {
        super();
        this.state = {
            enquiryList: [],
            document_count: '',
            display_div: 1,
            documentName: []
        }
    }

    componentDidMount() {
        const customer_id = JSON.parse(localStorage.getItem('user'));
        axios.get(AppUrl.enquiryList(customer_id.id)).then((response) => {
            this.setState({ enquiryList: response.data.enquiryList,display_div: response.data.getCount, documentName: response.data.documentName });
        })
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-receipt"></i></span>Enquiry</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    {
                                        this.state.display_div > 0 ? (
                                            <div>
                                                {/* <input type="submit" onClick={(e) => this.props.addEnquiry()} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Send New Enquiry " /> */}
                                                <button type="button" onClick={(e) => this.props.addEnquiry()} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-2"><i className="mdi mdi-plus mr-2"></i>Add</button>
                                                {
                                                    this.state.enquiryList.map((list, i) => {
                                                        return <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                                                            <div className="flex-1 content ml-4">
                                                                <h4 className="text-lg text-medium font-bold">Enquiry No. :{list.enquiry_id}</h4>
                                                                <hr className="mt-3 mb-3" />
                                                                <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Date :</span>{list.enquiry_date}</p>
                                                                <p className="text-dark-400 mb-0"><span className="font-bold mr-2">From :</span>{list.pickup_form}</p>
                                                                <p className="text-dark-400 mb-0"><span className="font-bold mr-2">To :</span>{list.drop_address}</p>
                                                                <p className="text-dark-400 mb-0"><span className="font-bold mr-2">Status :</span>{list.enquiry_status}</p>
                                                            </div>
                                                            <div>
                                                                <button onClick={(e) => this.props.enquiryDetails(list.id)} className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></button>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        ) : (
                                            <div><b>Please Upload Following Document:-</b> {this.state.documentName.join(', ')}</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default Enquiry
