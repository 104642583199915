import React, { Component } from 'react'
import Traveller from '../components/traveller/signup/TravellerSignup'

class TravellerSignupPage extends Component {
  render() {
    return (
      <div>
        <Traveller />
      </div>
    )
  }
}

export default TravellerSignupPage
