import React, { Component, Fragment } from 'react'
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import axios from 'axios';
import AppUrl from '../../../api/AppUrl';
import { toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import withRouter from '../../../api/withRouter';

class AddAddress extends Component {

    constructor() {
        super();
        this.state = {
            traveller_id: '',
            id: '',
            address: '',
            landmark: '',
            country_id: '',
            countries: [],
            state_id: '',
            city_id: '',
            locality_id: '',
            address_type: '',
            states: [],
            cities: [],
            localities: [],
            pincode: '',
            hide_submit: '',
            display_processing: 'none',
            address_error: '',
            landmark_error: '',
            country_error: '',
            address_type_error: '',
            state_error: '',
            city_error: '',
            locality_error: '',
            pincode_error: '',
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        axios.get(AppUrl.getCountry).then(response => {
            const countries = response.data.countries;
            this.setState({ countries: countries, states: [], cities: [], localities: [], traveller_id: userInfo.id });
        });

        if (this.props.id) {
            this.setState({ id: this.props.id });
            axios.get(AppUrl.travellerAddressEdit(this.props.id)).then(response => {
                console.log(response.data.states);
                this.setState({
                    address: response.data.singleData.address,
                    landmark: response.data.singleData.landmark,
                    pincode: response.data.singleData.pincode,
                    country_id: response.data.singleData.country_id,
                    state_id: response.data.singleData.state_id,
                    city_id: response.data.singleData.city_id,
                    address_type: response.data.singleData.address_type,
                    locality_id: response.data.singleData.locality_id,
                    states: response.data.states,
                    cities: response.data.cities,
                    localities: response.data.localities,
                })
            });
        }
    }

    ChangeCountry = (e) => {
        let country_error = '';
        this.setState({
            country_id: e.target.value, states: [], cities: [], localities: []
        });
        if (e.target.value) {
            this.setState({ country_error }, () => setTimeout(() => this.setState({ country_error: '' }), 5000));
        }
        axios.get(AppUrl.getStates(e.target.value)).then(response => {
            this.setState({
                states: response.data.states
            });
        });
    }

    ChangeteState = (e) => {
        let state_error = '';
        this.setState({
            state_id: e.target.value, cities: [], localities: []
        });
        if (e.target.value) {
            this.setState({ state_error }, () => setTimeout(() => this.setState({ state_error: '' }), 5000));
        }
        axios.get(AppUrl.getCity(e.target.value)).then(response => {
            console.log(response.data);
            this.setState({
                cities: response.data.cities
            });
        });
    }

    ChangeCity = (e) => {
        let city_error = '';
        this.setState({
            city_id: e.target.value, localities: []
        });
        if (e.target.value) {
            this.setState({ city_error }, () => setTimeout(() => this.setState({ city_error: '' }), 5000));
        }
        axios.get(AppUrl.getLocalities(e.target.value)).then(response => {
            this.setState({
                localities: response.data.localities
            })
        })
    }

    handelUserInput = (e) => {
        let address_error = '';
        let landmark_error = '';
        let locality_error = '';
        let pincode_error = '';
        let address_type_error = '';

        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });

        if (name == 'address') {
            this.setState({ address_error }, () => setTimeout(() => this.setState({ address_error: '' }), 5000));
        } else if (name == 'landmark') {
            this.setState({ landmark_error }, () => setTimeout(() => this.setState({ landmark_error: '' }), 5000));
        } else if (name == 'locality_id') {
            this.setState({ locality_error }, () => setTimeout(() => this.setState({ locality_error: '' }), 5000));
        } else if (name == 'pincode') {
            this.setState({ pincode_error }, () => setTimeout(() => this.setState({ pincode_error: '' }), 5000));
        } else if (name == 'address_type') {
            this.setState({ address_type_error }, () => setTimeout(() => this.setState({ address_type_error: '' }), 5000));
        }
    }

    validate = () => {
        let address_error = '';
        let landmark_error = '';
        let country_error = '';
        let state_error = '';
        let city_error = '';
        let locality_error = '';
        let pincode_error = '';
        let address_type_error = '';
        let numberPattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);

        if (!this.state.address) {
            address_error = "Please enter address";
        } else if (!this.state.landmark) {
            landmark_error = "please enter ladmark";
        } else if (!this.state.country_id) {
            country_error = "Please select country";
        } else if (!this.state.state_id) {
            state_error = "Please select state";
        } else if (!this.state.city_id) {
            city_error = "Please select city";
        } else if (!this.state.locality_id) {
            locality_error = "Please select locality";
        } else if (!this.state.pincode || !numberPattern.test(this.state.pincode)) {
            pincode_error = "Please enter pincode";
        } else if (!this.state.address_type) {
            address_type_error = "Please select address type";
        }
        if (address_error || landmark_error || country_error || state_error || city_error || locality_error || pincode_error || address_type_error) {
            this.setState({ address_error, landmark_error, country_error, state_error, city_error, landmark_error, pincode_error, address_type_error });

            setTimeout(() => {
                this.setState({
                    address_error: '',
                    landmark_error: '',
                    country_error: '',
                    state_error: '',
                    city_error: '',
                    pincode_error: '',
                    address_type_error: '',
                })
            }, 3000);
            return false;
        }
        return true;
    }

    addressSubmitForm = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState({ 'display_processing': 'block', 'hide_submit': 'none' });
            const dataFromState = this.state;
            const postingData = {
                'id': dataFromState.id,
                'address': dataFromState.address,
                'landmark': dataFromState.landmark,
                'country_id': dataFromState.country_id,
                'state_id': dataFromState.state_id,
                'city_id': dataFromState.city_id,
                'locality_id': dataFromState.locality_id,
                'pincode': dataFromState.pincode,
                'address_type': dataFromState.address_type,
                'traveller_id': dataFromState.traveller_id
            }

            axios.post(AppUrl.travellerAddressSave, postingData).then(response => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    window.location.href = decodeURIComponent('/traveller-address');
                }
            }).catch(error => {

            });
        }
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg font-bold mb-4">Add New Address</h3>
                                <h5 className="text-sm font-bold mb-4">Input your permanent, current and any other location addresses separately to enable us to match you to order pick-up closest to your location.</h5>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <form>
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label className="form-label font-medium">Address <span className="text-red-600">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <FeatherIcon icon="user" className="w-4 h-4 absolute top-3 left-4" />
                                                    <input type="text" className="form-input pl-12" id="address" name="address" value={this.state.address} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.address_error}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Landmark</label>
                                                <div className="form-icon relative mt-2">
                                                    <FeatherIcon icon="user" className="w-4 h-4 absolute top-3 left-4" />
                                                    <input type="text" className="form-input pl-12" id="landmark" name="landmark" value={this.state.landmark} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.landmark_error}</span>
                                                </div>
                                            </div>
                                        </div><br />
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="country_id" className="font-semibold">Country <span className="text-red-600">*</span></label>
                                                <select id="country_id" name="country_id" value={this.state.country_id} onChange={this.ChangeCountry} className="form-input mt-3">
                                                    <option value="">Select Country</option>
                                                    {
                                                        this.state.countries.map((country, key) => {
                                                            return <option key={key} value={country.id}>{country.country_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.country_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="state_id" className="font-semibold">State <span className="text-red-600">*</span></label>
                                                <select id="state_id" className="form-input mt-3" name="state_id" value={this.state.state_id} onChange={this.ChangeteState}>
                                                    <option value="">Select State</option>
                                                    {
                                                        this.state.states.map((state, key) => {
                                                            return <option key={key} value={state.id}>{state.state_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.state_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">City <span className="text-red-600">*</span></label>
                                                <select id="city_id" name="city_id" value={this.state.city_id} onChange={this.ChangeCity} className="form-input mt-3">
                                                    <option value="">Select City</option>
                                                    {
                                                        this.state.cities.map((city, key) => {
                                                            return <option key={key} value={city.id}>{city.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.city_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="locality_id" className="font-semibold">Locality <span className="text-red-600">*</span></label>
                                                <select id="locality_id" name="locality_id" className="form-input mt-3" value={this.state.locality_id} onChange={this.handelUserInput}>
                                                    <option value="">Select Locality</option>
                                                    {
                                                        this.state.localities.map((locality, key) => {
                                                            return <option key={key} value={locality.id}>{locality.locality_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.locality_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Pin Code <span className="text-red-600">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" />
                                                    <input type="text" className="form-input pl-12" id="pincode" name="pincode" maxLength="6" value={this.state.pincode} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.pincode_error}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="address_type" className="font-semibold">Address Type <span className="text-red-600">*</span></label>
                                                <select id="address_type" name="address_type" value={this.state.address_type} onChange={this.handelUserInput} className="form-input mt-3">
                                                    <option value="">Select</option>
                                                    <option value="1">Permanent Address</option>
                                                    <option value="2">Current Address</option>
                                                    <option value="3">Office Address</option>
                                                </select>
                                                <span className='required'>{this.state.address_type_error}</span>
                                            </div>
                                        </div>
                                        <input type="submit" id="address_submit" name="address_submit" onClick={(e) => this.addressSubmitForm(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Submit Address" />
                                        <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                    </form>{/*end form*/}
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default withRouter(AddAddress)
