import React, { Component, Fragment } from 'react'
import axios from 'axios'
import AppUrl from '../../../api/AppUrl'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import { toast } from 'react-toastify';

class AddDocument extends Component {

    constructor() {
        super();
        this.state = {
            id: '',
            traveller_id: '',
            document_type: '',
            document_no: '',
            file_path: '',
            display_processing: 'none',
            hide_submit: '',
            document_type_error: '',
            document_no_error: '',
            file_path_error: '',
            documentType: [],
            on_chnage_image_status:''
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const user_type = JSON.parse(localStorage.getItem('user_type'));
        const dataPost = {
            'id' : userInfo.id,
            'user_type' : user_type
        }
        axios.post(AppUrl.documentType,dataPost).then(response => {
            this.setState({ documentType: response.data.documentType, traveller_id: userInfo.id })
        })
    }

    handelUserInput = (e)  => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    handleImageChange = (e) => {
        console.log(URL.createObjectURL(e.target.files[0]));
        this.setState({ file_path : e.target.files[0]});
    }

    validate = () => {
        let document_type_error = '';
        let document_no_error = '';
        let file_path_error = '';
        if (!this.state.document_type) {
            document_type_error = 'Please select document type';
        } else if (!this.state.document_no) {
            document_no_error = "Please enter document no";
        } else if (!this.state.file_path) {
            file_path_error = "Please select document file"
        }  else if (this.state.document_type == 1) {
            const isValidAadhar = /^[0-9]{12}$/.test(this.state.document_no);
            if (!isValidAadhar) {
                document_no_error = "Please enter valid Addhar no";
            }
        } else if (this.state.document_type == 2) {
            const isValidPan = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(this.state.document_no);
            if (!isValidPan) {
                document_no_error = "Please enter valid Pan no";
            }
        } 
        if(document_type_error || document_no_error || file_path_error){
            this.setState({ document_type_error, document_no_error,file_path_error })

            setTimeout(() => {
                this.setState({
                    document_type_error: '',
                    document_no_error: '',
                    file_path_error: '',
                });
            }, 3000);
            
            return false;
        }
        return true;
    }

    submitDocumentForm = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState({ 'display_processing' : 'block', 'hide_submit' : 'none' });
            const dataFromState = this.state;
            const formData = new FormData();
            formData.append('id', dataFromState.id);
            formData.append('traveller_id', dataFromState.traveller_id);
            formData.append('document_type', dataFromState.document_type);
            formData.append('document_no', dataFromState.document_no);
            formData.append('file_path', dataFromState.file_path);
            const config = {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
            };
            axios.post(AppUrl.travellerDocumentSave, formData, config).then((response) => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    window.location.href = decodeURIComponent('/traveller-documents');
                }
            }).catch(error => {

            });
        }
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg font-bold mb-4">Add Document</h3><br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <form>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="document_type" className="font-semibold">Document Type <span className="text-red-600">*</span></label>
                                                <select id="document_type" className="form-input mt-3" name="document_type" value={this.state.document_type} onChange={this.handelUserInput}>
                                                    <option value="">Select Document Type</option>
                                                    {
                                                        this.state.documentType.map((document, key) => {
                                                            return <option value={document.id} key={key}>{document.type}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.document_type_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Document No <span className="text-red-600">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <FeatherIcon icon="user" className="w-4 h-4 absolute top-3 left-4" />
                                                    <input type="text" className="form-input pl-12" id="document_no" name="document_no" value={this.state.document_no} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.document_no_error}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="font-semibold" htmlFor="file_path">Upload file:</label>
                                                <input className="form-input mt-3" id="file_path" type="file" name="file_path" accept="image/png, image/jpeg" onChange={this.handleImageChange} />
                                                <span className='required'>{this.state.file_path_error}</span>
                                            </div>
                                        </div>
                                        <input type="submit" id="document_submit" name="document_submit" onClick={(e) => this.submitDocumentForm(e)}  style={{ display: `${this.state.hide_submit} `}} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Submit Document" />
                                        <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                    </form>{/*end form*/}
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default AddDocument