import React, { Component , Fragment} from 'react'
import Login from '../components/website/login/Login'
class LoginPage extends Component {
    render() {
        return (
            <Fragment>
                <Login />
            </Fragment>
        )
    }
}

export default LoginPage
