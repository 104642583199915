import React, { Component, Fragment } from 'react'
import UserHeader from '../website/common/UserHeader';
import UserSidebar from '../website/common/UserSidebar';
import axios from 'axios';
import AppUrl from '../../api/AppUrl';
import { toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import withRouter from '../../api/withRouter';

class BankAdd extends Component {

  constructor() {
    super();
    this.state = {
      user_id: '',
      id: '',
      bank_name: '',
      branch: '',
      state_id: '',
      city_id: '',
      account_no: '',
      ifsc_code: '',
      cancel_document_path: '',
      states: [],
      cities: [],
      hide_submit: '',
      display_processing: 'none',
      bank_name_error: '',
      branch_error: '',
      state_id_error: '',
      city_id_error: '',
      account_no_error: '',
      ifsc_code_error: '',
      file_path_error: '',
    }
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    axios.get(AppUrl.getStates(1)).then(response => {
      this.setState({
        states: response.data.states, cities: [], user_id: userInfo.id
      });
    });

    if (this.props.id) {
      this.setState({ id: this.props.id });
      axios.get(AppUrl.bankEdit(this.props.id)).then(response => {
        axios.get(AppUrl.getCity(response.data.singleData.state_id)).then(response => {
          this.setState({
            cities: response.data.cities
          });
        });
        this.setState({
          bank_name: response.data.singleData.bank_name,
          branch: response.data.singleData.branch,
          state_id: response.data.singleData.state_id,
          city_id: response.data.singleData.city_id,
          account_no: response.data.singleData.account_no,
          ifsc_code: response.data.singleData.ifsc_code,
          cancel_document_path: response.data.singleData.cancel_document_path,
          id: response.data.singleData.id,
        })
      });
    }
  }

  ChangeteState = (e) => {
    this.setState({
      state_id: e.target.value, cities: [], localities: []
    });
    axios.get(AppUrl.getCity(e.target.value)).then(response => {
      console.log(response.data);
      this.setState({
        cities: response.data.cities
      });
    });
  }


  handelUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleImageChange = (e) => {
    this.setState({ cancel_document_path: e.target.files[0] });
  }


  validate = () => {
    let bank_name_error = '';
    let branch_error = '';
    let state_id_error = '';
    let city_id_error = '';
    let account_no_error = '';
    let ifsc_code_error = '';
    let file_path_error = '';
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!this.state.bank_name) {
      bank_name_error = "Please enter bank name";
    } else if (!this.state.branch) {
      branch_error = "Please enter bank branch name";
    } else if (!this.state.state_id) {
      state_id_error = "Please select state";
    } else if (!this.state.city_id) {
      city_id_error = "Please select city";
    } else if (!this.state.account_no) {
      account_no_error = "Please enter account no";
    } else if (!this.state.ifsc_code) {
      ifsc_code_error = "Please enter IFSC code";
    } else if (!ifscPattern.test(this.state.ifsc_code)) {
      ifsc_code_error = "Please enter vaild IFSC code";
    } else if (!this.state.cancel_document_path) {
      file_path_error = "Please select document file"
    }
    if (bank_name_error || branch_error || state_id_error || city_id_error || account_no_error || ifsc_code_error || file_path_error) {
      this.setState({ bank_name_error, branch_error, state_id_error, city_id_error, account_no_error, ifsc_code_error, file_path_error });

      setTimeout(() => {
        this.setState({
          bank_name_error: '',
          branch_error: '',
          state_id_error: '',
          city_id_error: '',
          account_no_error: '',
          ifsc_code_error: '',
          file_path_error: '',
        });
      }, 3000);
      return false;
    }
    return true;
  }

  bankSubmitForm = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState({ 'display_processing': 'block', 'hide_submit': 'none' });
      const dataFromState = this.state;
      const formData = new FormData();
      formData.append('id', dataFromState.id);
      formData.append('bank_name', dataFromState.bank_name);
      formData.append('branch', dataFromState.branch);
      formData.append('state_id', dataFromState.state_id);
      formData.append('city_id', dataFromState.city_id);
      formData.append('account_no', dataFromState.account_no);
      formData.append('ifsc_code', dataFromState.ifsc_code);
      formData.append('user_id', dataFromState.user_id);
      formData.append('cancel_document_path', dataFromState.cancel_document_path);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      };

      axios.post(AppUrl.bankSave, formData, config).then(response => {
        const status = response.data.status;
        const message = response.data.message;
        if (status === 'success') {
          this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
          toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          window.location.href = decodeURIComponent('/banks');
        } else if (status === 'error') {
          this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      }).catch(error => {

      });
    }
  }

  handleKeyPress(e) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    return (
      <Fragment>
        <section className="relative lg:pb-24 pb-16">
          <UserHeader />
          <div className="container lg:mt-24 mt-16">
            <div className="md:flex">
              <UserSidebar />
              <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                <h3 className="text-lg font-bold mb-4">Add New Bank</h3>
                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                  <form>
                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                      <div>
                        <label className="form-label font-medium">Bank Name <span className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <FeatherIcon icon="briefcase" className="w-4 h-4 absolute top-3 left-4" />
                          <input type="text" className="form-input pl-12" id="bank_name" name="bank_name" value={this.state.bank_name} onChange={this.handelUserInput} />
                          <span className='required'>{this.state.bank_name_error}</span>
                        </div>
                      </div>
                      <div>
                        <label className="form-label font-medium">Branch</label>
                        <div className="form-icon relative mt-2">
                          <FeatherIcon icon="home" className="w-4 h-4 absolute top-3 left-4" />
                          <input type="text" className="form-input pl-12" id="branch" name="branch" value={this.state.branch} onChange={this.handelUserInput} />
                          <span className='required'>{this.state.branch_error}</span>
                        </div>
                      </div>
                    </div><br />
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                      <div>
                        <label htmlFor="state_id" className="font-semibold">State <span className="text-red-600">*</span></label>
                        <select id="state_id" className="form-input mt-3" name="state_id" value={this.state.state_id} onChange={this.ChangeteState}>
                          <option value="">Select State</option>
                          {
                            this.state.states.map((state, key) => {
                              return <option key={key} value={state.id}>{state.state_name}</option>
                            })
                          }
                        </select>
                        <span className='required'>{this.state.state_error}</span>
                      </div>
                      <div>
                        <label htmlFor="typejob" className="font-semibold">City <span className="text-red-600">*</span></label>
                        <select id="city_id" name="city_id" value={this.state.city_id} onChange={this.handelUserInput} className="form-input mt-3">
                          <option value="">Select City</option>
                          {
                            this.state.cities.map((city, key) => {
                              return <option key={key} value={city.id}>{city.city_name}</option>
                            })
                          }
                        </select>
                        <span className='required'>{this.state.city_error}</span>
                      </div>
                      <div>
                        <label className="form-label font-medium">Account No<span className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" />
                          <input type="text" className="form-input pl-12" id="account_no" name="account_no" value={this.state.account_no} onChange={this.handelUserInput} onKeyPress={(e) => this.handleKeyPress(e)} maxLength={18} />
                          <span className='required'>{this.state.account_no_error}</span>
                        </div>
                      </div>
                      <div>
                        <label className="form-label font-medium">IFSC<span className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                          <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" />
                          <input type="text" className="form-input pl-12" id="ifsc_code" name="ifsc_code" value={this.state.ifsc_code} onChange={this.handelUserInput} maxLength={11} />
                          <span className='required'>{this.state.ifsc_code_error}</span>
                        </div>
                      </div>
                      <div>
                        <label className="font-semibold" htmlFor="file_path">Cancelled Cheque <span className="text-red-600">*</span></label>
                        <input className="form-input file-sty" id="cancel_document_path" type="file" name="cancel_document_path" accept="image/png, image/jpeg" onChange={this.handleImageChange} />
                        <span className='required'>{this.state.file_path_error}</span>
                      </div>
                    </div>
                    <input type="submit" id="bank_submit" name="bank_submit" onClick={(e) => this.bankSubmitForm(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Submit Bank" />
                    <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                  </form>{/*end form*/}
                </div>
              </div>
            </div>{/*end grid*/}
          </div>{/*end container*/}
        </section>{/*end section*/}
        {/* End Hero */}
      </Fragment>
    )
  }
}

export default BankAdd
