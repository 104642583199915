import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import AppUrl from '../../../api/AppUrl'

class Home extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props);
        this.state = {
            addressList: [],
            price: '',
            pickup_form: '',
            drop_to: '',
            weight: '',
            form_locality_error: '',
            drop_to_error: '',
            weight_error: '',
            display_processing: 'none',
            display_price: 'none',
            isModalOpen: false,
            from_city_name: '',
            to_city_name: ''
        }
    }

    componentDidMount() {
        axios.get(AppUrl.getCity(0)).then(response => {
            this.setState({ addressList: response.data.cities })
        });
        this.scrollToTop();
    }

    handelChange = (e) => {
        let form_locality_error = '';
        let drop_to_error = '';
        let weight_error = '';
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });

        if (name == 'pickup_form') {
            this.setState({ from_city_name: e.target.options[e.target.selectedIndex].text });
        }

        if (name == 'drop_to') {
            this.setState({ to_city_name: e.target.options[e.target.selectedIndex].text });
        }
        if (name == 'pickup_form') {
            this.setState({ form_locality_error }, () => setTimeout(() => this.setState({ form_locality_error: '' }), 5000));
        } if (name == 'drop_to') {
            this.setState({ drop_to_error }, () => setTimeout(() => this.setState({ drop_to_error: '' }), 5000));
        } if (name == 'weight') {
            this.setState({ weight_error }, () => setTimeout(() => this.setState({ weight_error: '' }), 5000));
        }
    }

    validate = () => {
        let form_locality_error = '';
        let drop_to_error = '';
        let weight_error = '';
        const number_reg = RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        // single filed validation
        if (!this.state.pickup_form) {
            form_locality_error = "Please select from city.";
        } else if (!this.state.drop_to) {
            drop_to_error = "Please select to city.";
        } else if (!this.state.weight || !number_reg.test(this.state.weight)) {
            weight_error = "Please enter valid weight.";
        }
        if (form_locality_error || drop_to_error || weight_error) {
            this.setState({ form_locality_error, drop_to_error, weight_error });
            setTimeout(() => {
                this.setState({
                    form_locality_error: '',
                    drop_to_error: '',
                    weight_error: '',
                });
            }, 5000);
            return false;
        }
        return true;
    }

    // Function to open the modal
    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    // Function to close the modal
    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    getPrice = (e) => {
        e.preventDefault();
        const dataFromState = this.state;
        const isValid = this.validate();
        if (isValid) {
            this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
            const postingData = {
                'pickup_form': dataFromState.pickup_form,
                'drop_to': dataFromState.drop_to,
            }
            axios.post(AppUrl.getPrice, postingData).then((response) => {
                const status = response.data.status;
                const price = response.data.price;
                if (status === 'success') {
                    this.setState({ 'display_price': 'block' });
                    this.setState({ price: price });
                    this.openModal();
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                } else {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    this.setState({ 'display_price': 'none' });
                }
            }).catch(error => {

            });
        }
    }

    render() {
        const imageUrl = 'assets/images/home_banner_bg4.png';
        const backgroundStyle = {
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div>
                <section className="relative table w-full py-30 text-center lg:py-40 bg-gray-100 home-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-7xl lg:text-7xl mb-5 text-left italic"><span className="text-red-600">INDZIP</span><br />Faster than Courier</h4>
                                    <p className="text-xl text-left text-dark font-semibold">The INDZIP web app is simple and easy to use. Once you have placed your hand carrier request, our certified traveller will pick up your parcel and travel the journey on your behalf in real time. And if you need an entire aircraft, it will be made available too.</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="bg-gray-900 p-3 shadow dark:shadow-gray-900">
                                    <div className="mt-1">
                                        <h2 className="md:text-3xl text-white font-semibold">Get Instant Price</h2>
                                    </div>
                                </div>
                                <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-900">
                                    <form>
                                        <div className="grid grid-cols-1 mt-4 text-left">
                                            <div className="mb-5">
                                                <label for="typejob" className="font-semibold">From City <span className="text-red-600">*</span></label>
                                                <select id="pickup_form" name="pickup_form" className="form-input mt-3" value={this.state.pickup_form} onChange={this.handelChange}>
                                                    <option value=''>-- Select --</option>
                                                    {
                                                        this.state.addressList.map((address, key) => {
                                                            return <option value={address.id} key={key}>{address.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span style={{ color: 'red' }}>{this.state.form_locality_error}</span>
                                            </div>
                                            <div className="mb-5">
                                                <label for="typejob" className="font-semibold"> To City <span className="text-red-600">*</span></label>
                                                <select id="drop_to" name="drop_to" value={this.state.drop_to} onChange={this.handelChange} className="form-input mt-2">
                                                    <option value=''>-- Select --</option>
                                                    {
                                                        this.state.addressList.map((address, key) => {
                                                            return <option value={address.id} key={key}>{address.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span style={{ color: 'red' }}>{this.state.drop_to_error}</span>
                                            </div>
                                            <div className="mb-5">
                                                <label className="form-label font-medium">Weight<span className="text-red-600">*</span></label>
                                                <input type="text" className="form-input" placeholder="Weight" id="weight" name="weight" value={this.state.weight} onChange={this.handelChange} />
                                                <span style={{ color: 'red' }}>{this.state.weight_error}</span>
                                            </div>
                                            <div className="">
                                                <button type="button" onClick={(e) => this.getPrice(e)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full">Submit</button>
                                                <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal fade bd-example-modal-md custom-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div id="modalHeader" className="modal-header">
                                            <h5 className="modal-title" id="myLargeModalLabel">
                                            Price Estimate
                                            </h5>
                                            <button type="button" className="close" onClick={this.closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <h1>From &nbsp;&nbsp;&nbsp;: <span>{`${this.state.from_city_name}`}</span></h1>
                                            <h1>To &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <span>{`${this.state.to_city_name}`}</span></h1>
                                            <h1>Price* &nbsp;: ₹<span>{`${this.state.price}`}</span></h1>
                                            <br></br>
                                            <span>* Estimate all-inclusive price from door to door using fastest available mode of transport. GST/Taxes will be additional.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>

                <section className="relative py-6">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-6">
                                <img className="img-round rounded-3xl" src="assets/img/13.jpg" />
                            </div>
                            <div className="lg:col-span-6 mt-5 md:mt-0">
                                <div className="lg:ml-10">
                                    <h3 className="mb-6 md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">How <span className="font-extrabold">INDZIP</span> helps you</h3>
                                    <p className="text-dark max-w-xl text-xl">INDZIP provides urgent hand carry and air charter services. Whether you need to send a document for signature from one city to another and get it back the same day, or hand deliver an urgently needed parcel to someone across India or worldwide, or require an entire aircraft from anywhere to anywhere, we can get it moving instantly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-6 mt-5 md:mt-0">
                                <div className="lg:ml-10">
                                    <h3 className="mb-6 md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">Why Choose <span className="font-extrabold">INDZIP?</span></h3>
                                    <p className="text-dark max-w-xl text-xl">We provide the fastest option by directly picking up your parcel, dashing to the nearest airport, catching the next flight and delivering it to your recipient. Our model is simple, quick and personal. Even if your cargo is really heavy at 100,000 kilos, no problem, we will arrange an entire cargo aircraft to move it across the world for you. INDZIP literally zips your goods to the required destination.</p>
                                </div>
                            </div>
                            <div className="lg:col-span-6">
                                <img className="img-round rounded-3xl" src="assets/img/14.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container md:mt-4 mt-10 md:mb-12">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <h3 className="mb-6 md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">Want to know more about <span className="text-red-600">INDZIP?</span></h3>
                                    <p className="text-dark mx-auto text-xl text-left">INDZIP was founded by international air logistics specialists with a single mission - to create a seamless service that offers speedy same day hand delivery from India to anywhere. There is no limit to speed, so why wait an extra day when you can get yours moving right now</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="mt-1 text-center">
                                    <a href="/about-us" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">ABOUT US</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Home