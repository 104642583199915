import React, { Component } from 'react'
import Traveller from '../components/website/common/Traveller'

class TravellerPage extends Component {
  render() {
    return (
      <Traveller />
    )
  }
}

export default TravellerPage