import React, { Component, Fragment } from 'react'
import UserHeader from '.././website/common/UserHeader';
import UserSidebar from '.././website/common/UserSidebar';
import axios from 'axios';
import AppUrl from '../../api/AppUrl';
import FeatherIcon from 'feather-icons-react';

class BankList extends Component {

  constructor() {
    super();
    this.state = {
      banks: []
    }
  }

  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('user'));
    axios.get(AppUrl.bankDetails(userInfo.id)).then(response => {
      this.setState({ banks: response.data.banksDetails });
    });
  }

  render() {
    return (
      <Fragment>
        <section className="relative lg:pb-24 pb-16">
          <UserHeader />
          <div className="container lg:mt-24 mt-16">
            <div className="md:flex">
              <UserSidebar />
              <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                <div>
                  <div className="mt-[30px] lg:mt-0">
                    <button onClick={() => this.props.addBank()} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Add New Bank</button>
                    {
                      this.state.banks.map((list, i) => {

                        return <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                          <div className="flex-1 content ml-4">
                            <h4 className="text-lg text-medium">Bank Name: <b>{list.bank_name}</b></h4>
                            <p className="text-slate-400 mb-0">Branch : <b>{list.branch}</b></p>
                            <p className="text-slate-400 mb-0">State : <b>{list.state_name}</b></p>
                            <p className="text-slate-400 mb-0">City : <b>{list.city_name}</b></p>
                            <p className="text-slate-400 mb-0">Account No : <b>{list.account_no}</b></p>
                            <p className="text-slate-400 mb-0">IFSC Code : <b>{list.ifsc_code}</b></p>
                            <p className="text-slate-400 mb-0">Document : <a href={AppUrl.documentPath + '/traveller/document/' + list.user_id + '/' + list.cancel_document_path} target="_blank" className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></p>
                          </div>
                          <div>
                            <button onClick={() => this.props.edit(list.id)} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="edit" className="h-4 w-4" /></button>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>

              </div>
            </div>{/*end grid*/}
          </div>{/*end container*/}
        </section>{/*end section*/}
        {/* End Hero */}
      </Fragment>
    )
  }
}

export default BankList
