import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import withRouter from '../../../api/withRouter';
import { toast } from 'react-toastify';
import axios from 'axios';

class OrderDetails extends Component {

    constructor() {
        super();
        this.state = {
            status_id: '',
            document_img: '',
            enquiryList: [],
            products: [],
            documentData: [],
            orderDetail: [],
            invoice: '',
            travellerDetails: [],
            travellerStatus: [],
        }
    }

    componentDidMount() {
        const order_id = this.props.order_id;
        const enquiry_id = this.props.enquiry_id;
        axios.get(AppUrl.travellerOrderDetail(order_id, enquiry_id)).then(response => {
            this.setState({
                orderDetail: response.data.orderData,
                enquiryList: response.data.enquiryList,
                documentData: response.data.documents,
                products: response.data.products,
                invoice: response.data.invoice,
                travellerDetails: response.data.travellerDetails
            });
        })

        axios.get(AppUrl.getTravellerStatus).then(response => {
            const travellerStatus = response.data.traveller_status;
            this.setState({ travellerStatus: travellerStatus });
        });
    }

    handelUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const postingData = {
            'status_id': value,
            'order_id': this.state.orderDetail.id,
            'created_by': userInfo.id
        }
        axios.post(AppUrl.travellerStatusUpdate, postingData).then(response => {
            const status = response.data.status;
            const message = response.data.message;
            if (status === 'success') {
                this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            }
        }).catch(error => {

        });
    }

    handelChangeDoc = (e)  => {
        const { name, value } = e.target;
        const document_img = this.state.document_img;
        const userInfo = JSON.parse(localStorage.getItem('user'));
        const postingData = {
            'document_img': e.target.files[0],
            'order_id': this.state.orderDetail.id,
            'created_by': userInfo.id
        }
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };
        axios.post(AppUrl.travellerOrderDocumentUpdate, postingData,config).then(response => {
            const status = response.data.status;
            const message = response.data.message;
            if (status === 'success') {
                this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
                e.target.value = null;
            }
        }).catch(error => {

        });
       
    }
    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div>
                                <div>
                                    <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                        <table className="w-full text-left">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-5">Order No</th>
                                                    <th className="px-4 py-5 text-right">{this.state.orderDetail.order_id}</th>
                                                </tr>
                                                <tr>
                                                    <th className="px-4 py-5">Order Date</th>
                                                    <th className="px-4 py-5 text-right">{this.state.orderDetail.created_at}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <td className="p-4"><a href="#" className><i className="uil uil-clipboard-alt" /> Enquiry No</a></td>
                                                    <td className="p-4 text-right">{this.state.enquiryList.enquiry_id}</td>
                                                </tr>
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <td className="p-4"><a href="#" className><i className="uil uil-clipboard-alt" /> Enquiry Date</a></td>
                                                    <td className="p-4 text-right">{this.state.enquiryList.enquiry_date}</td>
                                                </tr>
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <td className="p-4"><a href="#" className><i className="uil uil-clipboard-alt" /> Status</a></td>
                                                    <td className="p-4 text-right"><span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">{this.state.orderDetail.status_name}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div><br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                        <div>
                                            <label htmlFor="typejob" className="font-semibold">Pickup From </label>
                                            <p><i className="uil uil-map-marker text-lg mr-2" /> {this.state.enquiryList.pickup_form}</p>
                                            <p><i className="uil uil-user text-lg mr-2" /> {this.state.enquiryList.pickup_person}</p>
                                        </div>
                                    </div>
                                </div><br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                        <div>
                                            <label htmlFor="typejob" className="font-semibold">Deliver To </label>
                                            <p><i className="uil uil-map-marker text-lg mr-2" /> {this.state.enquiryList.drop_address}</p>
                                            <p><i className="uil uil-user text-lg mr-2" /> {this.state.enquiryList.drop_person}</p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    {
                                        this.state.products.map((product, key) => {

                                            return <div key={key}>
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Product Type</label>
                                                        <p><b>{product.product_type}</b></p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Product Name</label>
                                                        <p><b>{product.product_name}</b>  <a href={AppUrl.documentPath + '/customer/enquiry/product/' + product.enquiry_id + '/' + product.enquiry_img} target="_blank" className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Quantity</label>
                                                        <p><b>{product.qty}</b></p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Weight</label>
                                                        <p><b>{product.weight}</b></p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Value</label>
                                                        <p><b>{product.product_value}</b></p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Packing Type</label>
                                                        <p><b>{product.packing_type}</b></p>
                                                    </div>
                                                </div><br />
                                                <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Description</label>
                                                        <p>{product.description}  </p>
                                                    </div>
                                                </div>
                                                <br /><hr /><br />
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    this.state.documentData.length>0 && (
                                    this.state.documentData.map((document, key) => {
                                        return <div key={key} className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                <div>
                                                    <label htmlFor="typejob" className="font-bold">Document Name</label>
                                                    <p>{document.document_name} </p>
                                                </div>
                                                <div>
                                                    <label htmlFor="typejob" className="font-bold">Image</label>
                                                    <p><a href={AppUrl.documentPath + '/customer/enquiry/document/' + document.enquiry_id + '/' + document.document_img} target="_blank" className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></p>
                                                </div>
                                            </div>
                                            <hr className="mt-3 mb-3" />
                                        </div>
                                    })
                                )}
                                <br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                        <div>
                                            <label htmlFor="typejob" className="font-semibold">Traveller Details </label>
                                            {
                                                this.state.travellerDetails.map((detail, key) => {
                                                    return <div key={key}>
                                                        <p><i className="uil uil-user text-lg mr-2" /> {detail.traveller_name}</p>
                                                        <p><i className="uil uil-phone text-lg mr-2" /> {detail.mobile}</p>
                                                        <br />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                        <div>
                                            <label htmlFor="status_id" className="font-semibold">Delivery Status </label>
                                            <select id="status_id" name="status_id" value={this.state.status_id} onChange={this.handelUserInput} className="form-input mt-3">
                                                <option value="">Select Status</option>
                                                {
                                                    this.state.travellerStatus.map((status, key) => {
                                                        return <option key={key} value={status.id}>{status.status_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <br />                
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                        <div>
                                            <label className="font-semibold" htmlFor="document_img">Upload image:</label>
                                            <input className="form-input mt-3" id="document_img" name="document_img" type="file" onChange={this.handelChangeDoc} />
                                        </div>
                                    </div>
                                    <br /><hr /><br />
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default withRouter(OrderDetails)
