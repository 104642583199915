import React, { Component, Fragment } from 'react'
import AppUrl from '../../../api/AppUrl';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import axios from 'axios'

class Login extends Component {

    constructor() {
        super();
        this.state = {
            login_id: '',
            password: '',
            user_type: '',
            user_type_error: '',
            login_id_error: '',
            password_error: '',
            hide_submit: 'block',
            display_processing: 'none'
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        this.scrollToTop();
    }

    userInputHandel = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })
    }

    vaild = () => {
        let login_id_error = '';
        let password_error = '';
        let user_type_error = '';
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!this.state.user_type) {
            user_type_error = "Please select user type"
        } else if (!this.state.login_id) {
            login_id_error = "Please enter valid login id"
        } else if (!this.state.password) {
            password_error = "Please enter valid password"
        }
        if (login_id_error || password_error || user_type_error) {
            this.setState({
                login_id_error, password_error, user_type_error
            });
            return false;
        }
        return true;
    }

    login = (e) => {
        e.preventDefault();
        const isValid = this.vaild();
        if (isValid) {
            const dataFromState = this.state;
            const postingData = {
                'login_id': dataFromState.login_id,
                'password': dataFromState.password
            }
            axios.post(AppUrl.singin, postingData).then((response) => {
                const status = response.data.status;
                if (status == 'success') {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    window.location.href = decodeURIComponent('/profile');
                } else {
                    this.setState({ display_processing: 'none', hide_submit: 'block' })
                    toast.warning(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                }
            }).catch(error => {
            });
        }
    }

    render() {
        return (
            <div>
                <section class="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div class="container">
                        <div class="md:grid-cols-12 grid-cols-1 items-center mt-20 pb-5">
                            <h4 class="font-extrabold text-6xl lg:text-6xl mb-5 italic"><span class="text-red-600">Sign In / REGISTER</span></h4>
                        </div>
                    </div>
                </section>
                <section class="relative py-14 mt-2">
                    <div class="container md:mt-4 mt-10 md:mb-12">
                        <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px]">
                            <div class="md:col-span-6">
                                <div class="mt-1 text-center">
                                    <Link to="/customer-login" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 text-5xl login-btn-sty">CUSTOMER</Link>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="mt-1 text-center">
                                    <Link to="/traveller-login" class="btn bg-black hover:bg-black border-black hover:border-black text-white rounded-3xl p-3 text-5xl login-btn-sty">TRAVELLER</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Login
