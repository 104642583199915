import React, { Component } from 'react'
import CustomerSignup from '../components/customer/signup/CustomerSignup'

class CustomerSignupPage extends Component {
  render() {
    return (
      <div>
        <CustomerSignup />
      </div>
    )
  }
}

export default CustomerSignupPage
