import React, { Component } from 'react'
import { Link } from "react-router-dom";
class Customer extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
      }

        
    componentDidMount() {
        this.scrollToTop();
    }
    
    render() {
        const imageUrl = 'assets/images/gray-abstract.jpg';
        const backgroundStyle = {
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Customer</span></h4>
                                    <h1 className="text-sb font-extrabold text-left italic line-height-1">We serve anyone who requires urgent mobility.</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/22.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                    <section className="relative py-6 mt-2">
                        <div className="container">
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                                <div className="lg:col-span-12 mt-5 md:mt-0">
                                    <div className="text-center">
                                        <p className="mb-6 text-xl md:leading-normal leading-normal font-semibold">INDZIP serves all categories of customers. We can deliver anything for anyone who has an urgent requirement for using our hand carriage and air charter services. We offer seamless mobility services for a wide range of product categories such as Automotive, Technology, Medical, Event Planners, Legal Professionals and several others.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6 mt-2">
                        <div className="container">
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                                <div className="lg:col-span-12 mt-5 md:mt-0">
                                    <div className="text-center">
                                        <p className="mb-6 text-3xl md:leading-normal leading-normal font-semibold">Examples of our customer categories are as below:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                                <div className="md:col-span-6">
                                    <div className="bg-gray-900 p-3 shadow dark:shadow-gray-900">
                                        <h1 className="text-sb mb-5 font-extrabold text-left text-white italic md:pl-10 mt-10">HAND CARRIER</h1>
                                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-2 items-center md:pl-10 mt-10 mb-10 gap-[30px]">
                                            <div className="md:col-span-6">
                                                <ul className="list-none text-white mt-4">
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Automotive</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Technology</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Electronics</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>E-Commerce</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Hospitals</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Fashion Designers</li>
                                                </ul>
                                            </div>
                                            <div className="md:col-span-6">
                                                <ul className="list-none text-white mt-4">
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Collectibles</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Legal Firms</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Professionals</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Event Planners</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Architects</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Celebrities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:col-span-6">
                                    <div className="bg-red-600 p-3 shadow dark:shadow-red-600">
                                        <h1 className="text-sb mb-5 font-extrabold text-left text-white italic md:pl-10 mt-10">AIRCRAFT CHARTER</h1>
                                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-2 items-center md:pl-10 mt-10 mb-10 gap-[30px]">
                                            <div className="md:col-span-6">
                                                <ul className="list-none text-white mt-4">
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Exporters</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Government</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Research Institutions</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Oil & Gas Companies</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Mining Companies</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Shipping</li>
                                                </ul>
                                            </div>
                                            <div className="md:col-span-6">
                                                <ul className="list-none text-white mt-4">
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Relief Agencies</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Aerospace Companies</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Celebrities</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Event Planners</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Corporations</li>
                                                    <li className="mb-1 flex"><i className="uil uil-check-circle text-white-600 text-xl mr-2"></i>Freight Forwarders</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <h3 className="mb-6 md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">Want to know more about <span className="text-red-600">INDZIP?</span></h3>
                                        <p className="text-dark mx-auto text-xl text-left">INDZIP was founded by international air logistics specialists with a single mission - to create a seamless service that offers speedy same day hand delivery from India to anywhere. We offer seamless mobility services for a wide range of product categories such as Automotive, Technology, Medical, Event Planners, Legal Professionals and several others.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/about-us" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">About Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container">
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                                <div className="md:col-span-12">
                                    <h1 className="text-sb text-center font-extrabold">Seamless mobility for a wide range of products</h1>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/24.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Documents</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/25.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Technology</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/26.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Automotive</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/27.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Medical</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/28.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Fashion</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/29.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Luxury</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/30.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Wedding</p>
                                </div>
                                <div className="lg:col-span-3">
                                    <img className="img-round rounded-3xl" src="assets/img/31.jpg" />
                                        <p className="text-dark mx-auto text-red-600 text-center font-semibold text-xl mt-2">Aerospace</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container">
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                                <div className="lg:col-span-6 mt-5 md:mt-0">
                                    <div className="lg:ml-10">
                                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold"><span className="font-extrabold text-red-600">INDZIP Aircraft Charter</span></h3>
                                        <p className="text-black max-w-xl text-xl font-semibold mb-5">Entire Aircraft? No Problem.</p>
                                        <p className="text-dark max-w-xl text-xl"><span className="font-extrabold">INDZIP</span> provides dedicated air charter services whenever and wherever you need it. When time is paramount, we provide you with the most suitable aircraft for speedy delivery to any location in the world. We also assist with documentation, origin pick-up and destination deliveries using our global agent network.<br /><br />
                                            We also provide one-way aircraft availabilities and try to optimize on cargo capacity on available aircrafts that results in immense cost saving for our customers. </p>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6">
                                        <img className="img-round rounded-3xl" src="assets/img/23.jpg" />
                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                                    <div className="lg:col-span-12 mt-5 md:mt-0">
                                        <div className="text-center">
                                            <p className="mb-6 text-xl md:leading-normal leading-normal"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                                    <div className="lg:col-span-12 mt-5 md:mt-0">
                                        <div className="text-left">
                                            <p className="mb-6 text-3xl md:leading-normal leading-normal font-semibold">Some of the available aircrafts are as below:</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                                    <div className="lg:col-span-8 mt-5 md:mt-0">
                                        <center>
                                            <img className="img-round rounded-3xl" src="assets/img/09.jpg" />
                                        </center>
                                    </div>
                                    <div className="lg:col-span-4 mt-5 md:mt-0">
                                        <center>
                                            <img className="img-round rounded-3xl" src="assets/img/08.jpg" />
                                        </center>
                                    </div>
                                </div>
                            </div>
                    </section>
                    
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <h3 className="mb-6 md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">Frequently asked questions by Customers</h3>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <a href="/customers-faq" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">CUSTOMER FAQ</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        )
    }
}
export default Customer