import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
 
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const path = useNavigate();
  const location = useLocation();
  return (
    <WrappedComponent
      {...props}
      params={params}
      history= {path}
      location = {location}
    />
  );
};
 
export default withRouter;
