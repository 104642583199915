import React, { Component } from 'react'
import CustomersFAQ from '../components/website/common/CustomersFAQ'

class CustomersFAQPage extends Component {
  render() {
    return (
      <CustomersFAQ />
    )
  }
}

export default CustomersFAQ