import React, { Component } from 'react'
import { Link } from "react-router-dom";

class Policy extends Component {
    scrollToTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Privacy Policy</span></h4>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/19.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-14 mt-2">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-12 mt-5 md:mt-0">
                                <div className="">
                                    <h6 class="mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">General information</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>At INDZIP, we are particularly committed to protecting your personal data. We wish to provide you below with comprehensive and transparent information regarding how your personal data are processed when you use our product application (hereinafter referred to as app).</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>As a matter of principle, we only process your personal data in accordance with the applicable legal regulations. This applies equally to processing required to establish and define the content of the contractual relationship (basic data) and processing that facilitates use of our services and offerings (usage data).</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>Your personal data are only then processed for other purposes if you have expressly agreed to this processing – in other words, if we have received your consent. Of course, you can contact us at any time if you have any queries or concerns. For any queries or requests in relation to data protection, please contact info@indzip.com directly to ensure these are dealt with promptly.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Personal data</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	Personal data are specific information on personal or actual characteristics relating to a specific natural person or which can be used to identify a natural person. Examples of such data include name, address and telephone number. Data which cannot be linked directly to a specific identity – such as analysis and statistical data (e.g. favorite websites or number of users of a site) – are not considered personal data.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Basic data</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	If a contractual relationship is to be established between you and us, or if the content of such a relationship is to be defined or amended, we use personal data from you for this provided this use is necessary for these purposes. These are usually contact and address data as well as invoicing data.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Usage data</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	We collect and use personal data from you provided these are necessary to facilitate use of our online services. In particular, this includes characteristics for your identification and information regarding the period and scope of use of our app.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Data for own business purposes</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	We collect, process and use your personal data provided in connection with your app profile for our own purposes. We only process your personal data in relation to services and never pass these on to unauthorized third parties. Our courier service customers have no access to your personal data. It is always our decision as to which travel assignment we assign to you.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Collection and processing</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	We adhere to the principles of data avoidance and data minimization. Therefore, we only store personal data for as long as this is necessary to achieve the purposes set forth herein or as stipulated in the various retention periods envisaged by the legislator. Once the respective purpose ends or such periods expire, the relevant data are routinely blocked or erased in accordance with legal provisions.
                                            	When you use a contact form to contact us, the information provided is stored for the purpose of processing the request and for possible follow-up questions.
                                            	When you rate our services, we process your personal data if you have provided these to us voluntarily with a declaration of consent. Of course, you may withdraw your declaration of consent at any time.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Login details</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	You must initially set up a user profile to access the app functions.
                                            	The login details collected during this process are stored together with your profile.
                                            	You are obliged to keep your login details secret; shared profiles are never permitted.
                                            	Login details are limited to your email address and a password you pick yourself for us to identify you.
                                            	We also record your cell phone number to verify that you are the user of the account.
                                            	You have the option of receiving orders directly via SMS to your cell phone if you do not wish to use or make any further use of the app we offer.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Profile data</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	All mandatory information provided during the registration process is easily identifiable by an asterisk (*). Information without an asterisk is provided by you to us voluntarily so that we can send you orders tailored to you. Of course, your personal data are never used for other purposes.
                                            	We process the following data in connection with your app profile: data regarding your identity, your contact and/or location data and your preferences and skill set.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">  Services of other providers</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	Our Privacy Policy expressly does not include third-party services. Therefore, the privacy policies of the respective providers apply. Of course, we ensure that our requirements in respect of data processing are always complied with where we commission third parties (our contractual partners, in other words) to process your data.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">  Our contractual partners</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	app is hosted by a contracted hosting provider. This means that we save your data on our partner’s servers. Our partner has no access to your data and simply provides the technical infrastructure for operating the app.
                                            	In order to invoice our customers for our services, your data are transferred by our IT service provider to our in-house invoicing process. Our contractual arrangements and the technical and organizational protective measures taken ensure a secure processing environment for your personal data in this regard.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Other third parties</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	app is available from third parties in the App Store and Google Play Store (sales platform). We have no influence over the collection, processing or use of personal data in connection with the respective app store; in this respect, the controller is solely the operator of the respective app store.
                                            	The privacy policies of the respective providers apply to the use of third-party back-up services (e.g. cloud solutions). We have no influence over the collection, processing or use of personal data in connection with the provision of back-up services.
                                            	In order to facilitate your work for us as a traveler, we pass on your data, depending on the respective order, to such companies without whose services it would not be possible to fulfill the assignment. In particular, this includes airlines and hotels. Please be assured that we only cooperate with such companies if they can prove an adequate level of data protection. If you would like to find out more about providers that have received your data in conjunction with an already completed transport assignment, simply contact us directly to request this information.
                                            	You can use your location through the offered “Google Maps” positioning and map service through the Google Maps API. Google Maps is a service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.

                                            	When you use Google Maps, information concerning your use of this app (including your IP address) may be transmitted to and stored on a Google server in the USA. Google may also share the information obtained through Maps with third parties where required to do so by law or where third parties process this data on Google’s behalf.

                                            	Google never associates your IP address with other Google data. Nevertheless, it may still be technically possible for Google to identify at least some users based on the data obtained. It is possible that personal data and personality profiles of website users may be processed by Google for other purposes over which we have and can have no influence. The Google Privacy Policy and Google Maps Additional Terms of Service can be found at
                                            https://www.google.com/intl/en_en/help/terms_maps.html.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Cookies</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	We also analyze how our app is used so that we can track user preferences and optimize our app.
                                            	We use Google Analytics, a web analytics service provided by Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA).
                                            	The IP address transmitted by your browser in relation to Google Analytics is not merged with other Google data. On behalf of the operator of this website, Google uses this information to analyze your usage of the website, to compile reports on the website activities for us and to render further services to the website operator connected with the usage of the website and of the Internet. Our legitimate interests in data processing also lie in these purposes.
                                            	You can prevent cookies being stored by adjusting your browser software appropriately. You can also prevent the data generated by the cookie and related to your usage of the App (incl. your IP address) being collected by Google and the processing of this data by Google by downloading and installing the “browser add-on” (available at: https://tools.google.com/dlpage/gaoptout?hl=en).
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Your rights</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	You have at all times the following rights, which you may assert vis-à-vis us without charge on request:
                                            	Right to rectify your personal data
                                            	Right to the erasure of your personal data
                                            	Right to restrict the processing of your personal data (block)
                                            	Right to data portability of your personal data
                                            	Right of access to your personal data processed by us and
                                            	Right to comprehensive and transparent information regarding the processing of your personal data
                                            Please note we may have to query you further if we cannot identify you on the basis of your request. In spite of our endeavors, it is not possible to process your request without the minimum required information.
                                            Please feel free to contact us by email (info@indzip.com).
                                            Please note that complete confidentiality and data security cannot be ensured at all times when communicating via the Internet (e.g. via email). Therefore, we recommend that you use a postal service if confidential information is involved.
                                        </li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">  Final provisions</h6>    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>
                                            	We reserve the right to update these privacy provisions from time to time to take into account changes in law or extensions to the functional scope of app. The date of the last update at the bottom of the Privacy Policy is amended accordingly in such cases. Therefore, we recommend that you regularly read the privacy provisions in order to remain up to date on the protection of the personal data we store.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container md:mt-4 mt-10 md:mb-12">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="mt-1 text-center">
                                    <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Policy