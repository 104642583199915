import React, { Component } from 'react'
import Document from '../../components/traveller/document/Document';
import AddDocument from '../../components/traveller/document/AddDocument';

class TravellerDocumentPage extends Component {
    constructor() {
        super();
        this.state = {
            pageDisplay: '',
            id: ''
        }
    }

    addDocument = () => {
        this.setState({ pageDisplay: 'add' })
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        this.scrollToTop();
    }


    render() {
        const pageDisplay = this.state.pageDisplay;
        if (pageDisplay === 'add') {
            return <AddDocument />
        } else {
            return (<Document addDocument={this.addDocument} />)
        }
    }
}

export default TravellerDocumentPage
