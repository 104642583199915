import React, { Component, Fragment } from 'react'
import axios from 'axios'
import AppUrl from '../../../api/AppUrl'
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import FeatherIcon from 'feather-icons-react';

class Document extends Component {

    constructor() {
        super();
        this.state = {
            customer_id: '',
            documentData: []
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        if (userInfo) {
            axios.get(AppUrl.getTravellerAllDocument(userInfo.id)).then(response => {
                this.setState({ documentData: response.data.all_document })
            })
        }
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <button type="button" id="submit" onClick={() => this.props.addDocument()} name="send" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Add New Documents</button>
                                <div className="mt-[30px] lg:mt-0">
                                    {
                                        this.state.documentData.map((document, key) => {
                                            return <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6" key={key}>
                                                <div className="flex-1 content ml-4">
                                                    <h4 className="text-lg text-medium">{document.type}</h4>
                                                    <p className="text-slate-400 mb-0">{document.document_no}</p>
                                                </div>
                                                <div>
                                                    <a href={AppUrl.documentPath+'/traveller/document/'+document.traveller_id+'/'+document.file_path} target="_blank" className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default Document
