import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import withRouter from '../../../api/withRouter';
import axios from 'axios';

class OrderDetails extends Component {

    constructor() {
        super();
        this.state = {
            enquiryList: [],
            products: [],
            documentData: [],
            orderDetail: [],
            invoice: '',
            travellerDetails: [],
            orderStatus: []
        }
    }

    componentDidMount() {
        const order_id = this.props.order_id;
        const enquiry_id = this.props.enquiry_id;
        axios.get(AppUrl.customerOrderDetail(order_id, enquiry_id)).then(response => {
            this.setState({
                orderDetail: response.data.orderData,
                enquiryList: response.data.enquiryList,
                documentData: response.data.documents,
                products: response.data.products,
                invoice: response.data.invoice,
                travellerDetails: response.data.travellerDetails,
                orderStatus: response.data.orderStatus
            });
        })
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-process"></i></span>Orders</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <h3 className="text-lg font-bold mb-4">Order Detail</h3>
                                    <hr className="mb-6 m-0" />
                                    <div>
                                        <div>
                                            <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                                <table className="w-full text-left">
                                                    <tr className="border-t border-gray-100 dark:border-gray-700">
                                                        <th className="px-4 py-5">Order No.</th>
                                                        <td className="px-4 py-5 text-right">{this.state.orderDetail.order_id}</td>
                                                    </tr>
                                                    <tr className="border-t border-gray-100 dark:border-gray-700">
                                                        <th className="px-4 py-5">Order Date</th>
                                                        <td className="px-4 py-5 text-right">{this.state.orderDetail.created_at}</td>
                                                    </tr>
                                                    <tr className="border-t border-gray-100 dark:border-gray-700">
                                                        <th className="p-4"><i className="uil uil-clipboard-alt mr-2"></i>Enquiry No.</th>
                                                        <td className="p-4 text-right">{this.state.enquiryList.enquiry_id}</td>
                                                    </tr>
                                                    <tr className="border-t border-gray-100 dark:border-gray-700">
                                                        <th className="p-4"><i className="uil uil-clipboard-alt mr-2"></i>Enquiry Date</th>
                                                        <td className="p-4 text-right">{this.state.enquiryList.enquiry_date}</td>
                                                    </tr>
                                                    <tr className="border-t border-gray-100 dark:border-gray-700">
                                                        <th className="p-4"><i className="uil uil-clipboard-alt mr-2"></i>Status</th>
                                                        <td className="p-4 text-right"><span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">{this.state.orderDetail.status_name}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                            <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                                <div>
                                                    <label className="font-bold">Pickup From</label>
                                                    <p><i className="uil uil-map-marker text-lg mr-2"></i>{this.state.enquiryList.pickup_form}</p>
                                                    <p><i className="uil uil-user text-lg mr-2"></i>{this.state.enquiryList.pickup_person}</p>
                                                </div>
                                            </div>
                                            <hr className="mt-3 mb-3" />
                                            <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                                <div>
                                                    <label className="font-bold">Deliver To</label>
                                                    <p><i className="uil uil-map-marker text-lg mr-2"></i>{this.state.enquiryList.drop_address}</p>
                                                    <p><i className="uil uil-user text-lg mr-2"></i>{this.state.enquiryList.drop_person}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                            {
                                                this.state.products.map((product, key) => {
                                                    return <div key={key}>
                                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                            <div>
                                                                <label><span className="font-bold mr-1">Product Type :</span>{product.product_type}</label>
                                                            </div>
                                                            <div>
                                                                <label><span className="font-bold mr-1">Product Namee :</span>{product.product_name}<a href={AppUrl.documentPath + '/customer/enquiry/product/' + product.enquiry_id + '/' + product.enquiry_img} target="_blank" className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full ml-3"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                            </div>
                                                            <div>
                                                                <label><span className="font-bold mr-1">Quantitye :</span>{product.qty}</label>
                                                            </div>
                                                            <div>
                                                                <label><span className="font-bold mr-1">Weighte :</span>{product.weight}</label>
                                                            </div>
                                                            <div>
                                                                <label><span className="font-bold mr-1">Valuee :</span>{product.product_value}</label>
                                                            </div>
                                                            <div>
                                                                <label><span className="font-bold mr-1">Packing Typee :</span>{product.packing_type}</label>
                                                            </div>
                                                        </div>
                                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5 mt-3">
                                                            <div>
                                                                <label><span className="font-bold mr-1">Descriptione :</span>{product.description}</label>
                                                            </div>
                                                        </div>
                                                        <hr className="mt-3 mb-3" />
                                                    </div>
                                                })
                                            }
                                        </div>
                                        {
                                            this.state.documentData.length>0 && (
                                            this.state.documentData.map((document, key) => {
                                                return <div key={key} className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                        <div>
                                                            <label htmlFor="typejob" className="font-bold">Document Name</label>
                                                            <p>{document.document_name} </p>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="typejob" className="font-bold">Image</label>
                                                            <p><a href={AppUrl.documentPath + '/customer/enquiry/document/' + document.enquiry_id + '/' + document.document_img} target="_blank" className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></p>
                                                        </div>
                                                    </div>
                                                    <hr className="mt-3 mb-3" />
                                                </div>
                                            })
                                        )}
                                        {this.state.invoice!=null && (
                                            <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-3">
                                                <div className="flex-1 content ml-4">
                                                    <h4 className="text-lg text-medium font-bold">Purchase Bill</h4>
                                                    <p className="text-slate-400 mb-0">{this.state.invoice ? this.state.invoice.invoice_no : ''}</p>
                                                </div>
                                                <div>
                                                    {
                                                        this.state.invoice ? <a href={AppUrl.documentPath + '/' + 'invoice/' + this.state.invoice.order_id + '/' + this.state.invoice.file_path} target="_blank" className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full ml-3"><FeatherIcon icon="eye" className="h-4 w-4" /></a> : ''
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {
                                            this.state.travellerDetails.length > 0 &&(
                                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                                <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                                    <div>
                                                        <label className="font-bold">Traveller Details</label>
                                                        <hr className="mt-3 mb-3" />
                                                        {
                                                            this.state.travellerDetails.map((detail, key) => {
                                                                return <div key={key}>
                                                                    <p><i className="uil uil-user text-lg mr-2" /> {detail.traveller_name}</p>
                                                                    <p><i className="uil uil-phone text-lg mr-2" /> {detail.mobile}</p>
                                                                    <br />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {
                                            this.state.orderStatus.length > 0 &&
                                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                                {
                                                    this.state.orderStatus.map((status, key) => {
                                                        return <div key={key}>
                                                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                                <div>
                                                                    <label className="font-bold">Date</label>
                                                                    <p>{status.status_date} - {status.time}</p>
                                                                </div>
                                                                <div>
                                                                    <label className="font-bold">Order Status</label>
                                                                    <p>{status.status_name}</p>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-3 mb-3" />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default withRouter(OrderDetails)
