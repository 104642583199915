import React, { Component } from 'react'
import Terms from '../components/website/common/Terms'

class TermsPage extends Component {
  render() {
    return (
      <Terms />
    )
  }
}

export default TermsPage