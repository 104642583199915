import React, { Component, Fragment } from 'react'
import TransactionList from '../../components/traveller/transaction/TransactionList'
class TravellerTransactionPage extends Component {

  scrollToTop() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    this.scrollToTop();
  }

  render() {
    return (
      <TransactionList />
    )
  }
}

export default TravellerTransactionPage
