import React, { Component } from 'react'
import Contact from '../components/website/common/Contact'

class ContactPage extends Component {
  render() {
    return (
      <Contact />
    )
  }
}

export default ContactPage