import React, { Component, Fragment } from 'react'
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import axios from 'axios';
import AppUrl from '../../../api/AppUrl';
import FeatherIcon from 'feather-icons-react';

class Address extends Component {

    constructor() {
        super();
        this.state = {
            addressList: [],
            customer_id: '',
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        axios.get(AppUrl.getTravellerAllAddress(userInfo.id)).then(response => {
            this.setState({ addressList: response.data.all_address });
        });
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <button onClick={() => this.props.addAddress()} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Add New Address</button>
                                {
                                    this.state.addressList.map((add, i) => {

                                        return <div className="mt-[30px] lg:mt-0">
                                            <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                                                <div className="flex-1 content ml-4">
                                                    <h4 className="text-lg text-medium"> <i className="uil uil-map-marker text-lg mr-2" /> {add.address} - <b>{add.address_type}</b></h4>
                                                </div>
                                                <div>
                                                    <button onClick={() => this.props.edit(add.id)} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="edit" className="h-4 w-4" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default Address
