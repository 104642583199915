import React, { Component } from 'react'
import Policy from '../components/website/common/Policy'

class PolicyPage extends Component {
  render() {
    return (
      <Policy />
    )
  }
}

export default PolicyPage