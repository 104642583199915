import Navbar from "./components/website/common/Navbar"
import Footer from "./components/website/common/Footer";
import { Fragment } from 'react';
import AppRoute from "./route/AppRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Fragment>
      <Navbar />
      <ToastContainer></ToastContainer>
      <AppRoute />
      <Footer />
    </Fragment>
  );
}

export default App;
