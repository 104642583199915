class AppUrl {
    static BaseURL = "https://indzip.com/admin/api"
    static documentPath = "https://indzip.com/admin/public"
    
    ///////////////////////// Common API For Customer And Traveller/////////////
    // CHeck Unique Start
    static CheckMobileUnique = this.BaseURL + "/check-mobile-unique";
    static CheckEmailUnique = this.BaseURL + "/check-email-unique";
    // OTP Send
    static otpSend = this.BaseURL + "/otp-send";
    //Get price
    static getPrice = this.BaseURL+"/get-price";    
    static getCountry = this.BaseURL + "/countries";

    static contactus = this.BaseURL+"/contact-us";
    static getStates(country_id) {
        return this.BaseURL + '/states/' + country_id;
    }
    static getCity(state_id) {
        return this.BaseURL + '/cities/' + state_id;
    }
    static getLocalities(city_id) {
        return this.BaseURL + '/localities/' + city_id;
    }
    static documentType = this.BaseURL + "/document-type";
    static getBussinessCategory = this.BaseURL + "/bussiness-categories";
    static getProfession = this.BaseURL + "/get-profession";

    // profile image update
    static customerProfileImageUpdate = this.BaseURL + "/customer-profile-image-update";
    ///////////////////////// Traveller //////////////////////////

    //Traveller Signup
    static travellerSignup = this.BaseURL + "/traveller-signup";
    // Traveller Login
    static travellerLogin = this.BaseURL + "/traveller-login";
    // Traveller Profile Update    
    static travellerProfileUpdate = this.BaseURL + "/traveller-profile-update";
    // profile image update
    static travellerProfileImageUpdate = this.BaseURL + "/traveller-profile-image-update";
    // Traveller Password Update
    static travellerPasswordUpdate = this.BaseURL + "/password-update";
    static travellerLogout = this.BaseURL + "/traveller-logout";
    static getTravellerStatus = this.BaseURL+"/gettraveller-status";
    static travellerStatusUpdate = this.BaseURL+"/traveller-status-update";
    static travellerOrderDocumentUpdate = this.BaseURL+"/traveller-order-document-update";
    
    //Banks Details Start
    static bankDetails(customer_id) {
        return this.BaseURL + '/bank-details/' + customer_id
    }
    static bankSave = this.BaseURL + "/bank-save";

    static bankEdit(id) {
        return this.BaseURL + '/bank-edit/' + id
    }
    //End     
    static travellerransactionList(traveller_id) {
        return this.BaseURL + "/traveller-transaction-list/" + traveller_id ;
    }
    //Orders
    static travellerOrderList(traveller_id) {
        return this.BaseURL + "/traveller-order-list/" + traveller_id;
    }
    static travellerOrderDetail(order_id, enquiry_id) {
        return this.BaseURL + "/traveller-order-details/" + order_id + '/' + enquiry_id;
    }
    static getTravellerAllDocument(traveller_id) {
        return this.BaseURL + "/all-traveller-document/" + traveller_id;
    }
    static travellerDocumentSave = this.BaseURL + "/traveller-document-save";
    static singleTravellerDocument(traveller_id) {
        return this.BaseURL + '/single-traveller-document/' + traveller_id;
    }
    static getTravellerAllAddress(traveller_id) {
        return this.BaseURL + '/traveller-address/' + traveller_id
    }
    static travellerAddressEdit(id) {
        return this.BaseURL + '/traveller-address-edit/' + id;
    }
    static travellerAddressSave = this.BaseURL + "/traveller-address-save";

    ////////////////////Customer //////////////////////////////////////////////
    //Customer Login
    static singin = this.BaseURL + "/customer/login";
    //Customer Signup
    static customerSingup = this.BaseURL + "/customer-signup";
    //Customer Profile
    static profileUpdate = this.BaseURL + "/customer-profile-update";
    //Customer Password Update
    static customerPasswordUpdate = this.BaseURL + "/customer-password-update";
    static customerLogout = this.BaseURL + "/customer-logout";    
    static CustomerTransactionList(customer_id) {
        return this.BaseURL + "/customer-transaction-list/" + customer_id ;
    }    
    //Customer Orders
    static customerOrderList(customer_id) {
        return this.BaseURL + "/customer-order-list/" + customer_id;
    }
    static customerOrderDetail(order_id, enquiry_id) {
        return this.BaseURL + "/customer-order-details/" + order_id + '/' + enquiry_id;
    }
    //invoice list
    static customerInvoiceList(customer_id) {
        return this.BaseURL + "/customer-invoice-list/" + customer_id;
    }
    //Enquery
    static getAllProductType = this.BaseURL + "/all-product-packing-type";
    static enquirySave = this.BaseURL + "/enquiry-save";
    static enquiryList(customer_id) {
        return this.BaseURL + "/enquiry-list/" + customer_id;
    }
    static enquiryDetails(id) {
        return this.BaseURL + "/enquiry-details/" + id;
    }
    static getCustomerAllDocument(customer_id) {
        return this.BaseURL + "/all-customer-document/" + customer_id;
    }
    static customerDocumentSave = this.BaseURL + "/customer-document-save";
    static singleCustomerDocument(customer_id) {
        return this.BaseURL + '/single-customer-document/' + customer_id;
    }
    static getCustomerAllAddress(customer_id) {
        return this.BaseURL + '/customer-all-address/' + customer_id
    }
    static customerAddressSave = this.BaseURL + "/customer-address-save";
    static customerAddressEdit(id) {
        return this.BaseURL + '/customer-address-edit/' + id;
    }
}
export default AppUrl