import React, { Component } from 'react'
import Address from '../../components/traveller/address/Address'
import AddAddress from '../../components/traveller/address/AddAddress';
import withRouter from '../../api/withRouter';
class TravellerAddressPage extends Component {

  constructor() {
    super();
    this.state = {
      pageDisplay: '',
      id: '',
    }
  }


  scrollToTop() {
    window.scrollTo(0, 0);
  }

  addAddress = () => {
    this.setState({ pageDisplay: 'add', id: '' })
  }

  edit = (id) => {
    this.setState({ pageDisplay: 'edit', id: id })
  }

  view = () => {
    this.setState({ pageDisplay: 'view', id: '' })
  }

  componentDidMount() {
    this.setState({ pageDisplay: 'view' })
    this.scrollToTop();
  }

  render() {
    const pageDisplay = this.state.pageDisplay;
    const id = this.state.id;
    if (pageDisplay === 'add' || pageDisplay === 'edit') {
      return <AddAddress id={id} view={this.view} />
    } else if (pageDisplay === 'view') {
      return <Address addAddress={this.addAddress} edit={this.edit} />
    } else {
      return <Address addAddress={this.addAddress} edit={this.edit} />
    }
  }
}

export default withRouter(TravellerAddressPage)
