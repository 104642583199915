import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";

class HowItWorks extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 lg:py-0 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">How it Works</span></h4>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic">Speed is Everything</h1>
                                    <p className="text-xl text-left text-dark font-semibold">INDZIP offers Urgent Hand Carriage and Air Charter services.</p>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/32.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-16 bg-dark-footer text-white">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-edit text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">Customer online enquiry</p>
                            </div>
                            <i className="uil uil-arrow-right text-red-600 text-5xl icon-1-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-file-contract-dollar text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP provides route pricing</p>
                            </div>
                            <i className="uil uil-arrow-right text-red-600 text-5xl icon-2-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-thumbs-up text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">Customer confirms order</p>
                            </div>
                            <i className="uil uil-arrow-down text-red-600 text-5xl icon-3-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-plane-fly text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP traveller checks-in at airport</p>
                            </div>
                            <i className="uil uil-arrow-left text-red-600 text-5xl icon-4-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-archive-alt text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP picks up order</p>
                            </div>
                            <i className="uil uil-arrow-left text-red-600 text-5xl icon-5-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-user text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP assigns traveller to order</p>
                            </div>
                            <i className="uil uil-arrow-down text-red-600 text-5xl icon-6-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-plane-arrival text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP traveller disembarks</p>
                            </div>
                            <i className="uil uil-arrow-right text-red-600 text-5xl icon-7-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-phone-volume text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP notifies recipient</p>
                            </div>
                            <i className="uil uil-arrow-right text-red-600 text-5xl icon-8-sty display-none-mb"></i>
                            <div className="lg:col-span-4 text-center pl-2 pr-2 pt-5 pb-6 border-sty">
                                <i className="uil uil-notes text-gray-300 text-5xl"></i>
                                <p className="mx-auto font-semibold text-xl mt-2 p-2">INDZIP delivers order to recipient</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                            <div className="lg:col-span-5 mt-5 md:mt-0">
                                <div className="lg:ml-10">
                                    <p className="text-dark text-2xl font-semibold max-w-xl">The traditional courier industry works on a Hub-and-Spoke model which makes it extremely difficult to achieve same day inter-state and international deliveries. <br /><br />This is how your urgent goods are likely to move with a courier company with multiple sorting points. So would you rely on the below process when you need something urgently?</p>
                                </div>
                            </div>
                            <div className="lg:col-span-7">
                                <img className="img-round rounded-3xl" src="assets/img/05.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                            <div className="lg:col-span-7">
                                <img className="img-round rounded-3xl" src="assets/img/50.jpg" />
                            </div>
                            <div className="lg:col-span-5 mt-5 md:mt-0">
                                <div className="lg:ml-10">
                                    <p className="text-dark text-2xl font-semibold max-w-xl">INDZIP provides direct hand carriage service using the fastest available transport modes. We also provide aircraft charter services for emergency logistics requirements across the world.
                                        <br /><br /> This is how your urgent goods would move with INDZIP. Always direct, without deviations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container md:mt-4 mt-10 md:mb-12">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="mt-1 text-center">
                                    <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-12 mb-12 gap-[30px]">
                            <div className="lg:col-span-7 mt-5 md:mt-0">
                                <div className="lg:ml-10">
                                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold"><span className="text-red-600">INDZIP Benefits</span></h3>
                                    <h6 className="mb-6 text-2xl text2xl md:leading-normal leading-normal font-semibold">Performance matched with speed.</h6>
                                    <ul className="list-none text-dark mt-4">
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Dedicated hand carrier service</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Faster than Courier</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Digital platform for booking and tracking</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Dedicated pre-verified travellers</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Delivery within 8 hours in India</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Delivery within 24 hours worldwide</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Dedicated air charter</li>
                                        <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>24/7 back-office support with tracking updates</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="lg:col-span-5">
                                <img className="img-round rounded-3xl" src="assets/img/33.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default HowItWorks