import React, { Component } from 'react'
import Login  from '../../src/components/traveller/signup/Login'
class TravellerLoginPage extends Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    )
  }
}

export default TravellerLoginPage
