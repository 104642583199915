import React, { Component } from 'react'
import AppUrl from '../../../api/AppUrl';
import { toast } from 'react-toastify';
import axios from 'axios'

class Login extends Component {


    constructor() {
        super();
        this.state = {
            login_id: '',
            password: '',
            login_id_error: '',
            password_error: '',
            hide_submit: 'block',
            display_processing: 'none'
        }
    }

    userInputHandel = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })
    }

    vaild = () => {
        let login_id_error = '';
        let password_error = '';
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!this.state.login_id) {
            login_id_error = "Please enter valid login id"
        } else if (!this.state.password) {
            password_error = "Please enter valid password"
        }
        if (login_id_error || password_error) {
            this.setState({
                login_id_error, password_error
            });
            return false;
        }
        return true;
    }

    login = (e) => {
        e.preventDefault();
        const isValid = this.vaild();
        if (isValid) {
            const dataFromState = this.state;
            const postingData = {
                'login_id': dataFromState.login_id,
                'password': dataFromState.password
            }
            axios.post(AppUrl.travellerLogin, postingData).then((response) => {
                const status = response.data.status;
                if (status == 'success') {

                        localStorage.removeItem('token'); // Remove 'token'
                        localStorage.removeItem('user'); // Remove 'user'
                        localStorage.removeItem('user_type'); // Remove 'user_type'
                        localStorage.removeItem('profile_image'); // Remove 'user_type'

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    localStorage.setItem("user_type", JSON.stringify(response.data.user_type));
                    localStorage.setItem("profile_image", JSON.stringify(response.data.user.profile_image));
                    // Set an expiration time (e.g., 20 minutes)
                    // const expirationTime = 20 * 60 * 1000; // 20 minutes in milliseconds

                    // // Schedule removal after the expiration time for each item
                    // setTimeout(() => {
                    //     localStorage.removeItem('token'); // Remove 'token'
                    //     localStorage.removeItem('user'); // Remove 'user'
                    //     localStorage.removeItem('user_type'); // Remove 'user_type'
                    // }, expirationTime);
                    window.location.href = decodeURIComponent('/traveller-profile');
                } else {
                    this.setState({ display_processing: 'none', hide_submit: 'block' })
                    toast.warning(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                }
            }).catch(error => {
            });
        }
    }

    render() {
        return (
            <div>
                <section class="relative table w-full py-16 text-center lg:py-36 bg-gray-100">
                    <div class="container">
                        <div class="md:grid-cols-12 grid-cols-1 items-center mt-0 pb-5">
                            <h4 class="font-extrabold text-7xl lg:text-7xl mb-5 text-center italic"><span class="text-red-600">Traveller Login</span></h4>
                        </div>
                    </div>
                </section>
                <br />

                {/* Start */}
                <section className=" md:py-24 bg-gray-50 dark:bg-slate-800" style={{ marginTop: '-17rem' }}>
                    <div className="container">
                        <div className="flex justify-center">
                            <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                                {/* <a href="index.html"><img src="assets/images/logo-icon-64.png" className="mx-auto" alt /></a> */}
                                {/* <h5 className="my-6 text-xl font-semibold">Traveller Login</h5> */}
                                <form className="text-left">
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="login_id">Login Id</label>
                                            <input id="login_id" type="text" className="form-input mt-2" placeholder="Login Id" name="login_id" value={this.state.login_id} onChange={this.userInputHandel} />
                                            <span className='required'>{this.state.login_id_error}</span>
                                        </div>
                                        <div className="mb-4">
                                            <label className="font-semibold" htmlFor="password">Password</label>
                                            <input id="password" type="password" className="form-input mt-2" placeholder="Password:" name="password" value={this.state.password} onChange={this.userInputHandel} />
                                            <span className='required'>{this.state.password_error}</span>
                                        </div>
                                        <div className="flex justify-between mb-4">
                                            <div className="form-checkbox flex items-center mb-0">
                                                <input className="mr-2 border border-inherit w-[14px] h-[14px]" type="checkbox" defaultValue id="RememberMe" />
                                                <label className="text-slate-400" htmlFor="RememberMe">Remember me</label>
                                            </div>
                                            <a href="customer-register-change-password.html" className="text-indigo-600"><i className="mdi mdi-lock-outline mr-2"></i>Forgot password ?</a>
                                        </div>
                                        <div className="mb-4">
                                            {/* <input type="submit" onClick={(e) => this.login(e)} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" style={{ display: `${this.state.hide_submit}` }} value="Login / Sign in" /> */}
                                            <button type="button" onClick={(e) => this.login(e)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full"><i className="mdi mdi-login-variant mr-2"></i>Login / Sign In</button>
                                            <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                        </div>
                                        <div className="sin mt-6">
                                            <a href="/traveller-sign-up"><i className="mdi mdi-format-line-style mr-2"></i>Sign Up as<b class="text-indigo-600 ml-2">Traveller</b></a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End */}
            </div>
        )
    }
}

export default Login
