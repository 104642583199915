import React, { Component } from 'react'
import OrderList from '../../components/customer/order/OrderList'
import OrderDetails from '../../components/customer/order/OrderDetails';

class CustomerOrderPage extends Component {
    constructor(){
        super();
        this.state = {
            displayPage : '',
            order_id : '',
            enquiry_id : ''
        }
    }
    
    scrollToTop() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        this.scrollToTop();
    }
    
    orderDetails = (order_id,enquiry_id) => {
        this.setState({ displayPage : 'detail' , order_id : order_id , enquiry_id : enquiry_id })
    }

  render() {
    const displayPage = this.state.displayPage;
    if(displayPage === 'detail'){
        return <OrderDetails order_id = {this.state.order_id}  enquiry_id = {this.state.enquiry_id} />
    }else{
        return <OrderList orderDetails={this.orderDetails}/>
    }
  }
}

export default CustomerOrderPage
