import React, { Component } from 'react'
import Enquiry from '../components/customer/enquiries/Enquiry'
import EnquiryAdd from '../components/customer/enquiries/EnquiryAdd';
import Details from '../components/customer/enquiries/Details';
import withRouter from '../api/withRouter'

class EnquiryPage extends Component {

    constructor() {
        super();
        this.state = {
            id:'',
            displayPage: ''
        }
    }

    addEnquiry = () => {
        this.setState({ displayPage : 'Add' });
    }

    view = () => {
        this.setState({ displayPage : 'view' });
    }

    edit = () => {
        this.setState({ displayPage : 'Add' });
    }  
    
    enquiryDetails = (id) => {
        this.setState({ displayPage : 'detail' , id : id });
    }

    render() {
        const displayPage = this.state.displayPage;
        if (displayPage === 'Add') {
            return <EnquiryAdd view = {this.view}/>
        } else if (displayPage === 'View') {
            return <Enquiry  addEnquiry = {this.addEnquiry} enquiryDetails = {this.enquiryDetails}/>
        } else if(displayPage === 'detail'){
            return <Details  id = {this.state.id}/>
        }else {
            return <Enquiry  addEnquiry = {this.addEnquiry} enquiryDetails = {this.enquiryDetails} />
        }
    }
}

export default withRouter(EnquiryPage)