import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl';
import axios from 'axios';

class Details extends Component {

    constructor() {
        super();
        this.state = {
            enquiryList: [],
            productData: [],
            documentData: [],
        }
    }

    componentDidMount() {
        axios.get(AppUrl.enquiryDetails(this.props.id)).then((response) => {
            const status = response.data.status;
            if (status === 'success') {
                this.setState({ enquiryList: response.data.enquiryList, productData: response.data.products, documentData: response.data.documents })
            }
        })
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-receipt"></i></span>Enquiry</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <h3 className="text-lg font-bold mb-4">Enquiry Detail</h3>
                                    <hr className="mb-6 m-0" />
                                    <div>
                                        <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                            <table className="w-full text-left">
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <th className="px-4 py-5">Enquiry No.</th>
                                                    <td className="px-4 py-5 text-right">{this.state.enquiryList.enquiry_id}</td>
                                                </tr>
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <th className="p-4"><i className="uil uil-clipboard-alt mr-2"></i>Date</th>
                                                    <td className="p-4 text-right"><span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">{this.state.enquiryList.enquiry_date}</span></td>
                                                </tr>
                                                <tr className="border-t border-gray-100 dark:border-gray-700">
                                                    <th className="p-4"><i className="uil uil-clipboard-alt mr-2"></i>Status</th>
                                                    <td className="p-4 text-right">{this.state.enquiryList.enquiry_status}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="typejob" className="font-bold">Pickup From</label>
                                                <p><i className="uil uil-map-marker text-lg mr-2"></i>{this.state.enquiryList.pickup_form}</p>
                                                <p><i className="uil uil-user text-lg mr-2"></i>{this.state.enquiryList.pickup_person}</p>
                                            </div>
                                        </div>
                                        <hr className="mt-3 mb-3" />
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="typejob" className="font-bold">Deliver To</label>
                                                <p><i className="uil uil-map-marker text-lg mr-2"></i>{this.state.enquiryList.drop_address}</p>
                                                <p><i className="uil uil-user text-lg mr-2"></i>{this.state.enquiryList.drop_person}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        {
                                            this.state.productData.map((product, key) => {
                                                return <div key={key}>
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                        <div>
                                                            <label><span className="font-bold mr-1">Product Type :</span>{product.product_type}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Product Name :</span>{product.product_name}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Quantity :</span>{product.qty}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Weight :</span>{product.weight}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Value :</span>{product.product_value}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Packing Type :</span>{product.packing_type}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Description :</span>{product.description}</label>
                                                        </div>
                                                        <div>
                                                            <label><span className="font-bold mr-1">Image :</span><a href={AppUrl.documentPath + '/customer/enquiry/product/' + product.enquiry_id + '/' + product.enquiry_img} target="_blank" className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                        </div>
                                                    </div>
                                                    <hr className="mt-3 mb-3" />
                                                </div>
                                            })
                                        }
                                    </div>
                                    {
                                        this.state.documentData.map((document, key) => {
                                            return <div key={key} className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-bold">Document Name</label>
                                                        <p>{document.document_name} </p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-bold">Image</label>
                                                        <p><a href={AppUrl.documentPath + '/customer/enquiry/document/' + document.enquiry_id + '/' + document.document_img} target="_blank" className="btn btn-icon bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></p>
                                                    </div>
                                                </div>
                                                <hr className="mt-3 mb-3" />
                                            </div>
                                        })
                                    }
                                    {/*
                                    <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                                        <div className="flex-1 content ml-4">
                                            <h4 className="text-lg text-medium">Bill</h4>
                                            <p className="text-slate-400 mb-0">ABCD1234</p>
                                        </div>
                                        <div>
                                            <a href="#" className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a>
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default Details
