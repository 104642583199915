import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";

class Terms extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }


    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <section class="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div class="container">
                        <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div class="md:col-span-6">
                                <div class="md:mr-6">
                                    <h4 class="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span class="text-red-600">Terms Of Services</span></h4>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <img class="img-round rounded-3xl banner-img-sty" src="assets/img/18.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-14 mt-2">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-12 mt-5 md:mt-0">
                                <div className="">
                                    <h6 class="mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">I.	Service</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	INDZIP ("Contractor") offers its customers rapid Shipment services.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall organize transport of goods pursuant to the provisions of Section V of these TCC and deliver these goods to the defined recipient.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Unless a customer directs specific routing and agrees to pay any additional costs associated with the specific routing, Contractor may choose routing at its discretion.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to select the freight carrier.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Upon order placement, the Contractor shall undertake to pick up and deliver the Consignment as defined in the Waybill, as well as render other possible services, at the expense of the customer.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Any Shipment is accepted as is by the Contractor upon handover; content and condition of content of packages are unknown to the Contractor. The Contractor shall   have no obligation to inspect or verify the condition or completeness of the Shipment at the transportation transfer points where a handover takes place.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall have the option to select the mode of transportation on segments of the route.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor establishes the shipping schedule for same day delivery at the destination, as practicable, depending on the timely handover of the Consignment to the Contractor and/or the timely acceptance of the Consignment by the Consignee and the route. The schedule shall be coordinated with the customer upon order placement.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">II.	Scope of TCC</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	These TCC shall govern all of the Contractor's activities, services, products and shipments, irrespective of whether the services are rendered directly or via a third party.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Any deviations to these TCC or contradictory customer terms shalI be binding upon the Contractor only if accepted by Contractor in writing.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall make TCC available upon request in print form, and electronically under www.indzip.com The electronic version applicable at the time of conclusion of the contract shall take precedence over diverging provisions. The TCC also apply in case of conclusion of a framework agreement as a continuing obligation. In the event of conflict between the provisions of the TCC and the framework agreement, the latter shall take precedence.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">Ill.	Definitions</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Consignment" and "Shipment" mean goods, in particular documents or merchandise, listed on a Waybill, regardless of the mode of transportation involved.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Consignee" is the final recipient of the Shipment as outlined in the Air Waybill</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Consignor" is the originator of a Shipment as outlined in the Air Waybill</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Dangerous Goods" means materials/goods classified as goods dangerous for carriage pursuant to the UN, IATA DGR, ICAO regulations or other national or international regulations for Dangerous Goods, or goods which due to their nature, their properties or their condition, when transported, pose a risk to the public safety or the public order, in particular, to the general public, important community facilities, the life and health of persons, animals and objects.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Days" means full calendar days, including Sundays and public holidays.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	"Air Waybill" or "Waybill" means any handwritten or machine generated order or freight document, or Shipment label prepared by the Contractor or authorized individuals.</li>
                                    </ul>

                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">IV.	Governing law</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	These TCC shall be governed by and construed in accordance with the laws of India for all transportation done within India.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	International air carriage is subject to the rules relating to liability established by the Convention for the Unification of Certain Rules Relating to International Carriage by Air; signed at Warsaw on 12 October 1929 (Warsaw Convention) and the Convention for the Unification of Certain Rules for International Carriage by Air signed at Montreal on 28 May 1999 (Montreal Convent1on), as modified and supplemented.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	International transportation by road is subject to the Convention on the Contract for the International Carriage of Goods by Road of Geneva, 19 May 1956 ("CMR").</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The above-referenced regulations shall be supplemented by the current IATA Dangerous Goods Regulations, the ICAO Technical Instructions, and/or national regulations of the Shipment's country of departure, transit, or destination, as applicable.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">V.	Goods  accepted   for   Shipment</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall only accept and take over for transport the following goods for Shipment as being in accordance with the contract, unless otherwise stipulated:</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Hand Carriage: Shipments weighing a maximum of 20 kg. The Contractor shall apply a reasonable supplementary charge for larger/heavier Shipments based on the contractual tariff.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Other Shipments: Shipment weight, measures and means of transport, as stipulated by the Contractor and Customer.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Aircraft Charter: Shipment weight, measures and type of aircraft, subject to aircraft charter agreement to be signed between the Contractor and Customer prior to execution.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	All packages containing Hazardous Materials/Dangerous Goods shall be limited to the materials and quantities authorized for air transportation under the International Air Transport Association (IATA) Dangerous Goods Regulations ("Regulations"). Customer shall comply with the Regulations regardless of the routing or the mode by which the Shipment is transported. Each Shipment requiring a customer's Declaration for Dangerous Goods under the Regulations shall be accompanied by properly executed documents in accordance with the requirements of the Regulations. If a Shipment contains Hazardous Materials/Dangerous Goods, the contents shall be - and customer hereby certifies they are - fully and accurately described on the Waybill or other shipping document by proper shipping name and are classified, packaged, marked and labeled, and in proper condition for carriage by air (or, if tendered for other mode of transportation, then for carriage by such other mode) according to the Regulations and any other applicable national governmental regulations.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer is responsible for ensuring that Regulations and all other applicable air transport regulation requirements are met.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer shall provide Contractor with advance written notice of the proposed Shipment of any Hazardous Material, together with a copy of the Material Safety Data Sheet for that Hazardous Material. Customer agrees to indemnify, hold harmless, and defend Contractor for all claims arising from the Shipment of Hazardous Material.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The value of goods for each Consignment must not exceed US$ 2000, unless otherwise stipulated. The customer shall inform the Contractor in advance about exceeding values of goods.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to not accept any particular items in a Consignment, which are banned under the IATA and ICAO regulations, or for any other legal or safety reasons. Consignments cooled with dry ice, diagnostic specimens or biological substances are only accepted upon separate confirmation of a suitable service offered by the Contractor.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor is entitled to dispose of certain items from transport at any time if their transportation violates the law. The customer shall be liable and obliged to compensate the Contractor for any additional cost or expense incurred as a result of the exercise of the right of disposal. The transportation import and export shall especially not violate any law or provision of a country, from which, to which or through which the transportation takes place. The necessary approvals by public authorities for the entry, exit or transit must be issued before the beginning of transportation and must be submitted to the Contractor. The same applies for official notifications by authorities.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer bears the sole responsibility for compliance with all applicable law, rules and regulations, governmental requirements or notices.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer ensures that the goods are packaged in a suitable manner and accompanied by the required shipping documents. The goods must not endanger the transportation vehicle, the safety of the transport, persons or objects or cause annoyance to passengers.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer authorizes and consents to all cargo tendered for transportation by air to be screened as required by airport security regulations and in accordance with Contractor's own cargo security, including any necessary breakdown of a Shipment.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Customer shall disclose to Contractor if it is acting as agent, representative, broker, carrier, or other freight intermediary for any other person or entity, and shall assist Contractor to comply with security requirements by enabling Contractor to obtain all necessary documents from such other person or entity, or otherwise qualify, such person or entity.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to stop or reject the Consignment at any time for any reason.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">VI.	Charges  /  Invoicing</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Charges for the Shipment shall be based on the Contractor's schedule of fees in effect upon order placement, even without any explicit reference thereto. Payment is to be made prior to pick-up of order from origin point,  unless otherwise agreed.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to invoice and the customer agrees to pay all unpaid charges, costs and expenses of the Contractor, including but not limited to import taxes and duties, regardless of whether foreseen or not.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to charge a reasonable supplementary fee incident to delays that arise on the part of the customer, based on contractual transportation fees. The Contractor shall also invoice the customer for extra expenses incident to events beyond the control of the Contractor, including but not limited to delays caused by the weather, acts of war, strikes and all sovereign measures such as customs, security checks, airport closures, etc.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall have a general lien on any and all property and documents relating thereto of the customer in its possession, custody or control or en route, for all claims and charges, expenses or advances incurred by the Contractor in connection with any Shipment if such due amount remains unsatisfied for 30 days. The Contractor may sell the Shipment upon 10 days written notice and apply the net proceeds of such sale to the payment of the amount due to the Contractor. Any surplus from such sale shall be paid out to customer, and customer shall be liable for any deficiency in the sale.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">VII.	Pickup / Delivery / Not Delivered</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Shipment shall be picked up and delivered to the street address stated in the Waybill, not to any post boxes or encoded addresses. Deliveries shall also be made to a doorkeeper, receptionist, or incoming postal clearance room in a building.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall waive the right to written proof of delivery, unless otherwise stipulated explicitly.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall promptly inform the Contractor on how to proceed in case the Shipment is not deliverable. If no such guidance is available, the Contractor shall have the right to reasonably decide on how to proceed with the delivery, taking into account the legitimate interests of the customer.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall bear the costs of return Shipment including any applicable customs fines if the Consignment is not deliverable or the carrier refuses acceptance or in any other case where the Contractor cannot provide transport service due to reasons beyond their control.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to refuse to accept a Shipment, or to hold, postpone, or return it, if in the Contractor's reasonable judgment, the Consignment may damage other shipments, property, or persons, or violates the law.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall organize shipment with the next available means of transport at a reasonable cost, in the event the originally scheduled flight is not available for any reason whatsoever. The customer shall bear the additional costs connected herewith.</li>

                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">VIII.	Consignments in Transit</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor may stop, change, reschedule or postpose any transport, if due to any event beyond their control, the Contractor is not capable to perform the promised service. These events include, but are not limited to; weather conditions, acts of God, force majeure, strikes, riots, political disturbances, embargoes, wars, hostilities, civil commotions, unstable international conditions, global pandemics, terrorism or governmental warnings against terrorism or war. In this respect it is irrelevant whether the events have in fact occurred or is only threatened or announced or whether this directly or indirectly results in a delay, claim, requirement, incident or predicament.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	If the Contractor deems it necessary to hold any Consignment or part(s) of it in place during or after transportation for preventing damage or danger, the Contractor may store the Consignment or part(s) of it at the expense, risk and cost of the customer at a storehouse or any other available place or with the customs authorities.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right, but is not obliged to inspect the Shipment, if deemed necessary to protect its interests for reasons that include but are not limited to:
                                            * Address verification
                                            * Customs procedures
                                            * Security procedures
                                            * Securing of damaged contents
                                            * Precluding a potential risk from Shipment of Dangerous Goods
                                            * Suspicion that the contents may contravene these TCC according to Section V.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	In the course of the inspection process, x-ray screening of Shipments is possible. In this case, even when the inspection is made properly, damages of radiosensitive goods may occur. The Contractor is not liable for such damages.</li>
                                    </ul>

                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">IX.	International Shipments / Customs</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall observe all national and international laws relevant for the Consignment, including but not limited to requirements for packaging, documentation, and transportation. Furthermore, the customer shall comply with all applicable regulations on transportation of Dangerous Goods. The customer shall be invoiced and agrees to pay any customs fines, warehouse charges and other charges imposed by custom officials or expenses incurred by the Contractor, together with possible custom duties and taxes, if those are due as a result of the costumer or Consignor not submitting complete export documentation, licenses or permits.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall submit all necessary information and documentation if it requires the Contractor to handle customs clearance. In case of non-payment of import taxes or other customs duties by the Consignee, the costumer shall bear these costs to the full amount.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	If the customer does not require the Contractor to clear customs, the customer shall arrange for payment of any applicable export and import duties and customs clearance fees before delivering the goods to the Contractor.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor is entitled to hire its own customs clearance broker(s).</li>
                                    </ul>

                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">X.	Dangerous goods / Packaging / Labeling</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor shall specify the suitable transportation service for Shipment of Dangerous Goods, each Shipment of which is to be accompanied by a separate Waybill. The Contractor is entitled to refuse the transport of Dangerous Goods without stating any reasons.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	In the event local regulations, airports, airlines, or modes of transportation restrict the movement of Dangerous Goods or impose embargoes at certain departure, transit or destination points, these locations cannot be selected for transportation.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall ensure that Dangerous Goods are packed safely and appropriately, and the Contractor shall not be liable for any damage resulting from improper packaging. All Dangerous Goods shall comply with the IATA Dangerous Goods Regulations and relevant ICAO Technical Instructions.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Diagnostic samples such as blood, urine, etc. must be packed and labeled pursuant to IATA DGR Packing Instructions 602. The package must accordingly contain leak-proof primary and secondary containers each with approved firm packaging materials.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Each Consignment shall be marked legibly and durably with the name, street address, city, country, and zip code of the customer and Consignee. The outside of the container shall bear the proper shipping name(s), the general type and nature of goods and the technical names, as well as UN I D#s  of  the contents. In addition, a contact person that can provide details of the contents during the period of transportation shall be clearly indicated on the package, including name and telephone number. A customer's document, which clearly identifies and describes the contents, shall be placed inside the packaging of each Dangerous Goods Shipment.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall ensure that dry ice Consignments contain an adequate quantity of dry ice to keep the contents cool for a period of at least 48 hours. The Contractor shall not be required to refill the container with dry ice during transportation.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor reserves the right to refuse the transport of a Dangerous Goods Consignment that leaks, releases odors, has damaged packaging, or is otherwise damaged. The customer shall bear all costs for countermeasures, such as return to the customer, destruction of the Consignment, measures taken to prevent accidents, etc.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Acceptance of a Consignment of Dangerous Goods by the Contractor shall not automatically imply acceptance by the intended air carrier.</li>

                                    </ul>

                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XI.	Obligations of Customer</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600"> Notwithstanding other provisions herein, the customer shall ensure:</h6>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That the selected mode of transportation is appropriate for the Shipment;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Compliance with the "Ready for Carriage" rules for airfreight;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That the packaging is safe for the product and mode of transportation</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That the Shipment is adequately marked and labeled;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That all accompanying documents, such as customs papers, are present and contain correct and complete details, in particular on the pickup and destination addresses;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That the Contractor is notified of any safety concerns;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Availability of all the information necessary for Consignee to accept the Shipment;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That import and export customs clearances are handled, unless otherwise stipulated;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That the Contractor is notified promptly of any potential transportation hindrances, which become known to the customer;</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	That proper approvals are obtained, as defined by national or international foreign trade regulations (e.g. dual use regulations), and</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Compliance with all other applicable law, governmental requirements, guidelines and regulations on national or international foreign trade</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Contractor shall not be responsible for action taken, liquidated damages, fines or penalties assessed by any governmental agency against the Shipment because of the failure of customer to comply with the laws, requirements or regulations of any country or governmental agency or with a notification issued by any such agency.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer shall be liable for any damages, costs or consequences arising from disregard of the above mentioned obligations.</li>
                                    </ul>


                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XII.	Delays</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor is only liable for delays, if a guaranteed time of transport is agreed upon by both parties in writing.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XIII.	Liability of Contractor</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer agrees that the Contractor shall only be liable for any directly caused loss, damage or expense of the customer attributable to the gross negligence or willful misconduct of the Contractor.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Subject to any applicable law, statute or regulation, such liability shall be limited as follows: The Contractor's liability shall be limited to the maximum stipulated for circumstances governed by the Warsaw Convention, the Montreal Convention or the CMR rules respectively.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Notwithstanding the above, the Contractor shall not be responsible for any consequential damages resulting from loss, damage, or delay of Shipment.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The Contractor is authorized to select and engage subcontractors, as required, to transport, store, and deliver the goods. The Contractor shall under no circumstances be liable for any loss, damage, expense or delay to the goods for any reason whatsoever when the goods are in custody, possession of control of third parties carefully selected by the Contractor to forward, enter and clear, transport or render other services with respect to such goods.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Liability for claims not arising from loss or damage of a Consignment in custody of the Contractor or by delay shall be limited to typical and foreseeable damages, subject to a maximum of US$ 2,000 for the goods,</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	Neither party will be liable to the other for failing to perform or discharge any obligation under this agreement where caused by acts of God, labor disorders, fire, closing of the public highways, governmental interference, war, riot, act of terrorism, a global pandemic and other disasters beyond the affected party's control. In such case, both parties will make every commercially reasonable effort to remedy or cure such event as soon as practically possible.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The customer is responsible to notify in writing the Contractor in case of any damage to the transported goods at the time of delivery. Receipt by the Consignee without complaints is a prima facie evidence that the goods have been delivered in good condition and in accordance with these TCC. Subject to any applicable laws, statute or regulation, the Contractor shall not be liable for any claims for damage or loss discovered by the customer after delivery, unless a claim is reported in writing within 3 days after delivery of the goods.</li>

                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XIV.	 Indemnification</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	In the event that a third party makes a claim or institutes legal action against Contractor for duties, fines, penalties, liquidated damages or other money due arising from a Shipment of goods, customer agrees to indemnify and hold harmless Contractor for any amount Contractor may be required to pay any other carrier, other person or governmental agency together with reasonable expenses, including attorney fees, incurred by Contractor in connection with defending such claim or legal action and obtaining reimbursement.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The confiscation or detention of the goods by any governmental authority shall not affect or diminish the liability of customer to Contractor to pay all charges or other money due promptly on demand.</li>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	When employees and/or subcontractors of the Contractor are held liable on account of the carriage of the goods, these persons may invoke each liability limitation and/or exoneration which the Contractor can invoke pursuant to these conditions or any other legal or contractual provision.</li>
                                    </ul>

                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XV.	Place of jurisdiction</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	The TCC shall be governed by and construed in accordance with, the laws of India and subject to the jurisdiction of the Courts in Mumbai.</li>
                                    </ul>
                                    <h6 class="mt-4 mb-1 text-2xl text2xl md:leading-normal leading-normal font-semibold text-red-600">XVI.	Severability</h6>
                                    <ul className='list-none text-dark mt-1 ml-5'>
                                        <li className='mb-1 flex'><i class="uil uil-check-circle text-black-600 text-xl mr-2"></i>	If any part of these TCC is declared or becomes void, or unenforceable, or if a loophole exists, the remaining provisions shall continue in full force and effect, whereupon the offending part shall be replaced, or the loophole closed, with an enforceable provision that best reflects the original intent.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-6">
                    <div className="container md:mt-4 mt-10 md:mb-12">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                            <div className="md:col-span-8">
                                <div className="md:mr-6">
                                    <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                </div>
                            </div>
                            <div className="md:col-span-4">
                                <div className="mt-1 text-center">
                                    <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Terms