import React, { Component, Fragment } from 'react'
import UserHeader from '../../website/common/UserHeader'
import UserSidebar from '../../website/common/UserSidebar'
import AppUrl from '../../../api/AppUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

class Profile extends Component {

    userInfo = {};

    constructor() {
        super();
        this.state = {
            id: '',
            details_id: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            gender: '',
            dob: '',
            age: '',
            email: '',
            mobile: '',
            customer_type: '',
            //Details
            secondary_email: '',
            secondary_mobile: '',
            occupation_id: '',
            business_categeory_id: '',
            company_name: '',
            pan: '',
            pan_path: '',
            gst: '',
            gst_path: '',
            adhaar_number: '',
            adhaar_path: '',
            company_registration_no: '',
            company_registration_path: '',
            permanent_address: '',
            permanent_state: '',
            permanent_city: '',
            permanent_locality: '',
            permanent_pincode: '',
            current_address: '',
            current_state: '',
            current_city: '',
            current_locality: '',
            current_pincode: '',
            login_id: '',
            password: '',
            //Error Variable 
            first_name_error: '',
            middle_name_error: '',
            gender_error: '',
            dob_error: '',
            occupation_error: '',
            email_error: '',
            mobile_error: '',
            secondary_mobile_error: '',
            secondary_email_error: '',
            login_id_error: '',
            password_error: '',
            adhaar_number_error: '',
            adhar_path_error: '',
            permanent_address_error: '',
            company_error: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            old_password_error: '',
            new_password_error: '',
            confirm_password_error: '',
            business_category_error: '',
            company_registration_path_error: '',
            company_registration_no_error: '',
            //dropdown bind
            bussiness_categories: [],
            states: [],
            cities: [],
            localities: [],
            current_states: [],
            current_cities: [],
            current_localities: [],
            //Display Or Not
            customer_type_display: 'block',
            profession_display: 'none',
            password_processing: 'none',
            password_update_btn: '',
            display_processing: 'none',
            display_ind_column: 'none',
            display_com_column: 'none',
            pan_path_display: 'none',
            adhaar_path_display: 'none',
            company_registration_path_display: 'none',
            gst_path_display: 'none',
            hide_submit: '',
        }
    }

    componentDidMount() {
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        if (this.userInfo) {
            this.setState({
                id: this.userInfo.id,
                first_name: this.userInfo.first_name,
                middle_name: this.userInfo.middle_name,
                last_name: this.userInfo.last_name,
                gender: this.userInfo.gender,
                dob: this.userInfo.dob,
                age: this.userInfo.age,
                occupation_id: this.userInfo.occupation_id,
                email: this.userInfo.email,
                secondary_email: this.userInfo.secondary_email,
                mobile: this.userInfo.mobile,
                secondary_mobile: this.userInfo.secondary_mobile,
                password: this.userInfo.password,
                user_type: this.userInfo.user_type,
                customer_type: this.userInfo.customer_type,
                profession_id: this.userInfo.profession_id,
                company_name: this.userInfo.company_name,
                pan: this.userInfo.pan,
                pan_path: this.userInfo.pan_path,
                gst: this.userInfo.gst,
                gst_path: this.userInfo.gst_path,
                adhaar_number: this.userInfo.adhaar_number,
                adhaar_path: this.userInfo.adhaar_path,
                company_registration_no: this.userInfo.company_registration_no,
                company_registration_path: this.userInfo.company_registration_path,
                permanent_address: this.userInfo.permanent_address,
                permanent_state: this.userInfo.permanent_state,
                permanent_city: this.userInfo.permanent_city,
                permanent_locality: this.userInfo.permanent_locality,
                permanent_pincode: this.userInfo.permanent_pincode,
                current_address: this.userInfo.current_address,
                current_state: this.userInfo.current_state,
                current_city: this.userInfo.current_city,
                current_locality: this.userInfo.current_locality,
                current_pincode: this.userInfo.current_pincode,
                gst: this.userInfo.gst,
                business_categeory_id: this.userInfo.business_categeory_id
            })

            if (this.userInfo.customer_type == 2) {
                this.setState({ display_com_column: 'block' })
                this.getBussinessCategory();
            }
            if (this.userInfo.customer_type == 1) {
                this.setState({ display_ind_column: 'block' })
            }

            if (this.userInfo.pan_path) {
                this.setState({ pan_path_display: 'block' })
            }
            if (this.userInfo.gst_path) {
                this.setState({ gst_path_display: 'block' })
            }
            if (this.userInfo.company_registration_path) {
                this.setState({ company_registration_path_display: 'block' })
            }
            if (this.userInfo.adhaar_path) {
                this.setState({ adhaar_path_display: 'block' })
            }
            // this.getPermanentCity(this.userInfo.permanent_state);
            // this.getCurrentCity(this.userInfo.current_state);

            // this.getPermanentLocality(this.userInfo.permanent_city);
            // this.getCurrentLocality(this.userInfo.current_city);
        }
        axios.get(AppUrl.getStates(1)).then(response => {
            this.setState({
                states: response.data.states,
                current_states: response.data.states
            });
        });

    }

    //If customer type Corporate
    getBussinessCategory = () => {
        axios.get(AppUrl.getBussinessCategory).then(response => {
            console.log(response);
            this.setState({
                bussiness_categories: response.data.getCategories
            });
        });
    }
    // On Load City Locality Display Start

    // onChangePermentState
    changePermanentState = (e) => {
        this.setState({
            permanent_state: e.target.value, cities: [], localities: []
        });
        axios.get(AppUrl.getCity(e.target.value)).then(response => {
            console.log(response.data);
            this.setState({
                cities: response.data.cities
            });
        });
    }

    // onChangeCurrentState
    changeCurrentState = (e) => {
        this.setState({
            current_state: e.target.value, current_cities: [], current_localities: []
        });
        axios.get(AppUrl.getCity(e.target.value)).then(response => {
            console.log(response.data);
            this.setState({
                current_cities: response.data.cities
            });
        });
    }

    changePermanentCity = (e) => {
        this.setState({
            permanent_city: e.target.value, localities: []
        });
        this.getPermanentLocality(e.target.value);
    }

    // onChangeCurrentCity
    changeCurrentCity = (e) => {
        this.setState({
            current_city: e.target.value, current_localities: []
        });
        this.getCurrentLocality(e.target.value);
    }
    //city
    getPermanentCity = (state_id) => {
        axios.get(AppUrl.getCity(state_id)).then(response => {
            this.setState({ cities: response.data.cities });
        });
    }
    getCurrentCity = (state_id) => {
        axios.get(AppUrl.getCity(state_id)).then(response => {
            this.setState({ current_cities: response.data.cities });
        });
    }
    //Locality
    getPermanentLocality = (city_id) => {
        this.setState({
            permanent_city: city_id, localities: []
        });
        axios.get(AppUrl.getLocalities(city_id)).then(response => {
            this.setState({
                localities: response.data.localities
            })
        })
    }

    getCurrentLocality = (city_id) => {
        this.setState({
            current_city: city_id, current_localities: []
        });
        axios.get(AppUrl.getLocalities(city_id)).then(response => {
            this.setState({
                current_localities: response.data.localities
            })
        })
    }


    // On Load City Locality Display End

    handelUserInput = (e) => {
        let dob_error = '';
        let first_name_error = '';
        let last_name_error = '';
        let gender_error = '';
        let mobile_error = '';
        let email_error = '';
        let secondary_mobile_error = '';
        let secondary_email_error = '';
        let business_category_error = '';
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        if (name == 'dob') {
            var today = new Date();
            var birthDate = new Date(value);  // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
            }
            this.setState({ age: age_now })
            this.setState({ dob_error }, () => setTimeout(() => this.setState({ dob_error: '' }), 5000));
        } else if (name === 'company_registration_path') {
            this.setState({ company_registration_path: e.target.files[0] });
        } else if (name === 'gst_path') {
            this.setState({ gst_path: e.target.files[0] });
        } else if (name === 'pan') {
            this.setState({ pan_path: e.target.files[0] });
        } else if (name === 'adhaar') {
            this.setState({ adhaar_path: e.target.files[0] });
        } else if (name === 'adhaar') {
            this.setState({ adhaar_path: e.target.files[0] });
        } else if (name == 'first_name') {
            this.setState({ first_name_error }, () => setTimeout(() => this.setState({ first_name_error: '' }), 5000));
        } else if (name === 'last_name') {
            this.setState({ last_name_error }, () => setTimeout(() => this.setState({ last_name_error: '' }), 5000));
        } else if (name === 'gender') {
            this.setState({ gender_error }, () => setTimeout(() => this.setState({ gender_error: '' }), 5000));
        } else if (name === 'mobile') {
            this.setState({ mobile_error }, () => setTimeout(() => this.setState({ mobile_error: '' }), 5000));
        } else if (name === 'email') {
            this.setState({ email_error }, () => setTimeout(() => this.setState({ email_error: '' }), 5000));
        } else if (name === 'secondary_mobile') {
            this.setState({ secondary_mobile_error }, () => setTimeout(() => this.setState({ secondary_mobile_error: '' }), 5000));
        } else if (name === 'secondary_email') {
            this.setState({ secondary_email_error }, () => setTimeout(() => this.setState({ secondary_email_error: '' }), 5000));
        } else if (name === 'business_categeory_id') {
            this.setState({ business_category_error }, () => setTimeout(() => this.setState({ business_category_error: '' }), 5000));
        }
    }

    validate = () => {
        let first_name_error = '';
        let middle_name_error = '';
        let last_name_error = '';
        let gender_error = '';
        let dob_error = '';
        let occupation_error = '';
        let email_error = '';
        let mobile_error = '';
        let login_id_error = '';
        let password_error = '';
        let adhaar_number_error = '';
        let adhar_path_error = '';
        let permanent_address_error = '';
        let permanent_state_error = '';
        let permanent_city_error = '';
        let permanent_locality_error = '';
        let permanent_pincode_error = '';
        let current_address_error = '';
        let current_state_error = '';
        let current_city_error = '';
        let current_locality_error = '';
        let current_pincode_error = '';
        let company_error = '';
        let gst_no_error = '';
        let company_registration_no_error = '';
        let business_category_error = '';
        let pan_no_error = '';
        let secondary_mobile_error = '';
        let secondary_email_error = '';
        let numberPattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        let mailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!this.state.first_name) {
            first_name_error = "Please enter first name";
        } else if (!this.state.last_name) {
            last_name_error = "Please enter last name";
        } else if (!this.state.gender && this.state.customer_type === 1) {
            gender_error = "Please select gender";
        } else if (!this.state.dob && this.state.customer_type === 1) {
            dob_error = "Please select date of birth";
        } else if (!this.state.mobile || !numberPattern.test(this.state.mobile)) {
            mobile_error = "Please enter primary mobile number";
        } else if (!this.state.email || mailRegex.test(this.state.email) === false) {
            email_error = "Please enter primary email id";
        } else if (this.state.secondary_mobile != '' && this.state.secondary_mobile !== null && !numberPattern.test(this.state.secondary_mobile)) {
            secondary_mobile_error = "Please enter valid mobile number";
        } else if (this.state.secondary_email != '' && this.state.secondary_email !== null && mailRegex.test(this.state.secondary_email) === false) {
            secondary_email_error = "Please enter valid email id";
        }
        // else if (!this.state.adhaar_number && this.state.customer_type == 1) {
        //     adhaar_number_error = "Please enter adhaar number";
        // } else if (!this.state.adhaar_path && this.state.customer_type == 1) {
        //     adhar_path_error = "Please upload adhaar card";
        // } 
        // else if (!this.state.occupation_id && this.state.customer_type == 1) {
        //     occupation_error = "Please select occupation";
        // }
        else if (!this.state.company_name && this.state.customer_type === 2) {
            company_error = "Please enter company name";
        } else if (!this.state.business_categeory_id && this.state.customer_type === 2) {
            business_category_error = "Please select category";
        }
        // else if (!this.state.company_registration_no && this.state.customer_type == 2) {
        //     company_registration_no_error = "Please enter company resistration number";
        // }  else if (!this.state.gst && this.state.customer_type == 2) {
        //     gst_no_error = "Please enter gst number";
        // } else if (!this.state.pan ) {
        //     pan_no_error = "Please enter pan number";
        // }  else if (!this.state.permanent_address) {
        //     permanent_address_error = "Please enter permanent address";
        // } else if (!this.state.permanent_state) {
        //     permanent_state_error = "Please select state";
        // } else if (!this.state.permanent_city) {
        //     permanent_city_error = "Please select city";
        // } else if (!this.state.permanent_locality) {
        //     permanent_locality_error = "Please select locality";
        // } else if (!this.state.permanent_pincode) {
        //     permanent_pincode_error = "Please enter pincode";
        // } else if (!this.state.current_address) {
        //     current_address_error = "Please enter current address";
        // } else if (!this.state.current_state) {
        //     current_state_error = "Please select state";
        // } else if (!this.state.current_city) {
        //     current_city_error = "Please select city";
        // } else if (!this.state.current_locality) {
        //     current_locality_error = "Please select locality";
        // } else if (!this.state.current_pincode) {
        //     current_pincode_error = "Please enter pincode";
        // }
        if (first_name_error || middle_name_error || last_name_error || gender_error || dob_error || occupation_error ||
            email_error || mobile_error || login_id_error || password_error || adhaar_number_error || adhar_path_error ||
            permanent_address_error || permanent_state_error || permanent_city_error || permanent_locality_error || permanent_pincode_error || current_address_error || current_state_error || current_city_error || current_locality_error ||
            current_pincode_error || company_error || gst_no_error || company_registration_no_error || business_category_error || pan_no_error || secondary_mobile_error || secondary_email_error) {
            this.setState({
                first_name_error, middle_name_error, last_name_error, gender_error, dob_error, occupation_error,
                email_error, mobile_error, login_id_error, password_error, adhaar_number_error, adhar_path_error,
                permanent_address_error, permanent_state_error, permanent_city_error, permanent_locality_error, permanent_pincode_error, current_address_error, current_state_error, current_city_error, current_locality_error,
                current_pincode_error, company_error, gst_no_error, company_registration_no_error, business_category_error, pan_no_error, secondary_mobile_error, secondary_email_error
            });
            return false;
        }
        return true;
    }

    profileUpdateForm = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            this.setState({ 'display_processing': 'block', 'hide_submit': 'none' });
            const dataFromState = this.state;
            const formData = new FormData();
            if (dataFromState.id) { formData.append('id', dataFromState.id); }
            if (dataFromState.id) { formData.append('customer_id', dataFromState.id); }
            if (dataFromState.first_name) { formData.append('first_name', dataFromState.first_name); }
            if (dataFromState.middle_name) { formData.append('middle_name', dataFromState.middle_name); }
            if (dataFromState.last_name) { formData.append('last_name', dataFromState.last_name); }
            if (dataFromState.gender) { formData.append('gender', dataFromState.gender); }
            if (dataFromState.dob) { formData.append('dob', dataFromState.dob); }
            if (dataFromState.age) { formData.append('age', dataFromState.age); }
            if (dataFromState.mobile) { formData.append('mobile', dataFromState.mobile); }
            if (dataFromState.secondary_mobile) { formData.append('secondary_mobile', dataFromState.secondary_mobile); }
            if (dataFromState.email) { formData.append('email', dataFromState.email); }
            if (dataFromState.secondary_email) { formData.append('secondary_email', dataFromState.secondary_email); }
            if (dataFromState.company_name) { formData.append('company_name', dataFromState.company_name); }
            if (dataFromState.business_categeory_id) { formData.append('business_categeory_id', dataFromState.business_categeory_id); }
            if (dataFromState.occupation_id) { formData.append('occupation_id', dataFromState.occupation_id); }
            if (dataFromState.adhaar_number) { formData.append('adhaar_number', dataFromState.adhaar_number); }
            formData.append('pan', dataFromState.pan);
            formData.append('pan_path', dataFromState.pan_path);
            formData.append('gst', dataFromState.gst);
            formData.append('gst_path', dataFromState.gst_path);
            formData.append('adhaar_path', dataFromState.adhaar_path);
            formData.append('company_registration_no', dataFromState.company_registration_no);
            formData.append('company_registration_path', dataFromState.company_registration_path);
            formData.append('permanent_address', dataFromState.permanent_address);
            formData.append('permanent_state', dataFromState.permanent_state);
            formData.append('permanent_city', dataFromState.permanent_city);
            formData.append('permanent_pincode', dataFromState.permanent_pincode);
            formData.append('permanent_locality', dataFromState.permanent_locality);
            formData.append('current_address', dataFromState.current_address);
            formData.append('current_state', dataFromState.current_state);
            formData.append('current_city', dataFromState.current_city);
            formData.append('current_locality', dataFromState.current_locality);
            formData.append('current_pincode', dataFromState.current_pincode);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };
            axios.post(AppUrl.profileUpdate, formData, config).then(response => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    localStorage.removeItem('user');
                    this.userInfo.id = this.state.id;
                    this.userInfo.first_name = this.state.first_name;
                    this.userInfo.middle_name = this.state.middle_name;
                    this.userInfo.last_name = this.state.last_name;
                    this.userInfo.gender = this.state.gender;
                    this.userInfo.dob = this.state.dob;
                    this.userInfo.age = this.state.age;
                    this.userInfo.occupation_id = this.state.occupation_id;
                    this.userInfo.email = this.state.email;
                    this.userInfo.secondary_email = this.state.secondary_email;
                    this.userInfo.mobile = this.state.mobile;
                    this.userInfo.secondary_mobile = this.state.secondary_mobile;
                    this.userInfo.login_id = this.state.login_id;
                    this.userInfo.password = this.state.password;
                    this.userInfo.user_type = this.state.user_type;
                    this.userInfo.customer_type = this.state.customer_type;
                    this.userInfo.profession_id = this.state.profession_id;
                    this.userInfo.company_name = this.state.company_name;
                    this.userInfo.adhaar_number = this.state.adhaar_number;
                    this.userInfo.adhaar_path = this.state.adhaar_path;
                    this.userInfo.business_categeory_id = this.state.business_categeory_id;
                    this.userInfo.pan = this.state.pan;
                    this.userInfo.pan_path = this.state.pan_path;
                    this.userInfo.gst = this.state.gst;
                    this.userInfo.gst_path = this.state.gst_path;
                    this.userInfo.company_registration_no = this.state.company_registration_no;
                    this.userInfo.company_registration_path = this.state.company_registration_path;
                    this.userInfo.permanent_address = this.state.permanent_address;
                    this.userInfo.permanent_state = this.state.permanent_state;
                    this.userInfo.permanent_city = this.state.permanent_city;
                    this.userInfo.permanent_locality = this.state.permanent_locality;
                    this.userInfo.permanent_pincode = this.state.permanent_pincode;
                    this.userInfo.current_address = this.state.current_address;
                    this.userInfo.current_state = this.state.current_state;
                    this.userInfo.current_city = this.state.current_city;
                    this.userInfo.current_locality = this.state.current_locality;
                    this.userInfo.current_pincode = this.state.current_pincode;
                    this.userInfo.gst = this.state.gst;
                    this.userInfo.website = this.state.website;
                    this.userInfo.details_id = this.state.details_id;

                    if (this.userInfo.customer_type == 2) {
                        this.setState({ display_com_column: 'block' })
                    }
                    if (this.userInfo.customer_type == 1) {
                        this.setState({ display_ind_column: 'block' })
                    }

                    // if (this.userInfo.pan_path) {
                    //     this.setState({ pan_path_display: 'block' })
                    // }
                    // if (this.userInfo.gst_path) {
                    //     this.setState({ gst_path_display: 'block' })
                    // }
                    // if (this.userInfo.company_registration_path) {
                    //     this.setState({ company_registration_path_display: 'block' })
                    // }
                    // if (this.userInfo.adhaar_path) {
                    //     this.setState({ adhaar_path_display: 'block' })
                    // }
                    localStorage.setItem("user", JSON.stringify(this.userInfo));
                }
            }).catch(error => {

            });
        }
    }

    //Password Update 
    passwordValidation = () => {
        let old_password_error = '';
        let new_password_error = '';
        let confirm_password_error = '';
        if (!this.state.old_password) {
            old_password_error = 'Old password required!';
        } else if (!this.state.new_password) {
            new_password_error = 'New password required!';
        } else if (!this.state.confirm_password) {
            confirm_password_error = 'Confirm password required!';
        } else if (this.state.new_password != this.state.confirm_password) {
            confirm_password_error = 'Password and Confirm password does not match!';
        } else if (this.state.new_password == this.state.old_password) {
            new_password_error = 'Old Password and New password can not same!';
        }
        if (old_password_error || new_password_error || confirm_password_error) {
            this.setState({ old_password_error, new_password_error, confirm_password_error });
            return false;
        }
        return true;
    }
    
    passwordUpdate = (e) => {
        const isValid = this.passwordValidation();
        if (isValid) {
            this.setState({ 'password_processing': 'block', 'password_update_btn': 'none' });
            const dataFromState = this.state;
            const postingData = {
                'customer_id': dataFromState.id,
                'old_password': dataFromState.old_password,
                'new_password': dataFromState.new_password,
                'confirm_password': dataFromState.confirm_password
            }
            axios.post(AppUrl.customerPasswordUpdate, postingData).then(response => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("user_type");
                    localStorage.removeItem("profile_image");
                    this.setState({
                        isUerLogin: false
                    })
                    window.location.href = decodeURIComponent('/');
                    this.setState({ 'password_processing': 'none', 'password_update_btn': 'block', 'old_password': '', 'new_password': '', 'confirm_password': '' });
                } else if (status === 'error') {
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    this.setState({ 'password_processing': 'none', 'password_update_btn': 'block' });
                }

            }).catch(error => {

            });
        }
    }

    render() {
        const maxDate = new Date(); // Get the current date
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        const maxDateFormatted = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1).toString().padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span class="mr-2 mb-0"><i class="uil uil-dashboard"></i></span>Profile</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    <h3 className="text-lg font-bold mb-4">Personal Details</h3>
                                    <hr className="mb-6 m-0" />
                                    <form>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">First Name<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                    <input type="text" className="form-input" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handelUserInput} required />
                                                    <span className='required'>{this.state.first_name_error}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">Middle Name</label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                    <input type="text" className="form-input" id="middle_name" name="middle_name" value={this.state.middle_name} onChange={this.handelUserInput} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">Last Name<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                    <input type="text" className="form-input" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handelUserInput} required />
                                                    <span className='required'>{this.state.last_name_error}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: `${this.state.display_ind_column}` }} >
                                                <div>
                                                    <label htmlFor="gender" className="font-semibold">Gender<span className="text-red-600 ml-1">*</span></label>
                                                    <select id="gender" name="gender" value={this.state.gender} onChange={this.handelUserInput} className="form-input mt-2">
                                                        <option value="">-- Select --</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                        <option value="3">Other</option>
                                                    </select>
                                                    <span className='required'>{this.state.gender_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label htmlFor="dob" className="font-semibold">Date of Birth<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <input type="date" className="form-input" id="dob" name="dob" value={this.state.dob} onChange={this.handelUserInput} placeholder="dd/mm/yy" max={maxDateFormatted} />
                                                    <span className='required'>{this.state.dob_error}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label htmlFor="age" className="font-semibold">Age<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <p><b> {this.state.age}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div>
                                                <label htmlFor="mobile" className="font-semibold">Primary Mobile<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> */}
                                                    <input type="text" className="form-input" id="mobile" name="mobile" value={this.state.mobile} onChange={this.handelUserInput} maxlength={10} required />
                                                    <span className='required'>{this.state.mobile_error}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">Primary Email Id<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                                    <input type="email" className="form-input" id="email" name="email" value={this.state.email} onChange={this.handelUserInput} required />
                                                    <span className='required'>{this.state.email_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label htmlFor="typejob" className="font-semibold">Secondary Mobile</label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> */}
                                                    <input type="text" className="form-input" id="secondary_mobile" name="secondary_mobile" value={this.state.secondary_mobile} onChange={this.handelUserInput} maxlength={10} />
                                                    <span className='required'>{this.state.secondary_mobile_error}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label htmlFor="typejob" className="font-semibold">Secondary Email Id</label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                                    <input type="email" className="form-input" id="secondary_email" name="secondary_email" value={this.state.secondary_email} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.secondary_email_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div style={{ display: `${this.state.display_com_column}` }}>
                                                <label htmlFor="typejob" className="font-semibold">Company Name<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <input type="text" className="form-input" id="company_name" name="company_name" value={this.state.company_name} onChange={this.handelUserInput} />
                                                    <span className='required'>{this.state.company_error}</span>
                                                </div>
                                            </div>
                                            <div style={{ display: `${this.state.display_com_column}` }} >
                                                <div>
                                                    <label htmlFor="business_categeory_id" className="font-semibold">Business Category<span className="text-red-600 ml-1">*</span></label>
                                                    <select id="business_categeory_id" name="business_categeory_id" value={this.state.business_categeory_id} onChange={this.handelUserInput} className="form-input mt-2">
                                                        <option value="">-- Select --</option>
                                                        {
                                                            this.state.bussiness_categories.map((category, key) => {
                                                                return <option key={key} value={category.id}>{category.category_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <span className='required'>{this.state.business_category_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div style={{ display: `${this.state.display_com_column}` }}>
                                                <label htmlFor="company_registration_no" className="font-semibold">Company Registration Certificate Number<span className="text-red-600 ml-1">*</span></label><br />
                                                <input type="text" className="form-input pl-12 mt-3" id="company_registration_no" name="company_registration_no" value={this.state.company_registration_no} onChange={this.handelUserInput} maxlength={20} />
                                                <span className='required'>{this.state.company_registration_no_error}</span>
                                            </div>
                                            <div style={{ display: `${this.state.display_com_column}` }}>
                                                <label className="font-semibold" htmlFor="company_registration_path">Upload Company Registration Certificate: <span className="text-red-600 ml-1">*</span>  <a href={AppUrl.documentPath + '/customer_documents/' + this.state.id + '/' + this.state.company_registration_path} target="_blank" style={{ display: `${this.state.company_registration_path_display}` }} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                <input className="form-input mt-1 " id="company_registration_path" name="company_registration_path" type="file" accept="image/png, image/jpeg" onChange={this.handelUserInput} />
                                                <span className='required'>{this.state.company_registration_path_error}</span>
                                            </div>
                                        </div><br />
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div style={{ display: `${this.state.display_com_column}` }}>
                                                <label htmlFor="gst" className="font-semibold">GST Number<span className="text-red-600 ml-1">*</span></label><br />
                                                <input type="text" className="form-input pl-12 mt-3" id="gst" name="gst" value={this.state.gst} onChange={this.handelUserInput} maxlength={20} />
                                                <span className='required'>{this.state.gst_no_error}</span>
                                            </div>
                                            <div style={{ display: `${this.state.display_com_column}` }}>
                                                <label className="font-semibold" htmlFor="gst_path">Upload GST:  <span className="text-red-600 ml-1">*</span>  <a href={AppUrl.documentPath + '/customer_documents/' + this.state.id + '/' + this.state.gst_path} target="_blank" style={{ display: `${this.state.gst_path_display}` }} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                <input className="form-input mt-1 " id="gst_path" name="gst_path" type="file" accept="image/png, image/jpeg" onChange={this.handelUserInput} />
                                                <span className='required'>{this.state.gst_path_error}</span>
                                            </div>
                                        </div><br />
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="pan" className="font-semibold">PAN Number<span className="text-red-600 ml-1">*</span></label><br />
                                                <input type="text" className="form-input pl-12 mt-3" id="pan" name="pan" value={this.state.pan} onChange={this.handelUserInput} maxlength={10} required />
                                                <span className='required'>{this.state.pan_no_error}</span>
                                            </div>
                                            <div >
                                                <label className="font-semibold" htmlFor="pan_path">Upload PAN: <span className="text-red-600 ml-1">*</span>  <a href={AppUrl.documentPath + '/customer_documents/' + this.state.id + '/' + this.state.pan_path} target="_blank" style={{ display: `${this.state.pan_path_display}` }} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                <input className="form-input mt-1 " id="pan_path" name="pan_path" type="file" accept="image/png, image/jpeg" onChange={this.handelUserInput} />
                                                <span className='required'>{this.state.company_registration_path_error}</span>
                                            </div>
                                        </div><br />
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label htmlFor="typejob" className="font-semibold">Adhaar Number<span className="text-red-600 ml-1">*</span></label><br />
                                                <input type="text" className="form-input pl-12 mt-3" id="adhaar_number" name="adhaar_number" value={this.state.adhaar_number} onChange={this.handelUserInput} maxlength={12} required />
                                                <span className='required'>{this.state.adhaar_number_error}</span>
                                            </div>
                                            <div style={{ display: `${this.state.display_ind_column}` }}>
                                                <label className="font-semibold" htmlFor="adhaar_path">Upload Adhaar: <span className="text-red-600 ml-1">*</span>  <a href={AppUrl.documentPath + '/customer_documents/' + this.state.id + '/' + this.state.adhaar_path} target="_blank" style={{ display: `${this.state.adhaar_path_display}` }} className="btn btn-icon bg-red-600/5 hover:bg-red-600 border-red-600/10 hover:border-red-600 text-red-600 hover:text-white rounded-full"><FeatherIcon icon="eye" className="h-4 w-4" /></a></label>
                                                <input className="form-input mt-1 " id="adhaar_path" name="adhaar_path" type="file" accept="image/png, image/jpeg" onChange={this.handelUserInput} />
                                                <span className='required'>{this.state.adhar_path_error}</span>
                                            </div>
                                        </div><br />
                                        <h2 className="mb-6 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold"> Permanent Address</h2>
                                        <label htmlFor="typejob" className="font-semibold">Address<span className="text-red-600 ml-1">*</span></label><br />
                                        <input type="text" className="form-input mt-3" id="permanent_address" name="permanent_address" value={this.state.permanent_address} onChange={this.handelUserInput} required />
                                        <span className='required'>{this.state.permanent_address_error}</span>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">State<span className="text-red-600 ml-1">*</span></label>
                                                <select id="permanent_state" name="permanent_state" value={this.state.permanent_state} onChange={this.changePermanentState} className="form-input mt-3">
                                                    <option value="">-- Select --</option>
                                                    {
                                                        this.state.states.map((state, key) => {
                                                            return <option key={key} value={state.id}>{state.state_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.permanent_state_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="permanent_city" className="font-semibold">City<span className="text-red-600 ml-1">*</span></label>
                                                <select id="permanent_city" name="permanent_city" value={this.state.permanent_city} onChange={this.changePermanentCity} className="form-input mt-3">
                                                    <option value="">Select City</option>
                                                    {
                                                        this.state.cities.map((city, key) => {
                                                            return <option key={key} value={city.id}>{city.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.permanent_city_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="permanent_locality" className="font-semibold">Locality<span className="text-red-600 ml-1">*</span></label>
                                                <select id="permanent_locality" name="permanent_locality" value={this.state.permanent_locality} onChange={this.handelUserInput} className="form-input mt-3">
                                                    <option value="">Select Locality</option>
                                                    {
                                                        this.state.localities.map((locality, key) => {
                                                            return <option key={key} value={locality.id}>{locality.locality_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.permanent_locality_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Pin Code<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <input type="text" className="form-input" id="permanent_pincode" name="permanent_pincode" value={this.state.permanent_pincode} onChange={this.handelUserInput} maxlength={6} required />
                                                    <span className='required'>{this.state.permanent_pincode_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="typejob" className="font-semibold"><span className="text-red-600" /></label>
                                            <div className="form-icon relative mt-2">
                                                <p><b>  <input type="checkbox" name defaultValue style={{ marginTop: 20, height: 20, width: 20, marginRight: 5 }} />Click if same as Permanent Address</b></p>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="typejob" className="font-semibold"><span className="text-red-600" /></label>
                                            <div className="form-icon relative mt-2">
                                            </div>
                                        </div>
                                        <h2 className="mb-6 md:text-2xl text-2xl md:leading-normal leading-normal font-semibold"> Current Address</h2>
                                        <label htmlFor="current_address" className="font-semibold">Address<span className="text-red-600 ml-1">*</span></label>
                                        <div className="form-icon relative mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                            <input type="text" className="form-input" id="current_address" name="current_address" value={this.state.current_address} onChange={this.handelUserInput} required />
                                            <span className='required'>{this.state.current_address_error}</span>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-4">
                                            <div>
                                                <label htmlFor="typejob" className="font-semibold">State<span className="text-red-600 ml-1">*</span></label>
                                                <select id="current_state" name="current_state" value={this.state.current_state} onChange={this.changeCurrentState} className="form-input mt-3">
                                                    <option value="">-- Select --</option>
                                                    {
                                                        this.state.current_states.map((state, key) => {
                                                            return <option key={key} value={state.id}>{state.state_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.current_state_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="current_city" className="font-semibold">City<span className="text-red-600 ml-1">*</span></label>
                                                <select id="current_city" name="current_city" value={this.state.current_city} onChange={this.changeCurrentCity} className="form-input mt-3">
                                                    <option value="">-- Select --</option>
                                                    {
                                                        this.state.current_cities.map((city, key) => {
                                                            return <option key={key} value={city.id}>{city.city_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.current_city_error}</span>
                                            </div>
                                            <div>
                                                <label htmlFor="current_locality" className="font-semibold">Locality<span className="text-red-600 ml-1">*</span></label>
                                                <select id="current_locality" name="current_locality" value={this.state.current_locality} onChange={this.handelUserInput} className="form-input mt-3">
                                                    <option value="">Select Locality</option>
                                                    {
                                                        this.state.current_localities.map((locality, key) => {
                                                            return <option key={key} value={locality.id}>{locality.locality_name}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.current_locality_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Pin Code<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    <input type="text" className="form-input" id="current_pincode" name="current_pincode" value={this.state.current_pincode} onChange={this.handelUserInput} maxlength={6} required />
                                                    <span className='required'>{this.state.current_pincode_error}</span>
                                                </div>
                                            </div>
                                        </div>
                                        */}
                                        {/* <input type="submit" id="profile_update" name="profile_update" onClick={(e) => this.profileUpdateForm(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Update Profile" /> */}
                                        <button type="button" id="profile_update" name="profile_update" onClick={(e) => this.profileUpdateForm(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md mt-5"><i className='mdi mdi-check mr-2'></i>Update</button>
                                        <button className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                    </form>{/*end form*/}
                                </div>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                    <h3 className="text-lg font-bold mb-4">Change Password</h3>
                                    <hr className="mb-6 m-0" />
                                    <div>
                                        <form>
                                            {/* <div className="grid grid-cols-1 gap-5"> */}
                                            <div className="grid lg:grid-cols-3 grid-cols-1 gap-5">
                                                <div>
                                                    <label className="form-label font-medium">Old Password<span className="text-red-600 ml-1">*</span></label>
                                                    <div className="form-icon relative mt-2">
                                                        {/* <FeatherIcon icon="key" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                        <input type="password" className="form-input" name="old_password" id="old_password" value={this.state.old_password} onChange={this.handelUserInput} maxlength={8} />
                                                        <span className='required'>{this.state.old_password_error}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label font-medium">New Password<span className="text-red-600 ml-1">*</span></label>
                                                    <div className="form-icon relative mt-2">
                                                        {/* <FeatherIcon icon="key" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                        <input type="password" className="form-input" name="new_password" id="new_password" value={this.state.new_password} onChange={this.handelUserInput} maxlength={8} />
                                                        <span className='required'>{this.state.new_password_error}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="form-label font-medium">Re-type New Password<span className="text-red-600 ml-1">*</span></label>
                                                    <div className="form-icon relative mt-2">
                                                        {/* <FeatherIcon icon="key" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                        <input type="password" className="form-input" name="confirm_password" id="confirm_password" value={this.state.confirm_password} onChange={this.handelUserInput} maxlength={8} />
                                                        <span className='required'>{this.state.confirm_password_error}</span>
                                                    </div>
                                                </div>
                                            </div>{/*end grid*/}
                                            {/* <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" onClick={(e) => this.passwordUpdate(e)} style={{ display: `${this.state.password_update_btn}` }}>Update password</button> */}
                                            <button type="button" id="password_update" name="profile_update" onClick={(e) => this.passwordUpdate(e)} style={{ display: `${this.state.password_update_btn}` }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md mt-5"><i className='mdi mdi-check mr-2'></i>Update</button>
                                            <button className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                        </form>
                                    </div>{/*end row*/}
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>

        )
    }
}

export default Profile
