import React, { Component } from 'react'
import Invoice from '../../components/customer/invoice/Invoice'
class InvoicePage extends Component {


  scrollToTop() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    this.scrollToTop();
  }
  render() {
    return (
      <Invoice />
    )
  }
}

export default InvoicePage
