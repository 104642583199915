import React, { Component } from 'react'
import Customer from '../components/website/common/Customer'

class CustomerPage extends Component {
  render() {
    return (
      <Customer />
    )
  }
}

export default CustomerPage