import React, { Component } from 'react'
import TravellersFAQ from '../components/website/common/TravellersFAQ'

class TravellersFAQPage extends Component {
  render() {
    return (
      <TravellersFAQ />
    )
  }
}

export default TravellersFAQ