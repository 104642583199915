import React, { Component } from 'react'
import BankList from '../components/bank_detail/BankList'
import AddBank from '../components/bank_detail/AddBank'
import withRouter from '../api/withRouter'
class BankPage extends Component {

    constructor() {
        super();
        this.state = {
            pageDisplay: '',
            id: '',
        }
    }

    addBank = () => {
        this.setState({ pageDisplay: 'add', id: '' })
    }

    edit = (id) => {
        this.setState({ pageDisplay: 'edit', id: id })
    }

    view = () => {
        this.setState({ pageDisplay: 'view', id: '' })
    }

    componentDidMount() {
        this.setState({ pageDisplay: 'view' })
    }

    render() {
        const pageDisplay = this.state.pageDisplay;
        const id = this.state.id;
        if (pageDisplay === 'add' || pageDisplay === 'edit') {
            return <AddBank id={id} view={this.view} />
        } else if (pageDisplay === 'view') {
            return <BankList addBank={this.addBank} edit={this.edit} />
        } else {
            return <BankList addAddress={this.addBank} edit={this.edit} />
        }
    }
}

export default withRouter(BankPage)
