import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import UserHeader from '../../website/common/UserHeader';
import UserSidebar from '../../website/common/UserSidebar';
import AppUrl from '../../../api/AppUrl'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import withRouter from '../../../api/withRouter';

class EnquiryAdd extends Component {

    constructor() {
        super();
        this.state = {
            addressList: [],
            documentType: [],
            packingType: [],
            rows: [{
                product_type: '',
                product_name: '',
                qty: '',
                weight: '',
                product_value: '',
                packing_type: '',
                description: '',
                enquiry_img: ''
            }],
            doc: [{
                document_name: '',
                document_img: ''
            }],
            pickup_form: "",
            contact_person: "",
            mobile_no: '',
            drop_to: '',
            drop_contact_person: '',
            drop_mobile_no: '',
            pickup_form_error: "",
            contact_person_error: "",
            mobile_no_error: '',
            drop_to_error: '',
            drop_contact_person_error: '',
            drop_mobile_no_error: '',
            price: '',
            on_chnage_image_status: '',
            hide_submit: '',
            display_processing: 'none',
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('user'));
        if (userInfo) {
            axios.get(AppUrl.getCustomerAllAddress(userInfo.id)).then(response => {
                this.setState({ addressList: response.data.all_address })
            });

            axios.get(AppUrl.getAllProductType).then(response => {
                this.setState({ documentType: response.data.documentType, packingType: response.data.packingType });
            });
        }
    }

    // Enquery Start
    productAddRow = () => {
        const item = {
            product_type: '',
            product_name: '',
            qty: '',
            weight: '',
            product_value: '',
            packing_type: '',
            description: '',
            enquiry_img: '',
        }
        this.setState({
            rows: [...this.state.rows, item]
        });
    }

    handelChangeProduct = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        if (e.target.name == 'enquiry_img') {
            rows[idx] = {
                ...rows[idx],
                [name]: e.target.files[0]
            };
        } else {
            rows[idx] = {
                ...rows[idx],
                [name]: value
            };
            if ([name] == 'product_type') { rows[idx].p_type_error = ''; }
            if ([name] == 'product_name') { rows[idx].pro_name_error = ''; }
            if ([name] == 'qty') { rows[idx].qty_error = ''; }
            if ([name] == 'weight') { rows[idx].p_weight_error = ''; }
            if ([name] == 'product_value') { rows[idx].p_value_error = ''; }
            if ([name] == 'packing_type') { rows[idx].pcaking_type_error = ''; }
        }
        this.setState({
            rows
        });
    };

    handelRemoveSpecificRow = idx => () => {
        const rows = [...this.state.rows];
        if (rows.length > 1) {
            rows.splice(idx, 1);
            this.setState({ rows });
        } else {
            toast.warning('Atlest one product required.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: false,
                draggable: true,
            });
        }
    };

    handelChange = (e) => {
        let pickup_form_error = '';
        let contact_person_error = '';
        let mobile_no_error = '';
        let drop_to_error = '';
        let drop_contact_person_error = '';
        let drop_mobile_no_error = '';
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
        if (name == 'pickup_form') {
            this.setState({ pickup_form_error }, () => setTimeout(() => this.setState({ pickup_form_error: '' }), 5000));
        } if (name == 'contact_person') {
            this.setState({ contact_person_error }, () => setTimeout(() => this.setState({ contact_person_error: '' }), 5000));
        } if (name == 'mobile_no') {
            this.setState({ mobile_no_error }, () => setTimeout(() => this.setState({ mobile_no_error: '' }), 5000));
        } if (name == 'drop_to') {
            this.setState({ drop_to_error }, () => setTimeout(() => this.setState({ drop_to_error: '' }), 5000));
        } if (name == 'drop_contact_person') {
            this.setState({ drop_contact_person_error }, () => setTimeout(() => this.setState({ drop_contact_person_error: '' }), 5000));
        } if (name == 'drop_mobile_no') {
            this.setState({ drop_mobile_no_error }, () => setTimeout(() => this.setState({ drop_mobile_no_error: '' }), 5000));
        }

        if ((e.target.name == 'pickup_form' && e.target.value != '')) {
            this.setState({ pickup_form: e.target.value })
        }
        if (e.target.name == 'drop_to' && e.target.value != '') {
            this.setState({ drop_to: e.target.value })
            // this.getPrice(e.target.value);
        }
        if (e.target.name === 'pickup_form' || e.target.name === 'drop_to') {
            const pickupValue = e.target.name === 'pickup_form' ? e.target.value : document.getElementsByName('pickup_form')[0].value;
            const dropValue = e.target.name === 'drop_to' ? e.target.value : document.getElementsByName('drop_to')[0].value;

            if (pickupValue === dropValue) {
                toast.warning('Pickup address and drop address are not the same.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            }
        }

    }

    //Document Start
    addMoreDocument = () => {
        const item = {
            document_name: '',
            document_img: ''
        }
        this.setState({
            doc: [...this.state.doc, item]
        });
    }
    handelChangeDoc = idx => e => {
        const { name, value } = e.target;
        const doc = [...this.state.doc];
        if (e.target.name == 'document_img') {
            doc[idx] = {
                ...doc[idx],
                [name]: e.target.files[0]
            };
        } else {
            doc[idx] = {
                ...doc[idx],
                [name]: value,
            };
            doc[idx].document_error = '';
        }
        this.setState({
            doc
        });
    }

    handelRemoveDoc = idx => () => {
        const doc = [...this.state.doc];
        if (doc.length > 1) {
            doc.splice(idx, 1);
            this.setState({ doc });
        } else {
            toast.warning('Atlest one data required in document. ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: false,
                draggable: true,
            });
        }
    }
    //Document End

    validate = () => {
        let pickup_form_error = '';
        let contact_person_error = '';
        let mobile_no_error = '';
        let drop_to_error = '';
        let drop_contact_person_error = '';
        let drop_mobile_no_error = '';
        const data = [...this.state.rows];
        const document = [...this.state.doc];
        const letter = /^[a-zA-Z\s]*$/g;
        const number_reg = RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        const decimal_reg = RegExp(/^\d{1,5}$|(?=^.{1,5}$)^\d+\.\d{0,2}$/);

        // single filed validation
        if (!this.state.pickup_form) {
            pickup_form_error = "Please select pickup from.";
        } else if (!this.state.contact_person) {
            contact_person_error = "Please enter contact person name.";
        } else if (!letter.test(this.state.contact_person)) {
            contact_person_error = "Please enter valid contact person name.";
        } else if (!this.state.mobile_no || !number_reg.test(this.state.mobile_no)) {
            mobile_no_error = "Please enter contact person mobile no";
        } else if (!this.state.drop_to) {
            drop_to_error = "Please select drop to.";
        } else if (!this.state.drop_contact_person) {
            drop_contact_person_error = "Please enter drop contact person name.";
        } else if (!this.state.drop_mobile_no || !number_reg.test(this.state.drop_mobile_no)) {
            drop_mobile_no_error = "Please enter drop mobile "
        } else if (this.state.pickup_form == this.state.drop_to) {
            toast.warning('Pickup address and drop address are not the same.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
            });
            return false;
        }
        // multiple product validation
        for (let index = 0; index < data.length; index++) {
            const pcakingTypeErrorElement = data[index].pcaking_type_error;
            if (data[index].product_type === '') {
                this.displayButton();
                data[index].p_type_error = "Please select product type";
                setTimeout(() => {
                    this.displayButton();
                    data[index].p_type_error = '';
                }, 3000);
            } else {
                data[index].p_type_error = '';
            }

            if (data[index].product_name === '') {
                this.displayButton();
                data[index].pro_name_error = "Please enter product name";
                setTimeout(() => {
                    this.displayButton();
                    data[index].pro_name_error = '';
                }, 3000);
            } else {
                data[index].pro_name_error = '';
            }

            if (data[index].qty == '') {
                this.displayButton();
                data[index].qty_error = "Please enter Quantity required";
                setTimeout(() => {
                    this.displayButton();
                    data[index].qty_error = '';
                }, 3000);
            } if (!number_reg.test(data[index].qty)) {
                this.displayButton();
                data[index].qty_error = "Please enter valid Quantity";
            } else {
                data[index].qty_error = '';
            }

            if (data[index].weight == '') {
                this.displayButton();
                data[index].p_weight_error = "Please enter weight";
                setTimeout(() => {
                    this.displayButton();
                    data[index].p_weight_error = '';
                }, 3000);
            } if (!number_reg.test(data[index].weight)) {
                this.displayButton();
                data[index].p_weight_error = "Please enter valid weight";
            } else {
                data[index].p_weight_error = '';
            }

            if (data[index].product_value == '') {
                this.displayButton();
                data[index].p_value_error = "Please enter product value";
                setTimeout(() => {
                    this.displayButton();
                    data[index].p_value_error = '';
                }, 3000);
            } if (!number_reg.test(data[index].product_value)) {
                this.displayButton();
                data[index].p_value_error = "Please enter valid product value";
            } else {
                data[index].p_value_error = '';
            }

            if (data[index].packing_type == '') {
                this.displayButton();
                data[index].pcaking_type_error = "Please select packing type";
                setTimeout(() => {
                    this.displayButton();
                    data[index].pcaking_type_error = '';
                }, 3000);
            } else {
                data[index].pcaking_type_error = '';
            }
        }

        // multiple document validation
        for (let index = 0; index < document.length; index++) {
            if (document[index].document_name == '') {
                this.displayButton();
                document[index].document_error = 'Please enter document name'
                setTimeout(() => {
                    this.displayButton();
                    data[index].document_error = '';
                }, 3000);
            } else {
                document[index].document_error = ''
            }
        }

        if (pickup_form_error || contact_person_error || mobile_no_error || drop_to_error || drop_contact_person_error || drop_mobile_no_error) {
            this.setState({ pickup_form_error, contact_person_error, mobile_no_error, drop_to_error, drop_contact_person_error, drop_mobile_no_error });

            setTimeout(() => {
                this.setState({
                    pickup_form_error: '',
                    contact_person_error: '',
                    mobile_no_error: '',
                    drop_to_error: '',
                    drop_contact_person_error: '',
                    drop_mobile_no_error: '',
                });
            }, 3000);

            return false;
        }
        return true;
    }

    displayButton = () => {
        this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
    }

    submitEnquiry = (e) => {
        e.preventDefault();
        const dataFromState = this.state;
        const isValid = this.validate();
        if (isValid) {
            //this.setState({ 'display_processing': 'block', 'hide_submit': 'none' });
            for (var i = 0; i < dataFromState.rows.length; i++) {
                var loopValue = dataFromState.rows[i];
                if (loopValue.product_type === '' || loopValue.product_name === '' || loopValue.qty === '' || loopValue.weight === '' || loopValue.product_value === '' || loopValue.packing_type === '') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.warning('Please fill up product details.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    return false;
                }
            }
            for (var j = 0; j < dataFromState.doc.length; j++) {
                var loopValue = dataFromState.doc[j];
                if (loopValue.document_name === '' || loopValue.document_img === '') {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                    toast.warning('Please fill up document required field.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    return false;
                }
            }
            const created_by = JSON.parse(localStorage.getItem('user'));
            const postingData = {
                'rows': dataFromState.rows,
                'doc': dataFromState.doc,
                'pickup_form': dataFromState.pickup_form,
                'contact_person': dataFromState.contact_person,
                'mobile_no': dataFromState.mobile_no,
                'drop_to': dataFromState.drop_to,
                'drop_contact_person': dataFromState.drop_contact_person,
                'drop_mobile_no': dataFromState.drop_mobile_no,
                'created_by': created_by.id
            }
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };
            axios.post(AppUrl.enquirySave, postingData, config).then((response) => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                    window.location.href = decodeURIComponent('/enquiries');
                } else {
                    this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                }
            }).catch(error => {

            });
        }
    }

    handleKeyPress(e) {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <Fragment>
                <section className="relative lg:pb-24 pb-16">
                    <UserHeader />
                    <div className="container lg:mt-24 mt-16">
                        <div className="md:flex">
                            <UserSidebar />
                            <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-[30px] md:mt-0">
                                <h3 className="text-lg text-indigo-600 font-bold mb-4"><span className="mr-2 mb-0"><i className="uil uil-receipt"></i></span>Enquiry</h3>
                                <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                    {/* <h3 className="text-lg font-bold mb-4">Enquirie Form</h3> */}
                                    <hr className="mb-6 m-0" />
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="pickup_form" className="font-semibold">Pickup From<span className="text-red-600 ml-1">*</span>  (<Link to="/customer-address">Add New</Link>)</label>
                                                <select id="pickup_form" name="pickup_form" className="form-input mt-3" value={this.state.pickup_form} onChange={this.handelChange}>
                                                    <option value=''>-- Select --</option>
                                                    {
                                                        this.state.addressList.map((address, key) => {
                                                            return <option value={address.id} key={key}>{address.address}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.pickup_form_error}</span>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div>
                                                <label className="form-label font-medium">Contact Person<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                    <input type="text" className="form-input" id="contact_person" name="contact_person" value={this.state.contact_person} onChange={this.handelChange} />
                                                </div>
                                                <span className='required'>{this.state.contact_person_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Phone No <span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                    <input type="text" className="form-input" id="mobile_no" name="mobile_no" value={this.state.mobile_no} onChange={this.handelChange} maxLength={10} onKeyPress={(e) => this.handleKeyPress(e)} />
                                                </div>
                                                <span className='required'>{this.state.mobile_no_error}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="drop_to" className="font-semibold">Drop To<span className="text-red-600 ml-1">*</span> (<Link to="/customer-address">Add New</Link>)</label>
                                                <select id="drop_to" name="drop_to" value={this.state.drop_to} onChange={this.handelChange} className="form-input mt-2">
                                                    <option value=''>-- Select --</option>
                                                    {
                                                        this.state.addressList.map((address, key) => {
                                                            return <option value={address.id} key={key}>{address.address}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className='required'>{this.state.drop_to_error}</span>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                            <div>
                                                <label className="form-label font-medium">Contact Person<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                    <input type="text" className="form-input" id="drop_contact_person" name="drop_contact_person" value={this.state.drop_contact_person} onChange={this.handelChange} />
                                                </div>
                                                <span className='required'>{this.state.drop_contact_person_error}</span>
                                            </div>
                                            <div>
                                                <label className="form-label font-medium">Phone No.<span className="text-red-600 ml-1">*</span></label>
                                                <div className="form-icon relative mt-2">
                                                    {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                    <input type="text" className="form-input" id="drop_mobile_no" name="drop_mobile_no" value={this.state.drop_mobile_no} onChange={this.handelChange} maxLength={10} onKeyPress={(e) => this.handleKeyPress(e)} />
                                                </div>
                                                <span className='required'>{this.state.drop_mobile_no_error}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        <div className="grid lg:grid-cols-1 grid-cols-1 gap-5">
                                            <div>
                                                <label htmlFor="price" className="font-semibold">Price</label>
                                                <input type="text" className="form-input" id="price" name="price" value={this.state.price} disabled />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        {
                                            this.state.rows.map((item, idx) => (
                                                <div key={idx}>
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                        <div>
                                                            <label htmlFor="product_type" className="font-semibold">Product Type<span className="text-red-600 ml-1">*</span></label>
                                                            <select id={`product_type${idx}`} name="product_type" onChange={this.handelChangeProduct(idx)} className="form-input mt-2">
                                                                <option value="">-- Select --</option>
                                                                {
                                                                    this.state.documentType.map((type, key) => {
                                                                        return <option value={type.id} key={key}>{type.product_type}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            <span className='required'>{item.p_type_error}</span>
                                                        </div>
                                                        <div>
                                                            <label className="form-label font-medium">Product Name<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="user" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <input type="text" className="form-input" id={`product_name${idx}`} name="product_name" onChange={this.handelChangeProduct(idx)} />
                                                            </div>
                                                            <span className='required'>{item.pro_name_error}</span>
                                                        </div>
                                                        <div>
                                                            <label className="form-label font-medium">Quantity<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <input type="text" className="form-input" id={`qty${idx}`} name="qty" onChange={this.handelChangeProduct(idx)} onKeyPress={(e) => this.handleKeyPress(e)} />
                                                            </div>
                                                            <span className='required'>{item.qty_error}</span>
                                                        </div>
                                                        <div>
                                                            <label className="form-label font-medium">Weight (Kg)<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <input type="text" className="form-input" id={`weight${idx}`} name="weight" onChange={this.handelChangeProduct(idx)} onKeyPress={(e) => this.handleKeyPress(e)} />
                                                            </div>
                                                            <span className='required'>{item.p_weight_error}</span>
                                                        </div>
                                                        <div>
                                                            <label className="form-label font-medium">Value of the Product (INR)<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="user-check" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <input type="text" className="form-input" id={`product_value${idx}`} name="product_value" onChange={this.handelChangeProduct(idx)} onKeyPress={(e) => this.handleKeyPress(e)} />
                                                            </div>
                                                            <span className='required'>{item.p_value_error}</span>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="packing_type" className="font-semibold">Packing Type<span className="text-red-600 ml-1">*</span></label>
                                                            <select id={`packing_type${idx}`} name="packing_type" onChange={this.handelChangeProduct(idx)} className="form-input mt-2">
                                                                <option value="">-- Select --</option>
                                                                {
                                                                    this.state.packingType.map((type, key) => {
                                                                        return <option value={type.id} key={key}>{type.packing_type}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            <span className='required'>{item.pcaking_type_error}</span>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="description" className="font-semibold">Description</label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="message-circle" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <textarea name="description" id={`description${idx}`} className="form-input h-28" placeholder="Message :" onChange={this.handelChangeProduct(idx)} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className="font-semibold" htmlFor="enquiry_img">Upload Image</label>
                                                            <input className="form-input file-sty mt-2" id={`enquiry_img${idx}`} name="enquiry_img" type="file" accept="image/png, image/jpeg" onChange={this.handelChangeProduct(idx)} />
                                                        </div>
                                                    </div>
                                                    {/* <button type="button" onClick={this.handelRemoveSpecificRow(idx)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md mt-2"><i className="mdi mdi-delete mr-2"></i>Delete Product</button> */}
                                                    <button type="button" onClick={this.handelRemoveSpecificRow(idx)} className="btn btn-icon bg-transparent hover:bg-red-600 border-red-600 text-red-600 hover:text-white rounded-full mt-2"><i className="mdi mdi-delete"></i></button>
                                                    <hr className="mt-3 mb-3" />
                                                </div>
                                            ))
                                        }
                                        <button type="button" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" onClick={this.productAddRow}><i className="mdi mdi-plus mr-2"></i>Add</button>
                                    </div>
                                    <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-3">
                                        {
                                            this.state.doc.map((item, idx) => (
                                                <div key={idx}>
                                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                        <div>
                                                            <label className="form-label font-medium">Document Name<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <FeatherIcon icon="user" className="w-4 h-4 absolute top-3 left-4" /> */}
                                                                <input type="text" className="form-input" id={`document_name${idx}`} name="document_name" onChange={this.handelChangeDoc(idx)} />
                                                            </div>
                                                            <span className='required'>{item.document_error}</span>
                                                        </div>
                                                        <div>
                                                            <label className="font-semibold" htmlFor="document_img">Upload Image</label>
                                                            <input className="form-input file-sty mt-3" id={`document_img${idx}`} name="document_img" type="file" onChange={this.handelChangeDoc(idx)} />
                                                        </div>
                                                    </div>
                                                    {/* <button type="button" onClick={this.handelRemoveDoc(idx)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md mt-2"><i className="mdi mdi-delete mr-2"></i>Delete Document</button> */}
                                                    <button type="button" onClick={this.handelRemoveDoc(idx)} className="btn btn-icon bg-transparent hover:bg-red-600 border-red-600 text-red-600 hover:text-white rounded-full mt-2"><i className="mdi mdi-delete"></i></button>
                                                    <hr className="mt-3 mb-3" />
                                                </div>
                                            ))
                                        }
                                        <button type="button" onClick={this.addMoreDocument} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5"><i className="mdi mdi-plus mr-2"></i>Add</button>
                                    </div>
                                    <div className="mt-6">
                                        <hr className="mt-3 mb-3" />
                                        {/* <input type="submit" onClick={(e) => this.submitEnquiry(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Submit Enquiry" /> */}
                                        <button type="button" onClick={(e) => this.submitEnquiry(e)} className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-md mt-5"><i className="mdi mdi-check mr-2"></i>Submit</button>
                                        <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                    </div>
                                    {/*end form*/}
                                </div>
                            </div>
                        </div>{/*end grid*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End Hero */}
            </Fragment>
        )
    }
}

export default withRouter(EnquiryAdd)
