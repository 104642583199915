import React, { Component, Fragment } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";
import withRouter from '../../../api/withRouter';
import { BrowserRouter, Route, NavLink } from 'react-router-dom';

class Navbar extends Component {

    userInfo = {};

    constructor() {
        super();
        this.state = {
            login_status: 'login',
            about_active_select: '',
            works_active_select: '',
            customer_active_select: '',
            traveller_active_select: '',
            contact_active_select: '',
            label_name_change:'Sign In / Register',
        }
    }

    componentDidMount() {
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        if (this.userInfo) {
            this.setState({ 'login_status': 'profile' , 'label_name_change' : this.userInfo['first_name']+'  Profile'})
        }
    }

    updateActiveState(currentPathname) {
        if (currentPathname === 'about-us') {
          this.setState({ about_active_select: 'red' });
        } else if (currentPathname === 'how-it-works') {
          this.setState({ works_active_select: 'red' });
        } else if (currentPathname === 'customer') {
          this.setState({ customer_active_select: 'red' });
        } else if (currentPathname === 'travellers') {
          this.setState({ traveller_active_select: 'red' });
        } else if (currentPathname === 'contact') {
          this.setState({ contact_active_select: 'red' });
        }
      }

    toggleMenu() {
        document.getElementById('isToggle').classList.toggle('open');
        var isOpen = document.getElementById('navigation')
        if (isOpen.style.display === "block") {
            isOpen.style.display = "none";
        } else {
            isOpen.style.display = "block";
        }
    }

    scrollToTop(currentPathname) {
        window.scrollTo(0, 0);
        //this.updateActiveState(currentPathname);
    }

    render() {
        return (
            <Fragment>
                <nav id="topnav" className="defaultscroll is-sticky">
                    <div className="container">
                        <Link className="logo pl-0" to="/">
                            <img src="assets/img/logo-dark.png" className="inline-block dark:hidden" alt="" />
                            <img src="assets/img/logo-light.png" className="hidden dark:inline-block" alt="" />
                        </Link>
                        <div className="menu-extras">
                            <div className="menu-item">
                                <a className="navbar-toggle" id="isToggle" onClick={this.toggleMenu}>
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <ul className="buy-button list-none pt-5">
                            <li className="inline">
                                <Link to={this.state.login_status} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl">{this.state.label_name_change}</Link>
                            </li>
                        </ul>
                        <div id="navigation">
                            <ul className="navigation-menu">
                                <li>
                                    <Link
                                        to="/about-us"
                                        className="sub-menu-item"
                                        onClick={this.scrollToTop.bind(this, 'about-us')}
                                        style={{ color: this.state.about_active_select }}
                                    >
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <NavLink
                                        to="/how-it-works"
                                        className="sub-menu-item"
                                        onClick={this.scrollToTop.bind(this, 'how-it-works')}
                                        style={{ color: this.state.works_active_select }}
                                    >
                                        How it Works
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/customer"
                                        className="sub-menu-item"
                                        onClick={this.scrollToTop.bind(this, 'customer')}
                                        style={{ color: this.state.customer_active_select}}
                                    >
                                        Customer
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/travellers"
                                        className="sub-menu-item"
                                        onClick={this.scrollToTop.bind(this, 'travellers')}
                                        style={{ color: this.state.traveller_active_select}}
                                    >
                                        Traveller
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/contact"
                                        className="sub-menu-item"
                                        onClick={this.scrollToTop.bind(this, 'contact')}
                                        style={{ color: this.state.contact_active_select}}
                                    >
                                        Contact Us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>{/*end container*/}
                </nav>
            </Fragment>
        )
    }
}
export default withRouter(Navbar)
