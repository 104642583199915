import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";
class TravellersFAQ extends Component {
    scrollToTop() {
        window.scrollTo(0, 0);
      }

    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Traveller FAQ</span></h4>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic line-height-1">Check our FAQ for more information</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/21.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                    <section className="relative py-16">
                        <div className="container">
                                <div className="grid md:grid-cols-1 grid-cols-1 gap-[30px]">
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Is <span className="text-red-600">INDZIP</span> a Courier?</h5>
                                            <p className="text-slate-400">Not Really. While courier companies use a hub and spoke model and operate on a larger scale, INDZIP is a premium and personalised service that provides dedicated hand carriage based on specific urgent demand from customers. By working for a courier company, you would be an employee with fixed salary working for 8-10 hours daily, however with INDZIP you would be a freelance traveller with the opportunity for free air travel across cities and the potential to earn. <br/> high income by working as per your convenience.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">What are we looking for?</h5>
                                            <p className="text-slate-400">INDZIP is looking for young and energetic people who love travelling and are looking for a freelance job to earn good income. We do not discriminate based on age or gender. Prior experience is not necessary.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Do I need to own a motorcycle or anything else to register with us?</h5>
                                            <p className="text-slate-400">No, you don’t need to own anything. We only require you to own a mobile phone with data. or to access our website and provide us with live updates while on travel mission. We will take care of all your travel arrangements.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">What are the basic requirements to register with us?</h5>
                                            <p className="text-slate-400">You need to be over 18 years old and you can submit your interest in a few simple steps:</p>
                                            <ul className="list-none text-slate-400 mt-4">
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Download the INDZIP app</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Register using your phone number and email id</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Upload required KYC documents</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Await confirmation from us</li>
                                                <li className="mb-1 flex"><i className="uil uil-check-circle text-red-600 text-xl mr-2"></i>Commence travelling</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How quickly will I get paid for my deliveries?</h5>
                                            <p className="text-slate-400">You will receive full payment into your bank account immediately upon completing your assigned delivery.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How will I know how much money I will earn for an order?</h5>
                                            <p className="text-slate-400">Details of every order including your earning amount will be visible to you before accepting it. You can then proceed to accept the order via the app itself. We maintain utmost transparency here.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Can I combine <span className="text-red-600">INDZIP</span> with other work or studies?</h5>
                                            <p className="text-slate-400">Yes, sure. You may choose to travel for us in the evenings or on weekends or any day or time as per your convenience. You can even make it your part-time hobby and earn well.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">How many days do I need to work in a month?</h5>
                                            <p className="text-slate-400">It is totally up to you, you can choose to accept travel requests as per your convenience. You will get paid per assignment.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">What is <span className="text-red-600">INDZIP's</span> expectation from its Traveller?</h5>
                                            <p className="text-slate-400">We expect you to be proactive while travelling for us. Simple things like timely response and updates on the app, picking up and delivering orders with care, and behaving professionally with customers will keep your ratings high and enable more earnings for you.</p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <i data-feather="help-circle" className="fea icon-ex-md text-red-600 mr-3"></i>
                                        <div className="flex-1">
                                            <h5 className="mb-2 text-xl font-semibold">Can I register from any city in India?</h5>
                                            <p className="text-slate-400">Yes, you can register from any city as a traveller with INDZIP. We will then be able to match you with specific orders from or around your city.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        )
    }
}

export default TravellersFAQ