import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Thankyou extends Component {
    render() {
        return (
            <div>
                <div className="relative shrink-0">
                    <img src="assets/images/header-bg.jpg" className="h-80 w-full object-cover" id="profile-banner" alt />
                    <div className="absolute inset-0 bg-black/70" />
                </div>
                {/* Start */}
                <section className=" md:py-24 bg-gray-50 dark:bg-slate-800" style={{ marginTop: '-17rem' }}>
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-1 md:order-2 order-1" />
                            <div className="lg:col-span-10 md:order-2 order-1">
                                <div className="lg:ml-10">
                                    <div className="bg-white dark:bg-slate-900 p-2 rounded-md shadow dark:shadow-gray-800">
                                        <h1 className="my-6 text-center font-bold" style={{ fontSize: '3rem' }}>Thank you for registering with INDZIP.</h1>
                                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2">
                                            <div>
                                                <div className="form-icon relative mt-5  my-6 mx-5">
                                                    <br /><br />
                                                    <p>Kindly input a few details to complete your registration. You can then commence with your order booking.</p><br />
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Link to="/login" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5">Back to Sign In</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="form-icon relative mt-2 my-6">
                                                    <img src="assets/images/mail-img.jpg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-0 md:order-2 order-1" />
                        </div>{/*end container*/}
                    </div></section>{/*end section*/}
                {/* End */}
            </div>

        )
    }
}

export default Thankyou
