import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";

class AboutUs extends Component {
    scrollToTop() {
        window.scrollTo(0, 0);
      }

        
    componentDidMount() {
        this.scrollToTop();
    }
  
    render() {
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">About Us</span></h4>
                                    <h1 className="text-sb font-extrabold text-left italic">Specialists in Action</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/15.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative py-14 mt-2">
                    <div className="container">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-12 mt-5 md:mt-0">
                                <div className="">
                                    <p className="mb-6 text-xl md:leading-normal leading-normal">INDZIP was founded by international air logistics specialists with a single mission – to create a seamless service that offers speedy same day hand delivery from India to anywhere.</p>
                                    <p className="mb-6 text-xl md:leading-normal leading-normal">Having encountered frustrating situations first hand, where it was impossible to find any courier company who could deliver from one city to another within the same day, they decided to create their own dedicated hand carrier network which would be faster than courier.</p>
                                    <p className="mb-6 text-xl md:leading-normal leading-normal">The young demographic profile in India and the vibrant business ecosystem coupled with rapid urbanization is driving demand for new technologies and services. In today’s world, everyone wants everything right now and INDZIP is well positioned to provide such urgent hand carriage services using its certified traveller network.</p>
                                    <p className="mb-6 text-xl md:leading-normal leading-normal">After all, there is no limit to speed, so why wait an extra day when you can get yours today too!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        )
    }
}

export default AboutUs