import React, { Component ,Fragment } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import HomePage from '../pages/HomePage';
import AboutUsPage from '../pages/AboutUsPage';
import CustomerPage from '../pages/CustomerPage';
import CustomersFAQPage from '../pages/CustomersFAQPage';
import TermsPage from '../pages/TermsPage';
import ContactPage from '../pages/ContactPage';
import TravellerPage from '../pages/TravellerPage';
import TravellersFAQPage from '../pages/TravellersFAQPage';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignUpPage';
import Thankyou from '../components/website/common/Thankyou';
import CustomerLoginPage from '../pages/CustomerLoginPage';
import TravellerLoginPage from '../pages/TravellerLoginPage';
import CustomerSignupPage from '../pages/CustomerSignupPage';
import TravellerSignupPage from '../pages/TravellerSignupPage';
import BankPage from '../pages/BankPage';
import ProfilePage from '../pages/ProfilePage';
import TravellerAddressPage from '../pages/traveller/TravellerAddressPage';
import TravellerDocumentPage from '../pages/traveller/TravellerDocumentPage';
import CustomerAddressPage from '../pages/customer/CustomerAddressPage';
import CustomerDocumentPage from '../pages/customer/CustomerDocumentPage';
import EnquiryPage from '../pages/EnquiryPage';
import TravellerOrderPage from '../pages/traveller/TravellerOrderPage';
import CustomerOrderPage from '../pages/customer/CustomerOrderPage';
import TravellerTransactionPage from '../pages/traveller/TravellerTransactionPage';
import CustomerTransactionPage from '../pages/customer/CustomerTransactionPage';
import InvoicePage from '../pages/customer/InvoicePage';
import HowItWorksPage from '../pages/HowItWorksPage';
import PolicyPage from '../pages/PolicyPage';
import { toast } from 'react-toastify';

function PrivateRoute({ children }) {
    const auth = localStorage.getItem('token')? true: false;

    if(!auth) {
        toast.error('Please Login to continue' , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: false,
            draggable: true,
            });
    }
    return auth ? children : <Navigate to="/" />;
  }

class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/how-it-works" element={<HowItWorksPage />} />
                    <Route path="/customer" element={<CustomerPage />} />
                    <Route path="/customers-faq" element={<CustomersFAQPage />} />
                    <Route path="/travellers" element={<TravellerPage />} />
                    <Route path="/travellers-faq" element={<TravellersFAQPage />} />
                    <Route path="/terms-of-services" element={<TermsPage />} />
                    <Route path="/privacy-policy" element={<PolicyPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/sign-Up/" element={<SignUpPage />} />
                    <Route path="/customer-login" element={<CustomerLoginPage />} />
                    <Route path="/traveller-login" element={<TravellerLoginPage />} />
                    <Route path="/customer-sign-up" element={<CustomerSignupPage />} />
                    <Route path="/traveller-sign-up" element={<TravellerSignupPage /> } />
                    <Route path="/thankyou" element={<Thankyou />} />
                    <Route path='/traveller-profile' element={<PrivateRoute><ProfilePage /> </PrivateRoute>} />
                    <Route path='/profile' element={<PrivateRoute><ProfilePage /> </PrivateRoute>} />                    
                    <Route path='/banks' element={<PrivateRoute><BankPage /></PrivateRoute>}/>
                    <Route path='/customer-address' element={<PrivateRoute><CustomerAddressPage /> </PrivateRoute>} />
                    <Route path='/customer-documents' element={<PrivateRoute> <CustomerDocumentPage /> </PrivateRoute>} />
                    <Route path='/traveller-address' element={<PrivateRoute><TravellerAddressPage /> </PrivateRoute>} />
                    <Route path='/traveller-documents' element={<PrivateRoute> <TravellerDocumentPage /> </PrivateRoute>} />
                    <Route path='/enquiries' element={<PrivateRoute> <EnquiryPage /> </PrivateRoute>} />
                    <Route path='/customer-orders' element={<PrivateRoute> <CustomerOrderPage /> </PrivateRoute>} />
                    <Route path='/traveller-orders' element={<PrivateRoute> <TravellerOrderPage /> </PrivateRoute>} />
                    <Route path='/customer-transactions' element={<PrivateRoute> <CustomerTransactionPage /> </PrivateRoute>} />
                    <Route path='/traveller-transactions' element={<PrivateRoute> <TravellerTransactionPage /> </PrivateRoute>} />
                    <Route path='/customer-invoices' element={<PrivateRoute> <InvoicePage /> </PrivateRoute>} />
                </Routes>
            </Fragment>
        )
    }
}

export default AppRoute
