import React, { Component } from 'react'
import AppUrl from '../../../api/AppUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

class CustomerSignup extends Component {

    constructor() {
        super()
        this.state = {
            // Column Name
            customer_type: '',
            company_name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            mobile: '',
            password: '',
            otp: '',
            // Error Column
            first_name_error: '',
            customer_type_error: '',
            last_name_error: '',
            email_error: '',
            mobile_error: '',
            password_error: '',
            company_name_error: '',
            //After Click Button Hide
            hide_submit: '',
            display_processing: 'none',
            display_company_name: 'none',
            verify_div: 'none'
        }
    }

    //handelUserInput
    handelUserInput = (e) => {
        let first_name_error = '';
        let customer_type_error = '';
        let email_error = '';
        let mobile_error = '';
        let password_error = '';
        let company_name_error = '';

        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });

        if (name === 'customer_type' && value === '2') {
            this.setState({ display_company_name: 'block' });
        } if (name == 'customer_type' && value === '1') {
            this.setState({ display_company_name: 'none' });
        }

        if (name === 'customer_type') {
            this.setState({ customer_type_error }, () => setTimeout(() => this.setState({ customer_type_error: '' }), 5000));
        } else if (name === 'company_name') {
            this.setState({ company_name_error }, () => setTimeout(() => this.setState({ company_name_error: '' }), 5000));
        } else if (name === 'first_name') {
            this.setState({ first_name_error }, () => setTimeout(() => this.setState({ first_name_error: '' }), 5000));
        } else if (name === 'email') {
            this.setState({ email_error }, () => setTimeout(() => this.setState({ email_error: '' }), 5000));
        } else if (name === 'mobile') {
            this.setState({ mobile_error }, () => setTimeout(() => this.setState({ mobile_error: '' }), 5000));
        } else if (name === 'password') {
            this.setState({ password_error }, () => setTimeout(() => this.setState({ password_error: '' }), 5000));
        }


        //check Email Unique
        // let email_error = '';
        // let mobile_error = '';
        // if (name === 'email') {
        //     const postingData = {
        //         'email': value,
        //         'table': 'customers'
        //     }
        //     axios.post(AppUrl.CheckEmailUnique, postingData).then(response => {
        //         console.log(response);
        //         const status = response.data.status;
        //         const message = response.data.message;
        //         if (status === 'exist') {
        //             email_error = message;
        //             this.emailInput.focus(); 
        //             this.setState({ email: ' ' , email_error : email_error})
        //         }
        //     }).catch(error => {

        //     });
        // }
        // if (name === 'mobile') {
        //     const postingData = {
        //         'mobile': value,
        //         'table': 'customers'
        //     }
        //     axios.post(AppUrl.CheckMobileUnique, postingData).then(response => {
        //         console.log(response);
        //         const status = response.data.status;
        //         const message = response.data.message;
        //         if (status === 'exist') {
        //             mobile_error = message;
        //             this.setState({ mobile: '' })
        //         }
        //     }).catch(error => {

        //     });
        // }
    }

    // FOrm Validation On Click
    validate = () => {
        let first_name_error = '';
        let customer_type_error = '';
        let email_error = '';
        let mobile_error = '';
        let password_error = '';
        let company_name_error = '';
        if (!this.state.customer_type) {
            customer_type_error = "Please select customer type";
        } else if (this.state.customer_type === '2' && this.state.company_name == '') {
            company_name_error = "Please enter company name";
        } else if (!this.state.first_name) {
            first_name_error = "Please enter first name";
        } else if (!this.state.email) {
            email_error = "Please enter email id";
        } else if (!this.state.mobile) {
            mobile_error = "Please enter mobile number";
        } else if (!this.state.password) {
            password_error = "Please enter password required";
        }
        if (first_name_error || customer_type_error || mobile_error || email_error || password_error || company_name_error) {
            this.setState({
                first_name_error, customer_type_error, mobile_error, email_error, password_error, company_name_error
            });
            return false;
        }
        return true;
    }

    // Form Submit
    customerSignup = (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {
            const dataFromState = this.state;
            const postingData = {
                'email': dataFromState.email,
                'type': 1
            }
            axios.post(AppUrl.otpSend, postingData).then(response => {
                const status = response.data.status;
                const message = response.data.message;
                if (status === 'success') {
                    this.setState({ verify_div: 'block', hide_submit: 'none' });
                    toast.success(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeonClick: true,
                        pauseOnHover: false,
                        draggable: true,
                    });
                }
            }).catch(error => {

            });
        }
    }


    verifyOtp = (e) => {
        e.preventDefault();
        this.setState({ display_processing: 'block', hide_submit: 'none', verify_div: 'none', });
        const dataFromState = this.state;
        const postingData = {
            'customer_type': dataFromState.customer_type,
            'company_name': dataFromState.company_name,
            'first_name': dataFromState.first_name,
            'middle_name': dataFromState.middle_name,
            'last_name': dataFromState.last_name,
            'email': dataFromState.email,
            'mobile': dataFromState.mobile,
            'password': dataFromState.password,
            'otp': dataFromState.otp,
        }

        axios.post(AppUrl.customerSingup, postingData).then(response => {
            const status = response.data.status;
            const message = response.data.message;
            if (status === 'success') {
                window.location.href = '/thankyou';
            } else if (status === 'exist') {
                this.setState({ 'display_processing': 'none', 'hide_submit': 'block' });
                toast.success(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            } else {
                this.setState({ verify_div: 'block', hide_submit: 'none', 'display_processing': 'none', });
                toast.warning(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeonClick: true,
                    pauseOnHover: false,
                    draggable: true,
                });
            }
        }).catch(error => {

        });
    }

    handleKeyPress(e) {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(e.key)) {
          e.preventDefault();
        }
      }

    render() {
        return (
            <div>
                <section class="relative table w-full py-16 text-center lg:py-36 bg-gray-100">
                    <div class="container">
                        <div class="md:grid-cols-12 grid-cols-1 items-center mt-0 pb-5">
                            <h4 class="font-extrabold text-7xl lg:text-7xl mb-5 text-center italic"><span class="text-red-600">Customer Signup</span></h4>
                        </div>
                    </div>
                </section>
                {/* Start */}
                <section className=" md:py-24 bg-gray-50 dark:bg-slate-800" style={{ marginTop: '-4rem' }}>
                    <div className="container ">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                            <div className="lg:col-span-8 md:order-2 order-1" style={{ marginTop: '-14rem' }}>
                                <div className="lg:ml-10">
                                    <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-800">
                                        <div className="mt-8">
                                            <p className="mb-6 md:text-2xl text-1xl md:leading-normal leading-normal font-semibold"><b>Please complete the below registration form to continue further.</b></p>
                                            <form>
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                                    <div onChange={this.handelUserInput} >
                                                        <label htmlFor="customer_type" className="font-semibold"><span className="text-red-600" />Customer Type</label>
                                                        <div className="form-icon relative mt-2">
                                                            <input type="radio" id="1" name="customer_type" value="1" className="cursorpointer mr-1" style={{ marginTop: 0, height: 15, width: 15 }} />
                                                            <label className="cursorpointer" for="1">Individual</label>
                                                            <input type="radio" id="2" name="customer_type" value="2" className="cursorpointer mr-1 ml-4" style={{ marginTop: 0, height: 15, width: 15 }} />
                                                            <label className="cursorpointer" for="2">Company</label>
                                                        </div>
                                                        <span className='required'>{this.state.customer_type_error}</span>
                                                    </div>
                                                </div>
                                                <div className="grid lg:grid-cols-1 grid-cols-1 gap-5 mt-3" style={{ display: `${this.state.display_company_name}` }}>
                                                    <div style={{ display: `${this.state.display_company_name}` }}>
                                                        <label htmlFor="company_name" className="font-semibold">Company Name<span className="text-red-600 ml-1">*</span></label>
                                                        <div className="form-icon relative mt-4">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                            <input type="text" className="form-input" id="company_name" name="company_name" value={this.state.company_name} onChange={this.handelUserInput} required />
                                                            <span className='required'>{this.state.company_name_error}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">First Name<span className="text-red-600 ml-1">*</span></label>
                                                        <div className="form-icon relative mt-2">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                            <input type="text" className="form-input" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handelUserInput} required />
                                                            <span className='required'>{this.state.first_name_error}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Middle Name</label>
                                                        <div className="form-icon relative mt-2">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                            <input type="text" className="form-input" id="middle_name" name="middle_name" value={this.state.middle_name} onChange={this.handelUserInput} />
                                                            <span className='required'>{this.state.middle_name_error}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Last Name</label>
                                                        <div className="form-icon relative mt-2">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user w-4 h-4 absolute top-3 left-4"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg> */}
                                                            <input type="text" className="form-input" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handelUserInput} />
                                                            <span className='required'>{this.state.last_name_error}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <label htmlFor="typejob" className="font-semibold">Email<span className="text-red-600 ml-1">*</span></label>
                                                            <div className="form-icon relative mt-2">
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 left-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> */}
                                                                <input type="email" className="form-input" ref={(input) => { this.emailInput = input; }} id="email" name="email" value={this.state.email} onChange={this.handelUserInput} required />
                                                                <span className='required'>{this.state.email_error}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-3">
                                                    <div>
                                                        <label htmlFor="typejob" className="font-semibold">Mobile Number<span className="text-red-600 ml-1">*</span></label>
                                                        <div className="form-icon relative mt-2">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone w-4 h-4 absolute top-3 left-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg> */}
                                                            <input type="text" className="form-input" id="mobile" name="mobile" value={this.state.mobile} onChange={this.handelUserInput} maxLength={10} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                                                            <span className='required'>{this.state.mobile_error}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="form-label font-medium">Passward<span className="text-red-600 ml-1">*</span></label>
                                                        <div className="form-icon relative mt-2">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-key w-4 h-4 absolute top-3 left-4"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg> */}
                                                            <input type="password" className="form-input" id="passward" name="password" value={this.state.password} onChange={this.handelUserInput} maxlength={8} required />
                                                            <span className='required'>{this.state.password_error}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid lg:grid-cols-1 grid-cols-1 gap-5 mt-3">
                                                    <div style={{ display: `${this.state.verify_div}` }}>
                                                        <label htmlFor="typejob" className="font-semibold">OTP<span className="text-red-600 ml-1">*</span></label>
                                                        <div className="form-icon relative mt-2">
                                                            <input type="password" className="form-input" name="otp" value={this.state.otp} onChange={this.handelUserInput} maxLength={4}  minlength={4} required onKeyPress={(e) => this.handleKeyPress(e)}/>
                                                            <span className='required'>{this.state.mobile_error}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2" style={{ textAlign: 'center' }}>
                                                    <input type="submit" id="submit" name="send" onClick={(e) => this.verifyOtp(e)} style={{ display: `${this.state.verify_div}` }} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full" value="Verify OTP" />
                                                    <input type="submit" id="resend_otp" name="resend_otp" onClick={(e) => this.customerSignup(e)} style={{ display: `${this.state.verify_div}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="Resend OTP" />
                                                    <button className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" type="button" id="display_processing" style={{ display: `${this.state.display_processing}` }}>Processing...</button>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    {/* <input type="submit" id="submit" name="send" onClick={(e) => this.customerSignup(e)} style={{ display: `${this.state.hide_submit}` }} className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-5" value="SignUp" /> */}
                                                    <button type="button" id="submit" name="send" onClick={(e) => this.customerSignup(e)} className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md w-full"><i className="mdi mdi-login-variant mr-2"></i>Sign Up</button>
                                                </div>
                                            </form>
                                            <div className="sin mt-6">
                                                <a href="/customer-login"><i className="mdi mdi-lock-reset mr-2"></i>Back to login<b class="text-indigo-600 ml-2">Customer</b></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-4" style={{ marginTop: '-4rem' }}>
                                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Welcome to INDZIP – India’s premium personal hand carriage service</h3>
                                <img src="assets/images/login.png" />
                                <p className="text-slate-400 max-w-xl">INDZIP serves anyone who requires urgent mobility.
                                    We can zip across your urgent parcel or cargo by using our hand carriage and air charter services. We offer seamless mobility services for a wide range of product categories such as Automotive, Technology, Medical, Event Planners, Legal Professionals etc
                                </p>
                            </div>
                        </div>{/*end grid*/}
                    </div > {/*end container*/}
                </section > {/*end section*/}
                {/* End */}
            </div >
        )
    }
}

export default CustomerSignup
