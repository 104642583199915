import React, { Component } from 'react'
import { Link } from "react-router-dom";
class Customer extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
      }

        
    componentDidMount() {
        this.scrollToTop();
    }
    render() {
        const imageUrl = 'assets/images/gray-abstract.jpg';
        const backgroundStyle = {
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div>
                <section className="relative table w-full py-16 text-center lg:py-36 bg-gray-100 other-banner-padding-sty">
                    <div className="container">
                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-20 gap-[30px] pb-5">
                            <div className="md:col-span-6">
                                <div className="md:mr-6">
                                    <h4 className="font-extrabold text-6xl lg:text-6xl mb-5 text-left italic"><span className="text-red-600">Traveller</span></h4>
                                    <h1 className="text-sb mb-5 font-extrabold text-left italic line-height-1">Become a traveller with INDZIP and earn good money per trip.</h1>
                                </div>
                            </div>
                            <div className="md:col-span-6">
                                <img className="img-round rounded-3xl banner-img-sty" src="assets/img/16.jpg" />
                            </div>
                        </div>
                    </div>
                </section>
                    <section className="relative py-16 mt-2">
                        <div className="container">
                            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                                <div className="lg:col-span-12 mt-5 md:mt-0">
                                    <div className="">
                                        <p className="mb-6 text-xl md:leading-normal leading-normal">Welcome to INDZIP – a premium personal hand carriage and air charter service where you can earn more than a regular full-time job. You can choose when and where you wish to travel with our orders. INDZIP creates immense earning potential for our travellers and enables them to travel to destination cities for free!</p>
                                        <p className="mb-6 text-xl md:leading-normal leading-normal">INDZIP is looking for energetic people who love travelling and are looking to earn good money while at it. We only require you to own a mobile phone with data. This is essential to download the INDZIP web app and provide us with live updates while on travel mission. We will take care of all your travel arrangements.</p>
                                        <p className="mb-6 text-xl md:leading-normal leading-normal">INDZIP does not discriminate based on age or gender, so anyone who is 18 years and above can apply to be a certified traveler with INDZIP. Prior experience is not necessary.</p>
                                        <p className="mb-6 text-xl md:leading-normal leading-normal">Details of every order including your earning amount will be visible to you before accepting it. INDZIP maintains utmost transparency and our travellers receive full payment into their bank account immediately upon completing their assigned order.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <h3 className="md:text-4xl text-4xl md:leading-normal leading-normal font-semibold">Frequently asked questions by travellers</h3>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/travellers-faq" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">TRAVELLER FAQ</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="relative py-6">
                        <div className="container md:mt-4 mt-10 md:mb-12">
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 md:pl-10 gap-[30px] know-more-sty">
                                <div className="md:col-span-8">
                                    <div className="md:mr-6">
                                        <p className="text-dark mx-auto text-xl text-left">When time is of the essence, choose INDZIP's hand carriage or air charter service to get it moving instantly.</p>
                                    </div>
                                </div>
                                <div className="md:col-span-4">
                                    <div className="mt-1 text-center">
                                        <Link to="/" className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-3xl p-3 know-more-btn-sty">Check Price</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            </div>
        )
    }
}
export default Customer